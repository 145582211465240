import request from '../utils'

const AuthorizationApi = {
    auth: async () => {
        const req = request.get(`/api/auth/signin`)
        const res = await req;
        return res.body;
    },
    login: async (userName, password) => {
        const req = request.post(`/api/auth/signin`).send({ userName, password });
        const res = await req;
        return res.body;
    },
    logout: async () => {
        const req = request.get(`/api/auth/signout`);
        const res = await req;
        return res.body;
    }
};

export default AuthorizationApi