<template>
    <body class="" v-bind:class="[absoluteClass, defaultClass]">
        <nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top">
            <a class="navbar-brand">{{ data.name }}</a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">{{ $t('layers') }}</a>
                            <div class="dropdown-menu" v-if="this.toolLayers.length > 0">
                            <form>
                                <a class="dropdown-item" v-for="(item, i) in this.toolLayers" :key="i">
                                    <div v-if="item.name" class="custom-control custom-checkbox" >
                                        <input type="checkbox" :checked="item.visible" @click="toggleLayer(item)" class="custom-control-input" :id="item.name">
                                        <label class="custom-control-label" :for="item.name">{{ item.name }}</label>
                                    </div>
                                </a>
                            </form>
                        </div>
                    </li>
                    <!-- <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Layouts</a>
                            <div class="dropdown-menu">
                            <button type="button" class="dropdown-item" @click="setClass('default')" >
                                Map+Table
                            </button>
                            <button type="button" class="dropdown-item" @click="setClass('fullmap')">
                                Fullmap
                            </button>
                        </div>
                    </li> -->
                </ul>
                <ul class="navbar-nav px-3">
                    <li class="nav-item text-nowrap">
                        <router-link class="nav-link" :to="{ name: 'inspectiondetail', params: { id: this.$route.params.id } }">
                            {{ $t('back') }}
                        </router-link>
                    </li>
                </ul>
            </div>
        </nav>

        <!-- Begin page content -->
        <div class="map-viewport clearfix">
            <div ref="map" class="map"></div>
        </div>
      
        <div class="fixed-bottom p-3">
            <div class="table-responsive">
                <table id="mainTable" class="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <!-- <th class="text-center" style="width: 10%">#</th>
                            <th class="text-center" style="width: 10%"></th> -->
                            <th class="text-center" v-for="(attrName, i) in this.data.form.attributes" :key="i">{{ attrName.title }}</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(tableFormRow, i) in this.tableForm" :key="i" v-bind:style="styleRow(tableFormRow)">
                            <!-- <td><i class="fas fa-times" @click="showDeleteRow(tableFormRow)"></i></td>
                            <td><i class="fas fa-edit" @click="editRow(tableFormRow)"></i></td> -->
                            <td class="text-center" v-for="(attrName, e) in data.form.attributes" :key="e">
                                <span v-html="renderRow(attrName, tableFormRow[e])">
                            </td> 
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </body>
</template>

<script>
import Map from "ol/Map";
import View from "ol/View";
import { register } from 'ol/proj/proj4';
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Select } from 'ol/interaction';
import Control from 'ol/control/Control';
import { getFeaturesFromSections, defaultLineStyleFunction } from "../../utils/feature-ext";
import { createBaseLayer } from "../../utils/layer-ext";
import { parseCoords } from "../../utils/geom-coords";
import ProjectApi from "../../api/server/project";
import NetApi from "../../api/server/net";
import InspectionApi from '../../api/server/inspection';
import proj4 from 'proj4';

export default {
    name: "Main",
    mounted() {
        this.getSummary();
    },
    destroyed() {
        this.destroyMap();
    },
    watch: {
        sectionFilter: function () {
            // Co když budeme chtít označit jinačí layers nez sections ?? (archivy)
            var lay = this.map.getLayers().getArray().find(layer => layer.get('name') == 'Sections');
            if(lay) {
                lay.changed();
            }
        }
    },
    methods: {
        styleRow(row) {
            if(row.deleted) {
                return "background: #d6d6d6"
            } else {
                return ""
            }
        },
        renderRow(attr, row) {
            if(attr.type === "boolean" && row){
                return '<i class="fas fa-check"></i>'
            } else if (attr.type === "boolean" && !row) {
                return ''
            } else {
                return row;
            }
        },
        async getSummary() {
            this.data = await ProjectApi.detail(this.$route.params.id);
            this.initMap();
        },
        setClass(layout) {
            var self = this;
            if(this.data.form === "graz") {
                self.defaultClass = layout + "Graz";
            } else {
                self.defaultClass = layout
            }
            // #warn odložená aktualizace mapy až po uplatnění změny class
            setTimeout(function () { self.map.updateSize(); }, 200);
        },
        async toggleLayer(item) {
            var self = this;
            var { id } = this.data; 
            var layerName = item.name;

            self.map.getLayers().forEach(async function (layer) {
                if (layer.get("name") === layerName) {
                    if(layer.get("archive") == true) {
                        var source = layer.get('source');
                        if(source == null) {
                            var sections = await NetApi.loadRoutes(id, item.label);
                            var features = getFeaturesFromSections(sections, self.data.projection, self.data.map.sectionAttributeName);
                            let inspectionsSummaryList = {};  
                            let inspections = await InspectionApi.summary(id, item.label);

                            for (var i in inspections) {
                                if(!inspections[i].deleted || undefined || null) {
                                    var section = inspections[i];
                                    if(!inspectionsSummaryList[section.sectionId]) inspectionsSummaryList[section.sectionId] = 0;
                                        inspectionsSummaryList[section.sectionId] = parseFloat(section.len);
                                }
                            };
                            if (features.length > 0) {
                                layer.setSource(new VectorSource({ features: features })) 
                                layer.setStyle((feature, resolution) => {
                                    if(resolution < 20) {
                                        let len = inspectionsSummaryList[feature.get("id")];
                                        if(len) {
                                            let lineLength = feature.get("length");
                                            let finalLength = (len / lineLength)*100;
                                            if(finalLength > parseInt(self.data.completedAt)) { //95
                                                return defaultLineStyleFunction(feature, resolution, self.data.map.completedLineColor || "#FF0000");
                                            } else {
                                                return defaultLineStyleFunction(feature, resolution, self.data.map.progressLineColor || "#FF7F50");
                                            }
                                        }
                                    }
                                    return defaultLineStyleFunction(feature, resolution, self.data.map.lineColor);
                                })
                            }
                        }
                    }
                    layer.setVisible(!item.visible);
                }
            });
        },
        async selectSectionHandler(evt) {
            var self = this;
            let { id } = self.data; //TODO: jak pracovat s ID?

            if (evt.selected && evt.selected.length > 0) {
                self.selectLastFeature = evt.selected[0];
                var layer = self.select.getLayer(self.selectLastFeature);
                var layerName = layer.get("name");
                if(layerName == "Sections") {
                    self.sectionId = self.selectLastFeature.get("id");
                    self.sectionName = self.selectLastFeature.get("name");
                    let query = "?sectionId=" + self.sectionId;
                    var label = self.selectLastFeature.get("label");
                    let inspections = await InspectionApi.list(id, label || '', query);
                    inspections.sort( (a,b) => {
                        return a.von - b.von;
                    });
                    self.tableForm = inspections;
                }
            }
        },
 
        async initMap() {
            var self = this;
            var { id, label } = self.data; 

            self.toolLayers = [];
            
            // release previous map
            if (self.map) {
                self.map.setTarget(undefined);
            }

            // init new map
            self.data = await ProjectApi.detail(id);
            
            proj4.defs('ProjectEpsg' + id, self.data.projection);
            register(proj4);
            
            var layers = [];
            self.data.map.baseMaps.forEach(function(baseMap){
                var layer = createBaseLayer(baseMap, id);
                if(layer) {
                    layers.push(layer);
                }
            })

            // TODO - Načítání stránky "main", je výrazně pomalejší kvůli načítání všech prvků na mapě.. třeba řešit jinak?
            let dataFromServer = await InspectionApi.summary(id, label || '');
            let summaryList = {};  
            for (var i in dataFromServer) {
                var section = dataFromServer[i];
                summaryList[section.sectionId] = section.len;
            }
            self.summaryList = summaryList;

            var sections = await NetApi.loadRoutes(id, label || '');
            var features = getFeaturesFromSections(sections, this.data.projection, this.data.map.sectionAttributeName);
            if (features.length > 0) {
                var vector = new VectorLayer({ 
                    name: "Sections",
                    source: new VectorSource({ features: features }),
                    visible: true
                })
                vector.setStyle((feature, resolution) => {
                    if(!self.selectLastFeature || self.selectLastFeature.get("id") != feature.get("id")) {
                        var canHighlight = false;
                        if(self.sectionFilter && self.sectionFilter.length > 3 && feature.get('name')) {
                            canHighlight = (feature.get('name').toUpperCase()).indexOf(self.sectionFilter.toUpperCase()) >= 0;
                        }

                        if(resolution < 20) {
                            let len = self.summaryList[feature.get("id")];
                            if(len) {
                                let lineLength = feature.get("length");
                                let finalLength = (len / lineLength)*100;
                                if(finalLength > parseInt(self.data.completedAt)) { 
                                    return defaultLineStyleFunction(feature, resolution, self.data.map.completedLineColor || "#FF0000", canHighlight, self.data.map.highlightedLineColor || "#FF00F7")
                                } else {
                                    return defaultLineStyleFunction(feature, resolution, self.data.map.progressLineColor || "#FF7F50", canHighlight, self.data.map.highlightedLineColor || "#FF00F7")
                                }
                            }
                        }
                        return defaultLineStyleFunction(feature, resolution, self.data.map.lineColor, canHighlight, self.data.map.highlightedLineColor || "#FF00F7");
                    } else {
                        // highlighted featury nepřepisujem
                    }
                })
                layers.push(
                    vector
                );
            }
            var styleSelectFunction = function (feature, resolution) {
                return defaultLineStyleFunction(feature, resolution, self.data.map.selectedLineColor || "#0000FF");
            }
            if(self.data.archives) {
                self.data.archives.forEach(function (layer) {
                    var vector = new VectorLayer({ 
                        name: layer.name,
                        label: layer.label,
                        archive: true,
                        source: null,
                        style: null,
                        visible: false
                    })
                    layers.push(vector);
                });
            }
            
            var view = new View({ center: parseCoords(self.data.map.center), zoom: 16 });
            self.map = new Map({
                target: self.$refs["map"],
                layers: layers,
                view: view,
            });
             
            self.select = new Select({ active: false, style: styleSelectFunction });
            self.select.on('select', async function (evt) {
                if (evt.selected && evt.selected.length > 0) {
                    var selectedFeature = evt.selected[0];
                    var layer = self.select.getLayer(selectedFeature);
                    var layerName = layer.get("name");
                    if(layerName == "Sections") {
                        self.sectionId = selectedFeature.get("id");
                        self.sectionName = selectedFeature.get("name");
                        self.sectionLabel = selectedFeature.get("label");
                    }
                } 
                else {
                    self.tableForm = null;
                
                    // clear section in form 
                    self.sectionLabel = "";
                    self.sectionId = "";
                    self.sectionName = "";
                }
            });
            self.map.addInteraction(self.select);

            var select = new Select();

            var changeInteraction = function () {
                if (select !== null) {
                    self.map.removeInteraction(select);
                }
                if (select !== null) {
                    self.map.addInteraction(select);
                    select.on('select', function (e) {
                        displayFeatureInfo(e.mapBrowserEvent.pixel);
                    });
                }
            };
            
            var displayFeatureInfo = async function (pixel) {
                var feature = self.map.forEachFeatureAtPixel(pixel, function (feature) {
                    return feature;
                });

                if (feature) {
                    let query = "?sectionId=" + feature.get("id");
                    let label = feature.get("label");
                    self.tableForm = await InspectionApi.list(id, label || '', query);
                }
            };
            changeInteraction();

            //TODO - Asi by to chtělo použít await, aby funkce počkala na načtení všech layoutů a až poté se načetla mapa.
            self.map.getLayers().forEach(function (layer) {
                    self.toolLayers.push(layer.values_)
                });

            const filterSections = document.createElement('div');
            filterSections.className = 'ol-control';
            filterSections.style = 'display:block; top:0.5em; right:.5em;';
            filterSections.innerHTML = `<div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">🔍</span>
                                            </div>
                                            <input class="form-control" id="filter_sections" type="text" >
                                        </div>`;
            
            filterSections.addEventListener('input', function(event) {
                var { value } = event.target;
                self.sectionFilter = value;
            });

            self.map.addControl(new Control({ element: filterSections }));
           
        },
        destroyMap() {
            if (this.map) {
                this.map.setTarget(undefined);
            }
        }
    },
    data() {
        return {
            summaryList: {},
            sectionFilter: "",
            map: null,
            toolLayers: [],
            data: {
                form: "default",
                map: {
                    baseMaps: [],
                },
            },
            absoluteClass: "h-100 position-absolute",
            defaultClass: "",
            classObject: {
                "df-bottom": true,
            },
            tableForm: []
        };
    }
};
</script>

<style scoped>
.map-viewport {
    position: fixed;
    top: 56px;
    right: 0;
    left: 0;
    z-index: 100;
    height: 60%;
}

.map-viewport .map {
    height: 100%;
    width: 100%;
}
.fixed-bottom {
    top: calc(60% + 56px);
}
.fixed-bottom table tbody {
    overflow: scroll;
}
.fixed-container {
    height: 100%;
    width: 100%;
}
</style>