<template>
    <div class="table-responsive">
        <table class="table table-bordered table-responsive-md">
            <thead>
                <tr>
                    <th class="text-center">{{ $t('projects') }}
                        <router-link style="float: right" :to="{ name: 'projectadd'}"><i class="fas fa-lg fa-plus"></i></router-link>
                    </th>
                </tr>
            </thead>
            <tbody v-if="listOfProjects.length > 0">
                <tr>
                    <input style="border:none" class="form-control" :placeholder="$t('projectName')" v-model="filterInput" type="search">
                </tr>
                <tr v-for="(item, i) in listOfProjects" :key="i">
                    <td>
                        <router-link :to="{ name: 'admininspectiondetail', params: { id: item.id } }">
                            {{ item.name }}
                        </router-link>
                        <i style="float:right; padding-top:5px; cursor: pointer" @click="showDeleteProjectModal(item)" class="fas fa-sm fa-trash"></i>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <input class="form-control" :placeholder="$t('filterByName')" v-model="filterInput" type="search">
                </tr>
                <tr>
                    {{ $t('noAssignedProject') }}
                </tr>
            </tbody>
        </table>
        <ProjectDeleteModal v-if="isModalVisible" @reload="requestData"></ProjectDeleteModal>
    </div>
</template>

<script>
import ProjectApi from "../../../api/server/project";
import ProjectDeleteModal from "./ProjectDeleteModal";

export default {
    name: "ProjectList",
    components: {
        ProjectDeleteModal
    },
    mounted() {
        this.requestData();
    },
    computed: {
        listOfProjects(){
            if (!this.filterInput){
                return this.projectList;
            } else {
                const lowerCaseFilter = this.filterInput.toLowerCase();

                return this.projectList.filter((project) => { 
                    if(project.name.toLowerCase().includes(lowerCaseFilter))
                    return project;
                });
            }
        }
    },
    methods: {
        async requestData() {
            this.projectList = await ProjectApi.list();
        },
        showDeleteProjectModal(item) {
            this.projectToDeleteId = item.id
            this.projectToDeleteName = item.name
            this.isModalVisible = true
        }
    },
    data() {
        return {
            projectList: [],
            projectToDeleteName: "",
            projectToDeleteId: "",
            isModalVisible: false,
            filterInput: ""
        }
    }
};
</script>

<style scoped>
    .table-responsive {
        max-height: calc(100vh - 160px - 3rem);
        margin: 0;
        padding: 0;
    }
</style> 