<template>
    <transition name="modal-fade">
        <div class="modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t('groups') }}</h5>
                        <span aria-hidden="true">&times;</span>
                    </div>
                    <div class="modal-body" style="padding:0; height:196px">
                        <table class="table">
                            <tbody class="bodyDialog" v-if="list.length > 0">
                                <tr v-for="(item, i) in list" :key="i">
                                    <td class="form-check">
                                        <label style="padding-left:20px" class="margin form-check-label" v-bind:for="item.id">
                                            <input v-bind:checked="checked(item.id)" v-bind:id="item.id" type="radio" class="form-check-input" @change="onChange({id: item.id, name: item.name })" name="optradio">{{ item.name}}
                                        </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="close" data-dismiss="modal" >
                            {{ $t('close') }}
                        </button>
                        <button type="button" class="btn btn-primary" @click="confirm" >
                            {{ $t('confirm') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import GroupApi from "../../../api/server/group";

export default {
    name: "GroupToUserDialog",
    mounted() {
        this.requestData();
    },
    methods: {
        async requestData() {
            try {
                this.list = await GroupApi.list();
                this.checked() 
            } catch (error) {}
        },
        checked(id) {
            if(this.$parent.data && this.$parent.data.groupId && this.$parent.data.groupId === id) {
                return true;
            } else {
                return false;
            }
        },
        onChange(data) {
            this.selectedRadio = data
        },
        close() {
            this.$emit("close");
        },
        confirm() {
            this.$emit("confirm", this.selectedRadio)
        },
    },
    data() {
        return {
            list: [],
            selectedRadio: {}
        };
    }
};
</script>