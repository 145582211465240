<template>
	<div class="container py-5">
		<div v-if="pending">{{ $t('loading') }}</div>
		<div v-else-if="error">{{ $t('error') }}</div>
		<div v-else>
            <h2>{{ $t('userDetail') }}</h2>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link :to="{ name: 'projects' }">
                            {{ $t('home') }}
                        </router-link>
                    </li>
                    <li class="breadcrumb-item">
                        <router-link :to="'/settings/users'">
                            {{ $t('users') }}
                        </router-link>
                    </li>
                    <li class="breadcrumb-item">
                        {{ data.name }}
                    </li>
                </ol>
            </nav>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label>{{ $t('name') }}</label>
                    <input class="form-control" v-model="data.name">
                </div>
                <div class="form-group col-md-6">
                    <label>{{ $t('role') }}</label>
                    <select id="role" v-model="data.role" class="form-control">
                        <option disabled>{{ $t('chooseRole') }}</option>
                        <option value="Inspector" id="inspector">{{ $t('inspector') }}</option>
                        <option value="Viewer" id="viewer">{{ $t('viewer') }}</option>
                        <option value="Admin" id="admin">{{ $t('admin') }}</option>
                    </select>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label>{{ $t('loginName') }}</label>
                    <input class="form-control" v-model="data.userName">
                </div>
                <div class="form-group col-md-6">
                    <label>{{ $t('team') }}</label>
                    <input class="form-control" :placeholder="$t('teamName')" v-model="data.teamName"> 
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label>{{ $t('group') }}</label>
                    <div class="input-group">
                        <input class="form-control" :placeholder="$t('chooseGroup')" v-on:click="showModal" v-model="data.groupName" readonly> 
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" @click="showModal" type="button">{{ $t('choose') }}</button>
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label style="display:block">&nbsp;</label>
			        <button class="btn btn-primary" v-on:click="saveUser" style="float:right">{{ $t('save') }}</button>
                </div>
            </div>

		</div>
        <GroupToUserDialog v-show="isModalVisible" @close="closeModal" @confirm="confirmModal"></GroupToUserDialog>
    </div>
</template>

<script>
import GroupToUserDialog from "./GroupToUserDialog";
import UserApi from "../../../api/server/user";
import GroupApi from "../../../api/server/group";

export default {
    name: "userdetail",
    components: {
        GroupToUserDialog
    },
    data() {
        return {
            pending: true,
            isModalVisible: false,
            error: false,
            data: {
                groupName: "",
                groupId: 0,
                name: "",
                userName: "",
                teamName: "",
                role: ""
            }
        };
    },
    notifications: {
        showSuccess: { 
            title: 'Getan!',
            message: 'User succesfully updated!',
            type: 'success'
        }
    },
    watch: {
        $route: "requestData",
    },
    mounted() {
        this.requestData();
    },
    methods: {
        confirmModal(modalData) {
            this.modalData = modalData
            this.data.groupName = modalData.name
            this.data.groupId = modalData.id
            this.closeModal()
        },
        showModal() {
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false; 
        },
        async saveUser() {
            let content = JSON.parse(this.data.content)
                content.groupId = this.data.groupId
                content.team = this.data.teamName
                content.role = this.data.role

            this.data.content = JSON.stringify(content)

            await UserApi.update(this.data);
            this.showSuccess()
        },
        async requestData() {
            let self = this
            this.pending = true;
            try {
                const data = await UserApi.detail(this.$route.params.id);
                this.data = data;
                this.error = false;
                if(data && data.content != null) {
                    let content = JSON.parse(data.content)
                    if(content.groupId && content.groupId > 0) {
                        self.data.groupId = content.groupId
                        let groupName = await GroupApi.detail(content.groupId)
                        if(groupName) {
                            self.data.groupName = groupName.name
                        }
                    } else {
                        self.data.groupId = 0
                    }
                       
                    if(content.team)
                        self.data.teamName = content.team
                    if(content.role)
                        self.data.role = content.role
                }
            } catch (e) {
                this.data = null;
                this.error = e;
            }
            this.pending = false;
        },
    },
    render() {
        return this.$scopedSlots.default({
            pending: this.pending,
            error: this.error,
            data: this.data,
        });
    },
};
</script>