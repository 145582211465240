<template>
	<div class="container py-5">
        <h2>{{ $t('groupDetail') }}</h2>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'projects'}">
                        {{ $t('home') }}
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="'/settings/groups'">
                        {{ $t('groups') }}
                    </router-link>
                </li>
                <li class="breadcrumb-item">{{ data.name }}</li>
            </ol>
        </nav>
        <div class="row">
            <div class="col-md-6">
                <label>Id</label>
                <input disabled class="form-control" v-model="data.id">
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <label>{{ $t('name') }}</label>
                <input class="form-control" v-model="data.name">
            </div>
        </div><br>
        <button class="btn btn-primary" v-on:click="saveGroup">{{ $t('save') }}</button>
    </div>
</template>

<script>
import GroupApi from "../../../api/server/group";

export default {
    name: "groupdetail",
    watch: {
        $route: "requestData",
    },
    mounted() {
        this.requestData();
    },
    methods: {
        async saveGroup() {
            await GroupApi.update(this.data);
            this.showSuccess()
        },
        async requestData() {
            this.data = await GroupApi.detail(this.$route.params.id);
        }
    },
    notifications: {
        showSuccess: { 
            title: 'Getan!',
            message: 'Group succesfully updated!',
            type: 'success'
        }
    },
    data() {
        return {
            data: {
                name: "",
                id: ""
            },
        }
    }
};
</script>