import request from '../utils'

const ProjectApi = {
    list: async () => {
        const req = request.get(`/api/project`)
        const res = await req;
        return res.body;
    },
    getInfo: async (projectId) => {
        const req = request.get(`/api/project/${projectId}/info`)
        const res = await req;
        return res.body;
    },
    detail: async (project) => {
        const req = request.get(`/api/project/${project}`)
        const res = await req;
        return res.body;
    },
    update: async (project) => {
        const req = request.put(`/api/project/${project.id}`).send(project);
        const res = await req;
        return res.body;
    },
    create: async (project) => {
        const req = request.post(`/api/project`).send(project);
        const res = await req;
        return res.body;
    },
    delete: async (projectId) => {
        const req = request.delete(`/api/project/${projectId}`);
        await req;
    },
    uploadXlsx: async (projectId, files) => {    
        const req = request.postMultipart(`/api/project/${projectId}/inspection/upload/xlsx`);
        files.forEach(file => {
            req.attach("files", file);
        }); 
        const res = await req;
        return res.body;
    }
};

export default ProjectApi