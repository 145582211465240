import request from '../utils'

const GroupApi = {
    list: async () => {
        const req = request.get(`/api/group`)
        const res = await req;
        return res.body;
    },
    detail: async (group) => {
        const req = request.get(`/api/group/${group}`)
        const res = await req;
        return res.body;
    },
    setPassword: async (id, password) => {
        const req = request.put(`/api/group/${id}/resetpassword`).send(password)
        const res = await req;
        return res.body;
    },
    update: async (group) => {
        const req = request.put(`/api/group/${group.id}`).send(group);
        const res = await req;
        return res.body;
    },
    create: async (group) => {
        const req = request.post(`/api/group`).send(group);
        const res = await req;
        return res.body;
    },
    delete: async (groupId) => {
        const req = request.delete(`/api/group/${groupId}`);
        await req;
    }
};

export default GroupApi