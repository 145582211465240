import { openDB } from 'idb';

const DbLoginApi = {
    initPms: async (dbName) => {
        await openDB(dbName, 10, {
            upgrade(db) {
                if (!db.objectStoreNames.contains('login')) {
                    db.createObjectStore('login', { keyPath: "id",  autoIncrement: true });
                }
                if (!db.objectStoreNames.contains('projects')) {
                    db.createObjectStore('projects', { keyPath: "id",  autoIncrement: true });
                }
                if (!db.objectStoreNames.contains('groups')) {
                    db.createObjectStore('groups', { keyPath: "id",  autoIncrement: true });
                }
            }
        });
    },
    saveLogin: async (dbName, data) => {
        var db = await openDB(dbName);
        var tx = db.transaction("login", "readwrite");

        tx.store.clear();
        var storing = tx.store.put(data);

        await storing;

        await tx.done
    },
    getLogin: async (dataName) => {
        const dbPromise = await openDB(dataName);
        const data = await dbPromise.get("login", 1);
        
        return data;
    }
    
};

export default DbLoginApi