
<template>
    <transition name="modal-fade">
        <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('groupDelete') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" @click="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p style="margin-bottom:0">{{ $t('wannaDeleteThisGroup') }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="close" data-dismiss="modal" >
                        {{ $t('close') }}
                    </button>
                    <button type="button" class="btn btn-primary" @click="deleteGroup" >
                        {{ $t('delete') }}
                    </button>
                </div>
                </div>
            </div>
        </div>      
    </transition>
</template>

<script>
import GroupApi from "../../../api/server/group";

export default {
    name: "groupDeleteModal",
    methods: {
        close() {
            this.$parent.isModalVisible = false;
        },
        async deleteGroup() {
            await GroupApi.delete(this.$parent.groupToDeleteId);
            this.close();
            this.$emit("reload");
        },
    }
};
</script>
