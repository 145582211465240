import { openDB } from 'idb';

const TABLE_NAME = "photo";
const DpPhotoApi = {
    deletePhotos: async (dbName, label) => {
        if(label) dbName += '_' + label;
        var db = await openDB(dbName);
        var tx = db.transaction(TABLE_NAME, "readwrite");
        
        await tx.store.clear()
    },
    storePhoto: async (dbName, label, data) => {
        if(label) dbName += '_' + label;
        var db = await openDB(dbName);
        var tx = db.transaction(TABLE_NAME, "readwrite");

        var storing = tx.store.put(data);
        await storing;

        await tx.done
    },
    getPhotos: async (dbName, label) => {
        if(label) dbName += '_' + label;
        const dbPromise = await openDB(dbName);
        const data = await dbPromise.getAll(TABLE_NAME);

        return data;
    },
    // deleteFormRow: async (dbName, key) => {
    //     var db = await openDB(dbName);
    //     var tx = db.transaction(TABLE_NAME, "readwrite");
        
    //     let oneKey = await tx.store.getAllKeys()
    //     await tx.store.delete(oneKey[key])
    // },
   
    // loadSection: async (dataName, sectionMapId) => {
    //     let data = [];

    //     const dbPromise = await openDB(dataName);
    //     //TODO Indexy, nebrat všechny sections
    //     const inspections = await dbPromise.getAll(TABLE_NAME);
    //     inspections.forEach((insp) => {
    //         if(insp.sectionId == sectionMapId) {
    //             data.push(insp)
    //         }
    //     })

    //     return data;
    // }
};

export default DpPhotoApi