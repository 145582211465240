import request from '../utils'

const FormApi = {
    list: async () => {
        const req = request.get(`/api/form`)
        const res = await req;
        return res.body;
    },
    detail: async (form) => {
        const req = request.get(`/api/form/${form}`)
        const res = await req;
        return res.body;
    },
    update: async (id, form) => {
        const req = request.put(`/api/form/${id}`).send(form);
        const res = await req;
        return res.body;
    },
    create: async (form) => {
        const req = request.post(`/api/form`).send(form);
        const res = await req;
        return res.body;
    },
    delete: async (formId) => {
        const req = request.delete(`/api/form/${formId}`);
        await req;
    }
}; 

export default FormApi