<template>
    <transition name="modal-fade">
        <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('copyFromArchive') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" @click="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <label>{{ $t('name') }}</label>
                    <select class="form-control" placeholder="Archive name" v-model="$parent.loadArchiveLabel">
                        <option value="" ></option>
                        <option v-for="(archive, o) in data.archives" :key="o" :value="archive.label" > {{ archive.name }} </option>
                    </select>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="close" data-dismiss="modal" >
                        {{ $t('close') }}
                    </button>
                    <button type="button" class="btn btn-primary" @click="confirm" >
                        {{ $t('confirm') }}
                    </button>
                </div>
                </div>
            </div>
        </div>      
    </transition>
</template>

<script>
export default {
    name: "rowDeleteModal",
    mounted() {
        this.requestProjectData();
    },
    notifications: {
        showError: {
            title: 'Error!',
            message: 'Choose archive.',
            type: 'error'
        }
    },
    methods: {
        requestProjectData() {
            this.data = this.$parent.data;
        },
        close() {
            this.$emit("close");
        },
        confirm() {
            if(this.$parent.loadArchiveLabel.length > 0) {
                this.$emit("confirm");
            } else {
                this.showError();
            }
        }
    },
    data() {
        return {
            data: {
                archives: []
            }
        }
    }
};
</script>
