<template>
    <div>
        <table class="table table-bordered table-responsive-md">
            <thead>
                <tr>
                    <th class="text-center">{{ $t('users') }}
                        <router-link style="float: right" :to="{ name: 'useradd'}"><i class="fas fa-lg fa-plus"></i></router-link>
                    </th>
                </tr>
            </thead>
            <tbody v-if="list.length > 0">
                <tr v-for="(user, i) in list" :key="i">
                    <td >
                        <router-link :to="{ name: 'userdetail', params: { id: user.id }}">
                            {{ user.name }}
                        </router-link>
                        <i style="cursor: pointer;float:right; padding-top:5px" title="Delete user" @click="showDeleteUserModal(user)" class="fas fa-sm fa-trash"></i>
                        <i style="cursor: pointer;float:right; padding-top:5px; padding-right:15px" title="Change password" @click="showModal(user)"  class="fa fa-sm fa-key" aria-hidden="true"></i>
                    </td>
                </tr>
            </tbody>
        </table>
        <PasswordModal v-show="isModalVisible" @close="closeModal" @confirm="confirmModal"></PasswordModal>
        <UserDeleteModal v-show="isDeleteModalVisible" @reload="requestData" @closeDeleteDialog="closeDeleteModal"></UserDeleteModal>
    </div>
</template>

<script>
import UserApi from "../../../api/server/user";
import PasswordModal from "./UserPasswordDialog";
import UserDeleteModal from "./UserDeleteModal";

export default {
    name: "users",
    components: {
        UserDeleteModal,
        PasswordModal
    },
    mounted() {
        this.requestData();
    },
    data() {
        return {
            userToDeleteId: "",
            userToDeleteName: "",
            userToChangePasswordId: "",
            userToChangePasswordName: "",
            isDeleteModalVisible: false,
            modalId: 0,
            isModalVisible: false,
            list: []
        };
    },
    methods: {
        async requestData() {
            this.list = await UserApi.list();
        },
        showDeleteUserModal(user) {
            this.userToDeleteId = user.id;
            this.userToDeleteName = user.name;
            this.isDeleteModalVisible = true;
        },
        closeDeleteModal() {
            this.isDeleteModalVisible = false;
        },
        confirmModal(modalData) {
            this.modalData = modalData;
            this.data.parentName = modalData.name;
            this.data.parentId = modalData.id;
            this.closeModal();
        },
        showModal(user) {
            this.userToChangePasswordId = user.id;
            this.userToChangePasswordName = user.name;
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        },
    }
};
</script>