<template>
    <transition name="modal-fade">
        <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('createArchive') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" @click="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <label>{{ $t('name') }}</label>
                    <input class="form-control" placeholder="Archive name" v-model="$parent.archiveName">
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="close" data-dismiss="modal" >
                        {{ $t('close') }}
                    </button>
                    <button type="button" class="btn btn-primary" @click="confirm" title="Delete" >
                        {{ $t('confirm') }}
                    </button>
                </div>
                </div>
            </div>
        </div>      
    </transition>
</template>

<script>
export default {
    name: "rowDeleteModal",
    methods: {
        close() {
            this.$emit("close");
        },
        confirm() {
            this.$emit("confirm");
        }
    }
};
</script>
