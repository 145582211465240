import { openDB } from 'idb';

const NET_TABLE_NAME = "net";
const DbNetApi = {
    init: async (dbName, label) => {
        if(label) dbName += '_' + label;
        await openDB(dbName, 8, {
            upgrade(db, oldVersion, newVersion) {
                if (!db.objectStoreNames.contains(NET_TABLE_NAME)) {
                    db.createObjectStore(NET_TABLE_NAME, { keyPath: "properties.OBJECTID" });
                }
                if (oldVersion < 3 && db.objectStoreNames.contains('inspection')) {
                    db.deleteObjectStore('inspection')
                    //db.createObjectStore('inspection', { keyPath: "id",  autoIncrement: true });
                }
                if (!db.objectStoreNames.contains('inspection_v2')) {
                    db.createObjectStore('inspection_v2', { keyPath: "guid" });
                }
                if (!db.objectStoreNames.contains('inspection_log')) {
                    db.createObjectStore('inspection_log', { keyPath: "guid" });
                }
                if (!db.objectStoreNames.contains('photo')) {
                    db.createObjectStore('photo', { keyPath: "guid" });
                }
            }
        });
    },
    deleteNet: async (dbName, label) => {
        if(label) dbName += '_' + label;
        var db = await openDB(dbName);
        var tx = db.transaction(NET_TABLE_NAME, "readwrite");
        
        await tx.store.clear()
    },
    countElements: async (dbName, label, storeName) => {
        if(label) dbName += '_' + label;
        var db = await openDB(dbName);
        var tx = db.transaction(storeName, "readwrite");
        
        return await tx.store.count()
    },
    storeNet: async (dbName, label, data ) => {
        if(label) dbName += '_' + label;
        var db = await openDB(dbName);
        var tx = db.transaction(NET_TABLE_NAME, "readwrite");

        var storing = tx.store.put(data);

        await storing;

        await tx.done
    },
    loadNet: async (dbName, label) => {
        if(label) dbName += '_' + label;
        const dbPromise = await openDB(dbName);

        const data = await dbPromise.getAll(NET_TABLE_NAME);
        
        return data;
    }
};

export default DbNetApi