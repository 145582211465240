<template>
    <div>
        <table class="table table-bordered table-responsive-md">
            <thead>
                <tr>
                    <th class="text-center">IndexedDB
                    </th>
                </tr>
            </thead>
            <tbody v-if="databaseList.length > 0">
                <tr v-for="(item, i) in databaseList" :key="i">
                    <td>
                        <span> {{ item.name }} </span>
                        <i style="float:right; padding-top:5px; cursor: pointer" @click="showClearIddb(item)" class="fas fa-sm fa-trash"></i>
                    </td>
                </tr>
            </tbody>
        </table>
        <IndexeddbDeleteDialog v-show="isDeleteModalVisible" @confirm="deleteIddb" @close="closeModal"></IndexeddbDeleteDialog>
    </div>
</template>

<script>
import { deleteDB } from 'idb';
import IndexeddbDeleteDialog from "./IndexeddbDeleteDialog";

export default {
    name: "IndexedDatabaseList",
    components: {
        IndexeddbDeleteDialog,
    },
    mounted() {
        this.getIddbList()
    },
    methods: {
        async getIddbList() {
            this.databaseList = await self.indexedDB.databases()
        },
        showClearIddb(iddbName) {
            this.iddbName = iddbName.name;
            this.isDeleteModalVisible = true;
        },
        async deleteIddb() {
            await deleteDB(this.iddbName);
            this.databaseList = [];
            this.getIddbList();
            this.closeModal();
        },
        closeModal() {
            this.isDeleteModalVisible = false;
        }
    },
    data() {
        return {
            list: "list",
            iddbName: "",
            isDeleteModalVisible: false,
            databaseList: [],
        };
    },
};
</script>