<template>
    <transition name="modal-fade">
        <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('deleteInspection') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" @click="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p style="margin-bottom:0">{{ $t('wannaDeleteAllInspections') }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="close" data-dismiss="modal" >
                        {{ $t('close') }}
                    </button>
                    <button type="button" class="btn btn-primary" @click="confirm" title="Delete" >
                        {{ $t('confirm') }}
                    </button>
                </div>
                </div>
            </div>
        </div>      
    </transition>
</template>

<script>
import InspectionApi from '../../../api/server/inspection';
export default {
    name: "ClearInspectionsDialog",
    notifications: {
        showSuccess: { 
            title: 'Getan!',
            message: 'Inspections has been deleted.',
            type: 'success'
        }
    },
    methods: {
        close() {
            this.$emit("close");
        },
        async confirm() {
            await InspectionApi.delete(this.$route.params.id);
            this.$emit("close");
            this.showSuccess();
            this.$emit("refresh");
        }
    }
};
</script>
