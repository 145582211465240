
import { fromLonLat } from "ol/proj";


export function parseCoords(coordinates) {
    if (typeof coordinates === 'string') {
        var parts = coordinates.split(",");
        coordinates = [parseFloat(parts[1]), parseFloat(parts[0])];
    }
    return fromLonLat(coordinates);
}
