<template>
    <div>
        <table class="table table-bordered table-responsive-md">
            <thead>
                <tr>
                    <th class="text-center">{{ $t('caches') }}
                    </th>
                </tr>
            </thead>
            <tbody v-if="cacheList.length > 0">
                <tr v-for="(item, i) in cacheList" :key="i">
                    <td>
                        <span> {{ item }} </span>
                        <i style="float:right; padding-top:5px; cursor: pointer" @click="showClearCache(item)" class="fas fa-sm fa-trash"></i>
                    </td>
                </tr>
            </tbody>
        </table>
        <CacheDeleteModal v-show="isModalVisible" @confirm="deleteCache" @close="closeModal"></CacheDeleteModal>
    </div>
</template>

<script>
import CacheDeleteModal from "./CacheDeleteDialog";

export default {
    name: "cacheList",
    components: {
        CacheDeleteModal
    },
    mounted() {
        this.getCacheList();
    },
    methods: {
        async getCacheList() {
            this.cacheList = await caches.keys()
        },
        showClearCache(cacheName) {
            this.cacheName = cacheName;
            this.isModalVisible = true;
        },
        async deleteCache() {
            await caches.delete(this.cacheName);
            this.cacheList = [];
            this.getCacheList();
            this.isModalVisible = false;
        },
        closeModal() {
            this.isModalVisible = false;
        }
    },
    data() {
        return {
            cacheName: "",
            isModalVisible: false,
            cacheList: []
        };
    },
};
</script>