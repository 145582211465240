import "bootstrap";
import 'bootstrap/dist/css/bootstrap.css' // Import precompiled Bootstrap css
import '@fortawesome/fontawesome-free/css/all.css'
import 'ol/ol.css';
import './css/site.css';

import Router from 'vue-router'
import Vue from 'vue';
import Login from './components/Login';
import Settings from './components/settings/Settings';

import InspectionDetail from './components/inspection/InspectionDetail';
import InspectionProjectList from './components/inspection/InspectionProjectList';
import InspectionTool from './components/inspection/InspectionTool';
import InspectionView from './components/inspection/InspectionView';

import GroupAdd from './components/settings/groups/GroupAdd';
import GroupDetail from './components/settings/groups/GroupDetail';
import GroupList from './components/settings/groups/GroupList';

import AdminProjectAdd from './components/settings/projects/AdminProjectAdd';
import AdminProjectDetail from './components/settings/projects/AdminProjectDetail';
import AdminInspectionDetail from './components/settings/projects/AdminInspectionDetail';
import ProjectList from './components/settings/projects/AdminProjectList';
import AdminProjectView from './components/settings/projects/AdminProjectView';

import UserAdd from './components/settings/users/UserAdd';
import UserDetail from './components/settings/users/UserDetail';
import UserList from './components/settings/users/UserList';

import FormAdd from './components/settings/forms/FormAdd';
import FormDetail from './components/settings/forms/FormDetail';
import FormList from './components/settings/forms/FormList';

import Tools from './components/tools/Tools';
import IndexeddbList from './components/tools/indexeddb/IndexeddbList';
import CacheList from './components/tools/cache/CacheList';

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'login',
            component: Login
        },
        //DATABASE
        {
            path: '/tools',
            name: "tools",
            component: Tools,
            props: true,
            children: [
                {
                    path: 'indexeddb',
                    name: "indexeddb",
                    component: IndexeddbList
                },
                {
                    path: 'cache',
                    name: "cache",
                    component: CacheList
                }
            ]
        },
        //INSPECTION
        {
            path: '/inspectiondetail/:id',
            name: 'inspectiondetail',
            component: InspectionDetail,
            props: true
        },
        {
            path: '/projects',
            name: 'projects',
            component: InspectionProjectList
        },
        {
            path: '/inspectiontool/:id',
            name: 'inspectiontool',
            component: InspectionTool,
            props: true
        },
        {
            path: '/inspectionview/:id',
            name: 'inspectionview',
            component: InspectionView,
            props: true
        },
        //GROUPS - SETTINGS
        {
            path: '/groupadd',
            name: 'groupadd',
            component: GroupAdd
        },
        {
            path: '/groupdetail/:id',
            name: 'groupdetail',
            component: GroupDetail,
            props: true
        },
        //ADMIN - FORMS
        {
            path: '/formadd',
            name: 'formadd',
            component: FormAdd
        },
        {
            path: '/formdetail/:id',
            name: 'formdetail',
            component: FormDetail,
            props: true
        },
        //ADMIN - SETTINGS
        {
            path: '/projectadd',
            name: 'projectadd',
            component: AdminProjectAdd
        },
        {
            path: '/projectdetail/:id',
            name: 'projectdetail',
            component: AdminProjectDetail,
            props: true
        },
        {
            path: '/admininspectiondetail/:id',
            name: 'admininspectiondetail',
            component: AdminInspectionDetail,
            props: true
        },
        {
            path: '/projectview/:id',
            name: 'projectview',
            component: AdminProjectView,
            props: true
        },
        //USERS - SETTINGS
        {
            path: '/useradd',
            name: 'useradd',
            component: UserAdd
        },
        {
            path: '/userdetail/:id',
            name: 'userdetail',
            component: UserDetail,
            props: true
        },
        {
            path: '/settings',
            name: "settings",
            component: Settings,
            props: true,
            children: [
                {
                    path: 'users',
                    name: "users",
                    component: UserList
                },
                {
                    path: 'groups',
                    name: "groups",
                    component: GroupList
                },
                {
                    path: 'projects',
                    name: "projects",
                    component: ProjectList
                },
                {
                    path: 'forms',
                    name: "forms",
                    component: FormList
                }
            ]
        }
    ]
})
if (!window.indexedDB) {
    console.log("Your browser doesn't support a stable version of IndexedDB. Such and such feature will not be available.");
}
