<template>
        <div class="modal modal-fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t('passwordChange') }}</h5>
                        <button type="button" class="close" @click="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <input v-bind:class='["form-control", danger]' type="password" v-model="model.password">
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="close" data-dismiss="modal" >
                            {{ $t('close') }}
                        </button>
                        <button type="button" class="btn btn-primary" @click="setPassword" >
                            {{ $t('confirm') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import userApi from "../../../api/server/user";

export default {
    name: "modal",
    notifications: {
        showSuccess: { 
            title: 'Password change',
            message: 'Password succesfully changed!',
            type: 'success'
        },
        showError: { 
            title: 'Password change',
            message: 'Your password is too short!',
            type: 'error'
        }
    },
    methods: {
        close() {
            this.$emit("close");;
            this.danger = "";
            this.model.password = "";
        },
        async setPassword() {
            if(this.model.password.length > 3) {
                await userApi.setPassword(this.$parent.userToChangePasswordId, this.model);
                this.showSuccess();
                this.$emit("close");
                this.danger = "";
                this.model.password = "";
            } else {
                this.showError();
                this.danger = "danger";
            }
        },
    },
    data() {
        return {
            danger: "",
            model: {
                password: ""
            }
        };
    },
};
</script>

<style>
.danger {
    border: solid 1px red;
}
.modal-backdrop {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: rgb(255, 255, 255);
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 2px 2px 20px 1px;
    display: flex;
    flex-direction: column;
}
.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
    border-color: black;
}
</style>