<template>
    <div class="container py-5">
        <div class="table-responsive" >
            <div v-bind:class="['alert alert-info', hidden]" role="alert">
                Please enter your credentials <router-link to="/"><b>here</b></router-link>.
            </div>
            <table class="table table-bordered table-responsive-md">
                <thead>
                    <tr>
                        <th class="text-center">{{ $t('projects') }}</th>
                    </tr>
                    <tr>
                        <td style="padding:0" >
                            <input class="form-control" style="border:none" :placeholder="$t('filterByName')" v-model="filterInput" type="search">
                        </td>
                    </tr>
                    <tr v-if="groupList.length > 1">
                        <td style="padding:0" >
                            <select style="border:none" class="form-control" v-model="filterInputGroup" >
                                <option selected value="">{{$t('chooseGroup')}}</option>
                                <option v-for="(group, o) in groupList" :key="o"  v-bind:value="group.id">{{ group.name }}</option>
                            </select>
                        </td>
                    </tr>
                </thead>
                <tbody v-if="listOfProjects.length > 0">
                    <tr v-for="(item, i) in listOfProjects" :key="i">
                        <td>
                            <router-link :to="{ name: 'inspectiondetail', params: { id: item.id } }">
                                {{ item.name }}
                            </router-link>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        {{ $t('noAssignedProject') }}
                    </tr>
                </tbody>
                
            </table>
        </div>
        <div class="under-buttons">
            <div v-if="loginInfo.userrole == 'Admin' && loginInfo.isAuthenticated" style="float:left">
                <router-link to="settings/projects" class="btn btn-secondary"> 
                    {{ $t('settings') }}
                </router-link>
            </div>
            <router-link to="tools/indexeddb" style='float:right' class="btn btn-secondary">
                <i class="fas fa-tools"></i>
            </router-link>
            <div class="logout">
                <button type="button" class="btn btn-secondary" @click="changeUser()" style="float:right; margin-right:20px">
                    {{ $t('logout') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import ProjectApi from "../../api/server/project";
import projectIdbApi from "../../api/idb/project";
import loginIdbApi from "../../api/idb/login"
import authApi from "../../api/server/authorization"
import GroupApi from '../../api/server/group';

export default {
    name: "Projects",
    mounted() {
        this.requestData();
    },
    computed: {
        listOfProjects() {
            var projectList = this.projectList;

            if (this.filterInput) {
                const lowerCaseFilter = this.filterInput.toLowerCase()
                projectList = projectList.filter((project) => { 
                    if(project.name.toLowerCase().includes(lowerCaseFilter))

                    return project;
                });
            }
            if (this.filterInputGroup) {
                projectList = projectList.filter((project) => { 
                    if(project.groups.some(g => g.groupId == this.filterInputGroup))
                        return project;
                });
            }

            return projectList;
        }
    },
    methods: {
        async requestData() {
            this.loginInfo = await loginIdbApi.getLogin("pms");
            if(!this.loginInfo.isAuthenticated) {
                this.showAlert();
            }
            
            try {
                this.loginInfo = await authApi.auth();
                var projects = await ProjectApi.list();
                var groups = await GroupApi.list();
                // for (var i in projects) {
                //     if (projects[i].groups) {
                //         for (var g in projects[i].groups) {
                //             var groupInListIndex = this.groupList.findIndex(gr => gr.id == projects[i].groups[g]);
                //             if(groupInListIndex < 0) {
                //                 this.groupList.push(groups.find(gro => gro.id == projects[i].groups[g].groupId));
                //             }
                //         }
                //     }
                // }
                this.projectList = projects;
                this.groupList = groups;

                await projectIdbApi.storeProjects("pms", this.projectList)
                await projectIdbApi.storeGroups("pms", this.groupList)
                await loginIdbApi.saveLogin("pms", this.loginInfo)
            } catch (error) {
                this.loginInfo = await loginIdbApi.getLogin("pms");
                if(!this.loginInfo.isAuthenticated) {
                    this.showAlert();
                }
                
                let groupId = this.loginInfo.usergroup;
                var allProjects = await projectIdbApi.loadProjects("pms");
                var allGroups = await projectIdbApi.loadGroups("pms");

                if(this.loginInfo.userrole == "Admin") {
                    this.projectList = allProjects;
                    this.groupList = allGroups;
                } else {
                    for (var i in allProjects) {
                        if (allProjects[i].groups) {
                            let findIndex = allProjects[i].groups.findIndex(g => g.groupId == groupId)
                            if (findIndex > 0) {
                                this.projectList.push(allProjects[i]);
                            } 
                        }
                    }
                }
            }
        },
        showAlert() {
            this.hidden = "showAlertBlock";
        },
        async changeUser() {
            await authApi.logout();
            this.$router.push('/');
        }
    },
    data() {
        return {
            loginInfo: {
                isAuthenticated: false,
                usergroup: null,
                userrole: ""
            },
            hidden: "hidden",
            userrole: "",
            projectList: [],
            groupList: [],
            filterInput: "",
            filterInputGroup: "",
            params: "",
            name: ""
        }
    }
};
</script>

<style scoped>
    ul {
        text-align: left;
        list-style: inside;
        padding-inline-start: 0;
    }

    h2 {
        margin-top: 0px;
        display: inline-block;
    }

    button {
        display: inline-block;
    }
    .under-buttons {
        padding-top: 20px;
    }
    .table-responsive {
        max-height: calc(100vh - 70px - 3rem);
        margin: 0;
        padding: 0;
    }
    .table {
        margin-left: auto;
        margin-right: auto;
        /* width: 480px; */
    }
    .hidden {
        display: none
    }
    .showAlertBlock {
        display: block;
    }
    .settings {
        text-align: center;
    }
    .container {
        width: 100%;
        max-width: 460px;
    }
    
</style>