<template>
    <body class="" v-bind:class="['h-100 position-absolute', defaultClass]">
        <nav class="navbar navbar-expand-md navbar-dark bg-dark fixed-top" style="z-index:1031">
            <a class="navbar-brand">{{ data.name }}</a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">{{ $t('layers') }}</a>
                                <div class="dropdown-menu" v-if="this.toolLayers.length > 0">
                                <form>
                                    <a class="dropdown-item" v-for="(item, i) in this.toolLayers" :key="i">
                                        <div v-if="item.name" class="custom-control custom-checkbox" >
                                            <input type="checkbox" :checked="item.visible" @click="toggleLayer(item.name, item.visible)" class="custom-control-input" :id="item.name">
                                            <label class="custom-control-label" :for="item.name">{{ item.name }}</label>
                                        </div>
                                    </a>
                                </form>
                        </div>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">View</a>
                        <div class="dropdown-menu">
                            <button class="dropdown-item" type="button" @click="setClass('view100')">
                                Map 100%
                            </button>
                            <button class="dropdown-item" type="button" @click="setClass('view90')">
                                Map 90%
                            </button>
                            <button class="dropdown-item" type="button" @click="setClass('view80')">
                                Map 80%
                            </button>
                            <button class="dropdown-item" type="button" @click="setClass('view70')">
                                Map 70%
                            </button>
                            <button class="dropdown-item" type="button" @click="setClass('view60')">
                                Map 60%
                            </button>
                            <button class="dropdown-item" type="button" @click="setClass('view50')">
                                Map 50%
                            </button>
                             <button class="dropdown-item" type="button" @click="setClass('view40')">
                                Map 40%
                            </button>
                            <button class="dropdown-item" type="button" @click="setClass('view30')">
                                Map 30%
                            </button>
                             <button class="dropdown-item" type="button" @click="setClass('view20')">
                                Map 20%
                            </button>
                             <button class="dropdown-item" type="button" @click="setClass('view10')">
                                Map 10%
                            </button>
                        </div>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="toggleCamera" role="button" aria-haspopup="true" aria-expanded="false"><span v-if="!isCameraOpen">{{ $t('openCamera') }}</span>
                            <span v-else>{{ $t('closeCamera') }}</span>
                        </a>
                    </li>
                </ul>
                <ul class="navbar-nav px-3">
                    <li class="nav-item text-nowrap">
                        <router-link class="nav-link" :to="{ name: 'inspectiondetail', params: { id: this.$route.params.id } }">
                            {{ $t('back') }}
                        </router-link>
                    </li>
                </ul>
            </div>
        </nav>
        <div class="map-viewport clearfix">
            <div ref="map" class="map"></div>
        </div>
        <div v-if="isCameraOpen" class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document" style="max-width:unset; width: 60%">
                <div class="modal-content" style="width:100%">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t('takeAPicture') }}</h5>
                        <button type="button" class="close" data-dismiss="modal" @click="closeCamera" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div v-if="isCameraOpen" ref="cameraBox" class="camera-box" style="z-index:1000; height: 281px">  
                            <video v-show="!isPhotoTaken" ref="camera" style="background:black; width: 100%;height: 281px" autoplay></video>
                            <canvas style="display:none" ref="canvasHD" :width="data.cameraSettings.width" :height="data.cameraSettings.height"></canvas>
                            <canvas v-show="isPhotoTaken" ref="canvas" style="display:block; margin-left:auto; margin-right:auto" width="500px" height="300px"></canvas>
                        </div>
                        <!-- <div v-if="isCameraOpen" class="camera-shoot" style="display: block; text-align: center;">
                            <button type="button" class="btn button cam" @click="takePhoto">
                                <img src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png">
                            </button>
                        </div> -->
                        <div class="form-group row" style="margin:0; margin-bottom:6px">
                            <label class="col-form-label-sm col-sm-3">{{ $t('photo') }}</label>
                            <div class="col-sm-9 input-group">
                                <input type="text" readonly v-bind:class="['form-control', falsePhoto]" v-model="photoData.photoName"  id="photoName">
                                <div class="input-group-append">
                                    <button v-if="!isPhotoTaken" type="button" class="btn input-group-text" @click="takePhoto">
                                       <i class="fas fa-camera"></i>
                                    </button>
                                    <button v-else type="button" class="btn input-group-text" @click="takePhoto">
                                       <i class="fas fa-sync"></i>
                                    </button>
                                </div>
                            </div>
                            
                        </div>
                        <div class="form-group row" style="margin:0; margin-bottom:6px">
                            <label @click="lockCoordinates" class="col-form-label-sm col-sm-3">{{ $t('coordinates') }}</label>
                            <div class="col-sm-9 input-group">
                                <input type="text" readonly class="form-control" v-model="photoData.gps"  id="gps">
                                <div class="input-group-append" >
                                    <button v-if="watchPosition" @click="lockCoordinates" style="padding-left: 14px" class="btn input-group-text"><i class="fas fa-unlock"></i></button>
                                    <button v-else @click="lockCoordinates" style="padding-left: 14px" class="btn input-group-text"><i class="fas fa-lock"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row" style="margin:0; margin-bottom:6px">
                            <label class="col-form-label-sm col-sm-3">{{ $t('chainage') }}</label>
                            <div class="col-sm-9 input-group">
                                <input type="text" readonly class="form-control" v-model="photoData.chainage"  id="photoData_chainage">
                            </div>
                        </div>
                        <div class="form-group row" style="margin:0; margin-bottom:6px">
                            <label class="col-form-label-sm col-sm-3">{{ $t('sectionId') }}</label>
                            <div class="col-sm-9 input-group">
                                <input type="text" readonly class="form-control" v-model="photoData.sectionId"  id="photoData_sectionId">
                            </div>
                        </div>
                        <div class="form-group row" style="margin:0; margin-bottom:6px">
                            <label class="col-form-label-sm col-sm-3">{{ $t('sectionName') }}</label>
                            <div class="col-sm-9 input-group">
                                <input type="text" readonly class="form-control" v-model="photoData.sectionName"  id="photoData_sectionName">
                            </div>
                        </div>
                        <div class="form-group row" style="margin:0; margin-bottom:6px">
                            <label class="col-form-label-sm col-sm-3" for="photoDesc">{{ $t('description') }}</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="photoData.description"  id="photoDesc">
                            </div>
                        </div>
                        <div class="form-group row" style="display:block; margin:0; padding-right: 15px;">
                            <button type="button" @click="savePhoto" class="btn btn-primary btn-sm" style="float:right">
                                {{ $t('postPhotos') }}
                            </button>
                            <span v-if="falsePhoto === 'required'" class="col-form-label-sm" style="color:red;float:right">{{ $t('photoIsRequired') }}&nbsp;</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>      
        <div class="fixed-bottom p-3">
            <form v-if="isMounted && showForm" class="formStyle" name="mainForm" id="mainForm">
                <div>
                    <FormTemplate v-bind:formConstraints="this.formConstraints" v-bind:formClass="this.formClass" v-bind:tableForm="this.tableForm" v-bind:data="this.data" v-bind:template="this.data.form.formTemplate" v-bind:formData="this.formData"></FormTemplate>
                </div>
            </form>
            <div class="table-responsive">
                <table id="mainTable" class="table table-sm table-bordered">
                    <thead>
                        <tr>
                            <th v-if="showForm" class="text-center" style="width: 2%">#</th>
                            <th v-if="showForm" class="text-center" style="width: 2%"></th>
                            <th class="text-center" v-for="(attrName, i) in this.data.form.attributes" :key="i">{{ attrName.title }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(tableFormRow, i) in this.tableForm" :key="i" v-bind:style="styleRow(tableFormRow)">
                            <td v-if="showForm" class="text-center">
                                <i v-if="tableFormRow.deleted" class="fas fa-undo" @click="undoDeleteRow(tableFormRow)"></i>
                                <i v-else class="fas fa-times" @click="showDeleteRow(tableFormRow)"></i>
                            </td>
                            <td v-if="showForm" class="text-center">
                                <i v-if="!tableFormRow.deleted" class="fas fa-edit" @click="editRow(tableFormRow)"></i>
                            </td>
                            <td class="text-center" v-for="(attrName, e) in data.form.attributes" :key="e">
                                <span v-html="renderRow(attrName, tableFormRow[e], tableFormRow)">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>       
        <DeleteRowDialog v-show="showDeleteRowDialog" @confirm="deleteRow" @close="closeDeleteRowDialog"></DeleteRowDialog>
        <UndoRowDialog v-show="showUndoRowDialog" @confirm="undoRowDeletion" @close="closeUndoRowDialog"></UndoRowDialog>
        <ValidationModal v-show="showValidationDialog" @confirm="sendAnyway" @close="close"></ValidationModal>
        <ResetModal v-show="showResetDialog" @reset="resetFormData" @confirm="closeResetDialog" @close="closeResetDialog"></ResetModal>
    </body>
</template>

<script>
import Map from "ol/Map";
import View from "ol/View";
import VectorLayer from "ol/layer/Vector";
import Feature from "ol/Feature";
import { Point } from "ol/geom";
import VectorSource from "ol/source/Vector";
import Control from 'ol/control/Control';
import { Icon, Style, Fill, Stroke, Circle } from "ol/style";
import { fromLonLat } from "ol/proj";
import { register } from 'ol/proj/proj4';
import { Modify, Select } from 'ol/interaction';

import targetsrc from "../../images/target.png";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import proj4 from 'proj4';

import { lineStringLengthToCoordinates } from "../../utils/geom-length";
import { getFeaturesFromSections, defaultLineStyleFunction } from "../../utils/feature-ext";
import { createBaseLayer } from "../../utils/layer-ext";
import { parseCoords } from "../../utils/geom-coords";

import DbApi from "../../api/idb/net";
import inspectionIdbApi from '../../api/idb/inspection';
import projectIdbApi from '../../api/idb/project';
import loginIdbApi from "../../api/idb/login";
import photoIdbApi from "../../api/idb/photo";

import ValidationModal from "./InspectionValidationDialog";
import ResetModal from "./InspectionResetDialog";
import DeleteRowDialog from "./DeleteRowDialog";
import UndoRowDialog from "./UndoRowDialog";

export default {
    name: "Main",
    mounted() {
        this.getSummary();
        this.isMounted = true;
    },  
    notifications: {
        showNoDataNotification: { 
            title: 'Warning!',
            message: 'No archive data for current section.',
            type: 'warn'
        }
    },
    components: {
        ValidationModal,
        ResetModal,
        DeleteRowDialog,
        UndoRowDialog
    },
    destroyed() {
        this.destroyMap();
    },
    watch: {
        formData: {
            handler(value) {
                for(var i in this.formDataClone) {
                    var archiveValue = this.formDataClone[i];

                    var foundProp = value[i];
                    if(foundProp && archiveValue != foundProp) {
                        this.$set(this.formClass, i, false);   
                    }
                }
            },
            deep: true
        },
        sectionFilter: function () {
            // Co když budeme chtít označit jinačí layers nez sections ?? (archivy)
            var lay = this.map.getLayers().getArray().find(layer => layer.get('name') == 'Sections');
            if(lay) {
                lay.changed();
            }
        }
    },
    methods: { 
        styleRow(row) {
            if(!this.data.showDeletedSections && row.deleted){
                return "display:none";
            } else if(row.deleted) {
                return "background: #d6d6d6";
            } else {
                return "";
            }
        },
        renderRow(attr, row) {
            if (attr.type === "boolean" && row){
                return '<i class="fas fa-check"></i>'
            } else if (attr.type === "boolean" && !row) {
                return ''
            } else {
                return row;
            }
        },
        editRow(row) {
            this.formConstraints.formEditing = true;
            Object.assign(this.formData, row);
        },
        showDeleteRow(row) {
            this.rowToDelete = row;
            this.showDeleteRowDialog = true;
        },
        closeDeleteRowDialog() {
            this.showDeleteRowDialog = false;
        },
        async deleteRow () {
            let { id } = this.data;
            var label = this.data.label;
            let row = this.rowToDelete;
                row.deleted = true;
                row.requireUpdate = true;
            await inspectionIdbApi.storeForm(id, label || '', row)

            await this.updateSectionData(label);
            this.showDeleteRowDialog = false;
        },
        undoDeleteRow(row) {
            this.undoRow = row;
            this.showUndoRowDialog = true;
        },
        closeUndoRowDialog() {
            this.showUndoRowDialog = false;
        },
        async undoRowDeletion () {
            let { id } = this.data;
            var label = this.data.label;
            let row = this.undoRow;
                row.deleted = false;
                row.requireUpdate = true;
            await inspectionIdbApi.storeForm(id, label || '', row)

            await this.updateSectionData(label);
            this.showUndoRowDialog = false;
        },
        setZustand() {
            var data = this.formData;
            if((data.bauweise === "Asphalt" || data.bauweise === "Asphalt_1" || data.bauweise === "Asphalt_2" || data.bauweise === "Asphalt_3")
                && (data.zustandRI === "" && data.zustandRI === "" && data.zustandOS === "" && data.zustandSL === "" && data.zustandV === "" && data.zustandRS === "")) {
                data.zustandRI = 1;
                data.zustandRI = 1;
                data.zustandOS = 1;
                data.zustandSL = 1;
                data.zustandV = 1;
                data.zustandRS = 1;
            }
        },
        getResult() {
            let self = this;
            let data = this.formData;
            let RI = parseInt(data.zustandRI);
            let OS =  parseInt(data.zustandOS);
            let SL =  parseInt(data.zustandSL);
            let V = parseInt(data.zustandV);
            let RS = parseInt(data.zustandRS);
            if(RI > 0 && OS > 0 && SL > 0 && V > 0 && RS > 0) {
                self.formData.schadenswert = RI + RS + V + SL + OS;

                let schadenswert = self.formData.schadenswert;
                let massnahmeKlasse = "";

                if((RI > 2 && V > 2 && (RS > 2 || OS > 2 || SL > 2)) || ((RI > 2 || V > 2) && schadenswert > 10)){
                    massnahmeKlasse = 7;
                } else if (schadenswert > 8 && RS > 2) {
                    massnahmeKlasse = 6;
                } else if (RS == 2 && schadenswert > 8) {
                    massnahmeKlasse = 5;
                } else if (schadenswert > 8 && RS == 1 && (RI > 2 || V > 2)) {
                    massnahmeKlasse = 4;
                } else if (V < 4 && schadenswert > 7) {
                    massnahmeKlasse = 3;
                } else if (schadenswert == 5) {
                    massnahmeKlasse = 0;
                } else if (schadenswert < 8 && schadenswert > 5 && RI == 1 && RS == 1 && V < 3) {
                    massnahmeKlasse = 1;
                } else {
                    massnahmeKlasse = 2;
                }
                
                self.formData.maßnahmenklasse = massnahmeKlasse;

                let erhaltungsklasse = "";
                if(massnahmeKlasse === 7 || massnahmeKlasse === 6) {
                    erhaltungsklasse = 4; 
                } else if (massnahmeKlasse === 5 || massnahmeKlasse === 4 || massnahmeKlasse === 3) {
                    erhaltungsklasse = 3;
                } else if (massnahmeKlasse === 2) {
                    erhaltungsklasse = 2;
                } else if (massnahmeKlasse === 1 || massnahmeKlasse === 0) {
                    erhaltungsklasse = 1;
                } else {
                    erhaltungsklasse = 0;
                }
                self.formData.erhaltungsklasse = erhaltungsklasse;

                let massnahme = "";
                if(massnahmeKlasse === 7) {
                    massnahme = "N"
                } else if (massnahmeKlasse === 6) {
                    massnahme = "NT"
                } else if (massnahmeKlasse === 5) {
                    massnahme = "IT"
                } else if (massnahmeKlasse === 4) {
                    massnahme = "IF"
                } else if (massnahmeKlasse === 3) {
                    massnahme = "I_H"
                } else if (massnahmeKlasse === 2) {
                    massnahme = "I"
                } else if (massnahmeKlasse === 1) {
                    massnahme = "L"
                } else {
                    massnahme = "keineMN"
                }
                self.formData.maßnahme = massnahme;
            } else {
                self.formData.schadenswert = "";
                self.formData.erhaltungsklasse = "";
                self.formData.maßnahmenklasse = "";
                self.formData.maßnahme = "";
            }
        },
        closeResetDialog() {
            this.showResetDialog = false;
        },
        close() {
            this.showValidationDialog = false;
        },
        async getLoginInfo()  {
            var userInfo = await loginIdbApi.getLogin("pms");
            if(userInfo.team) {
                this.teamName = userInfo.team;
                this.formData.team = userInfo.team;
            } else {
                this.teamName = "";
                this.formData.team = "";
            }
        },
        getAttributes() {
            var attrs = [];
            if(this.data.copySectionAttributes) {
                if(this.selectLastFeature) {
                    attrs = this.data.copySectionAttributes;
                    if(this.selectLastFeature)
                    attrs = this.selectLastFeature.get('objectAttributes');
                } else {
                    for (var i in this.data.copySectionAttributes) {
                        attrs.push({ name: this.data.copySectionAttributes[i]}) 
                    }
                }
            }   
            
            return attrs;
        },
        lockCoordinates() {
            let self = this;
            this.watchPosition = !this.watchPosition

            if(self.selectLastFeature && self.gpsSource) {
                var features = self.gpsSource.getFeatures();
                if(features.length > 0) {
                    self.photoData.chainage = self.getChainageFromFeature(self.selectLastFeature, features[0]);
                }
            }
        },
        closeCamera() {
            this.isCameraOpen = false;
            this.watchPosition = true;
            this.photoData.chainage = "";
            this.photoData.photoName = "";
            this.stopCameraStream();
        },
        takePhoto() {
            let { videoWidth, videoHeight } = this.$refs.camera;

            // FULL quality
            this.$refs.canvasHD.width = videoWidth;
            this.$refs.canvasHD.height = videoHeight;

            const contextHD = this.$refs.canvasHD.getContext('2d');
            contextHD.drawImage(this.$refs.camera, 0, 0);

            // PREVIEW
            let { clientWidth, clientHeight } = this.$refs.cameraBox;
            let k = Math.min(1, clientWidth/videoWidth, clientHeight/videoHeight) ;
            this.$refs.canvas.height = k * videoHeight;
            this.$refs.canvas.width = k * videoWidth;

            const context = this.$refs.canvas.getContext('2d');
            context.drawImage(this.$refs.camera, 0, 0, videoWidth, videoHeight, 0, 0, this.$refs.canvas.width, this.$refs.canvas.height);

            // toggle pro vyfocení 
            const image = this.$refs.canvasHD.toDataURL("image/jpeg")
            if(image.length < 10) {
                this.falsePhoto = "required";

            } else {
                this.falsePhoto = "";
                this.photoData.photoName = "Photo_" + moment().format("YYYYMMDDHHmmss.jpg");
                this.photoData.photo = image;
                this.photoData.guid = uuidv4();
                this.isPhotoTaken = !this.isPhotoTaken;
            }
        },
        async savePhoto() {
            var { id, label } = this.data;
            if(this.falsePhoto !== "required") {
                this.photoData.label = label;
                await photoIdbApi.storePhoto(id, label, this.photoData)
                this.watchPosition = true;
                this.photoData.chainage = "";
                this.photoData.description = "";
                this.photoData.photoName = "";

                this.isCameraOpen = false;
                this.isPhotoTaken = false;
                this.stopCameraStream();
            }
        },
        createCameraElement() {
            this.falsePhoto = "required"
            let self = this;
            const constraints = {
                audio: false,
                video: true,
                video: {
                    facingMode: {
                        ideal: "environment"
                    },
                    width: { ideal: this.data.cameraSettings.width },
                    height: { ideal: this.data.cameraSettings.height }
                }
            };
            navigator.mediaDevices
                .getUserMedia(constraints)
                .then(stream => {
                    this.$refs.camera.srcObject = stream;
                    this.$refs.camera.onloadedmetadata = function(e) {
                        self.$refs.camera.play();
                    };
                    
                })
                .catch(error => {
                    alert("May the browser didn't support or there is some errors.");
                });
        },
        downloadImage() {
            const download = document.getElementById("downloadPhoto");
            const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")
                .replace("image/jpeg", "image/octet-stream");

            download.setAttribute("href", canvas);
        },
        toggleCamera() {
            this.photoData.guid = "";
            this.photoData.photoName = "";
            this.isPhotoTaken = false;

            if(this.isCameraOpen) {
                this.isCameraOpen = false;
                this.isPhotoTaken = false;
                this.stopCameraStream();
            } else {
                this.isCameraOpen = true;
                this.createCameraElement();
            }
        },
        stopCameraStream() {
            let tracks = this.$refs.camera.srcObject.getTracks();

            tracks.forEach(track => {
                track.stop();
            });
        },
        returnResult(type) {
            if(this._data.formData){
                let data = this.formData;
                if(type == "schadenswert") {
                    return data.zustandRI + data.zustandOS + data.zustandSL + data.zustandV + data.zustandRS;
                }

            } else {
                return 0;
            }
        },
        required(type) {
            if(this.formData.bauweise === ""){
                if(type === "Bauweise")
                return "required"
            }
            if(this.formData.bauweise === "Asphalt" ||
                this.formData.bauweise === "Asphalt_1" ||
                this.formData.bauweise === "Asphalt_2" ||
                this.formData.bauweise === "Asphalt_3" || 
                this.formData.bauweise === "DE_B" || 
                this.formData.bauweise === "DE_SP_A" || 
                this.formData.bauweise === "DE_SP_B" ||
                this.formData.bauweise === "DE_SP") {
                if(type === "OS" && this.formData.zustandOS === "") {
                    return "required";
                } else if (type === "RS" && this.formData.zustandRS === "") {
                    return "required";
                } else if (type === "V" && this.formData.zustandV === "") {
                    return "required";
                } else if (type === "SL" && this.formData.zustandSL === "") {
                    return "required";
                } else if (type === "RI" && this.formData.zustandRI === "") {
                    return "required";
                } else if (type === "Bergfaktor" && this.formData.bergfaktor === "") {
                    return "required";
                } else if (type === "Breite" && this.formData.breite === "") {
                    return "required";
                }
            } else if (this.formData.bauweise === "DE_S" ||
                this.formData.bauweise === "DE_PF" ||
                this.formData.bauweise === "DE_SP_S" ||
                this.formData.bauweise === "DE_BA") {
                if (type === "Bergfaktor" && this.formData.bergfaktor === "") {
                    return "required";
                } else if (type === "Breite" && this.formData.breite == "") {
                    return "required";
                } 
            }
            return "";
        },
        requiredGraz(type) {
            if(type === "von") {
                return "required"
            }
            if(this.formData.type == "1") {
                if (type === "Schaden" && this.formData.fahrbahnZustandSchaden === "") {
                    return "required";
                } else if (type === "Risse" && this.formData.fahrbahnZustandRisse === "") {
                    return "required";
                } else if (type === "Oberflache" && this.formData.fahrbahnZustandOberflache === "") {
                    return "required";
                } else if (type === "Quer" && this.formData.fahrbahnZustandQuer === "") {
                    return "required";
                } else if (type === "Langs" && this.formData.fahrbahnZustandLangs === "") {
                    return "required";
                } else if (type === "Breite" && this.formData.fahrbahnBreite === "") {
                    return "required";
                }  else if (type === "Bauweise" && this.formData.fahrbahnBauweise === "") {
                    return "required";
                }
            } else if (this.formData.type == "5") {
                if (type === "gehsteigRechteBauweise" && this.formData.gehsteigRechteBauweise === "") {
                    return "required";
                } else if (type === "gehsteigRechteBreite" && this.formData.gehsteigRechteBreite === "") {
                    return "required";
                } else if (type === "gehsteigRechteZustand" && this.formData.gehsteigRechteZustand === "") {
                    return "required";
                }
            }  if (this.formData.type == "6") {
                if (type === "gehsteigRechteBauweise" && this.formData.gehsteigRechteBauweise === "") {
                    return "required";
                } else if (type === "gehsteigRechteBreite" && this.formData.gehsteigRechteBreite === "") {
                    return "required";
                } else if (type === "gehsteigRechteZustand" && this.formData.gehsteigRechteZustand === "") {
                    return "required";
                } else if (type === "radwegRechteBauweise" && this.formData.radwegRechteBauweise === "") {
                    return "required";
                } else if (type === "radwegRechteBreite" && this.formData.radwegRechteBreite === "") {
                    return "required";
                } else if (type === "radwegRechteZustand" && this.formData.radwegRechteZustand === "") {
                    return "required";
                }
            } 
            
            return "";
        },
        setPrositionCrossIndicator(type, chainage){
            var pointChange = parseFloat(chainage);

            var selectLastFeatureGeometry = this.selectLastFeature.getGeometry();
            var length = this.selectLastFeature.get("length");
            var fraction = Math.trunc(pointChange) / Math.trunc(length); // TODO: math.trunc
            var coordinate = selectLastFeatureGeometry.getCoordinateAt(fraction);
            var nextPoint = new Point(coordinate);

            this.editInspectionPoint.setGeometry(nextPoint);
            if(type == "von") {
                this.editInspectionPointVector.setStyle(new Style({ image: new Icon({ color: "orange" , src: targetsrc.toString() }) }));
            } else {
                this.editInspectionPointVector.setStyle(new Style({ image: new Icon({ color: "blue" , src: targetsrc.toString() }) }));
            }
        },
        setValidationOverlap() {
            var findIndexVon = null;
            var findIndexBis = null;
            if(this.tableForm) {
                findIndexVon = this.tableForm.find(s => parseFloat(s.von) < this.formData.von && this.formData.von < parseFloat(s.bis) && s.guid != this.formData.guid && s.deleted != true);
                findIndexBis = this.tableForm.find(s => parseFloat(s.von) < this.formData.bis && this.formData.bis < parseFloat(s.bis) && s.guid != this.formData.guid && s.deleted != true);
            }
            if(findIndexVon) {
                this.requiredVON = "required";
                this.requirementOverlap = true;
            } else if (findIndexBis) {
                this.requiredBIS = "required";
                this.requirementOverlap = true;
            } else {
                this.requirementOverlap = false;
            }
        },
        setValidationLengthOverlap() {
            if(this.selectLastFeature) {
                let maxLength = this.selectLastFeature.get("length").toFixed(2);
                if(parseFloat(this.formData.bis) > parseFloat(maxLength)) {
                    this.requiredVON = "required";
                    this.requirementOverMaxLength = true;
                } else {
                    this.requirementOverMaxLength = false;
                }
                if (parseFloat(this.formData.von) < 0) {
                    this.requiredBIS = "required";
                    this.requirementOverMinLength = true;
                } else {
                    this.requirementOverMinLength = false;
                }
            }
            
        },
        setValidationGraz() {
            var validateCommonFields = function(data) {
                return false;
            }
            var validate = {
                "1": function(data) {
                    //var notEmptyFields = [ "fahrbahnBreite", "fahrbahnBauweise" ];
                    //return notEmptyFields.find(f => data[f] === "") != null;
                    return data.fahrbahnBreite === "" ||
                            data.fahrbahnBauweise === "" ||
                            data.fahrbahnZustandLangs === "" ||
                            data.fahrbahnZustandQuer === "" ||
                            data.fahrbahnZustandOberflache === "" ||
                            data.fahrbahnZustandRisse === "" ||
                            data.fahrbahnZustandSchaden === "";
                },
                "5": function(data) {
                    return data.gehsteigRechteBauweise === "" ||
                        data.gehsteigRechteBreite === "" ||
                        data.gehsteigRechteZustand === "";
                },
                "6": function(data) {
                    return data.gehsteigRechteBauweise === "" ||
                        data.gehsteigRechteBreite === "" ||
                        data.gehsteigRechteZustand === "" ||
                        data.radwegRechteBauweise === "" ||
                        data.radwegRechteBreite === "" ||
                        data.radwegRechteZustand === "";
                }
            }

            var validationFailed = validateCommonFields(this.formData);
            var validationFunction = validate[this.formData.type];
            if(validationFunction) {
                validationFailed = validationFailed || validationFunction(this.formData);
            }
            
            this.requirement = validationFailed;
            this.requiredField = validationFailed;
        },
        setValidationTirol() {
            var validateCommonFields = function(data) {
                return false;
            }
            var validate = {
                "Asphalt": function(data) {
                    return data.zustandOS === "" || data.zustandRS === "" || data.zustandV === "" || 
                        data.zustandSL === "" || data.zustandRI === "" || data.bergfaktor === "" || 
                        data.breite === "";
                },
                "Asphalt_1": function(data) {
                    return data.zustandOS === "" || data.zustandRS === "" || data.zustandV === "" || 
                        data.zustandSL === "" || data.zustandRI === "" || data.bergfaktor === "" || 
                        data.breite === "";
                },
                "Asphalt_2": function(data) {
                    return data.zustandOS === "" || data.zustandRS === "" || data.zustandV === "" || 
                        data.zustandSL === "" || data.zustandRI === "" || data.bergfaktor === "" || 
                        data.breite === "";
                },
                "Asphalt_3": function(data) {
                    return data.zustandOS === "" || data.zustandRS === "" || data.zustandV === "" || 
                        data.zustandSL === "" || data.zustandRI === "" || data.bergfaktor === "" || 
                        data.breite === "";
                },
                "DE_B": function(data) {
                    return data.zustandOS === "" || data.zustandRS === "" || data.zustandV === "" || 
                        data.zustandSL === "" || data.zustandRI === "" || data.bergfaktor === "" || 
                        data.breite === "";
                },
                "DE_SP_A": function(data) {
                    return data.zustandOS === "" || data.zustandRS === "" || data.zustandV === "" || 
                        data.zustandSL === "" || data.zustandRI === "" || data.bergfaktor === "" || 
                        data.breite === "";
                },
                "DE_SP_B": function(data) {
                    return data.zustandOS === "" || data.zustandRS === "" || data.zustandV === "" || 
                        data.zustandSL === "" || data.zustandRI === "" || data.bergfaktor === "" || 
                        data.breite === "";
                },
                "DE_SP": function(data) {
                    return data.zustandOS === "" || data.zustandRS === "" || data.zustandV === "" || 
                        data.zustandSL === "" || data.zustandRI === "" || data.bergfaktor === "" || 
                        data.breite === "";
                },
                "DE_S": function(data) {
                    return data.bergfaktor === "" || data.breite === "";
                },
                "DE_PF": function(data) {
                    return data.bergfaktor === "" || data.breite === "";
                },
                "DE_SP_S": function(data) {
                    return data.bergfaktor === "" || data.breite === "";
                },
                "DE_BA": function(data) {
                    return data.bergfaktor === "" || data.breite === "";
                }
            }

            var validationFailed = validateCommonFields(this.formData);
            if(this.formData.bauweise === "") {
                validationFailed = true;
            }
            var validationFunction = validate[this.formData.bauweise];
            if(validationFunction) {
                validationFailed = validationFailed || validationFunction(this.formData);
            }
            
            this.requirement = validationFailed;
            this.requiredField = validationFailed;
        },
        setValidationVonBis() {
            let data = this.formData;
            var validation = data.von == "" || data.bis == "" || parseFloat(data.von) >= parseFloat(data.bis);
            this.requirementLowerToVon = validation;
        },
        setValidationSectionId() {
            if(this.formData.sectionId) {
                this.requirementSection = false;
            } else {
                this.requirementSection = true;
            }
        },
        getClosestPoint(type) {
            let self = this;
            if(type == "bis") {
                let dataAfter = self.tableForm.filter(e => parseFloat(e.von) >= parseFloat(self.formData.von) && e.guid != self.formData.guid);
                if(dataAfter != null && dataAfter.length > 0) {
                    self.formData.bis = parseFloat(dataAfter[0].von).toFixed(2); // first after
                } else {
                    if(self.selectLastFeature) {
                        var length = self.selectLastFeature.get("length");
                        self.formData.bis = length.toFixed(2);
                    } else {
                        self.formData.bis = 0;
                    }
                }
                self.setPrositionCrossIndicator("bis", self.formData.bis);

            } else if(type == "von") {
                let dataBefore = self.tableForm.filter(e => parseFloat(e.bis) <= parseFloat(self.formData.bis) && e.guid != self.formData.guid)
                if(dataBefore != null && dataBefore.length > 0) {
                    self.formData.von = parseFloat(dataBefore[dataBefore.length - 1].bis).toFixed(2); 
                } else {
                    self.formData.von = 0;
                }
                self.setPrositionCrossIndicator("von", self.formData.von);
            }
        },
        setNewCoordinates(type) {
            if(type == "von") {
                this.setFormDataFromArchive();
                var findIndexVon = this.tableForm.find(s => parseFloat(s.von) < parseFloat(this.formData.von) && parseFloat(this.formData.von) < parseFloat(s.bis) && s.guid != this.formData.guid);
                if(findIndexVon) {
                    this.requiredVON = "required";
                    this.requirement = true
                } else {
                    this.requiredVON = "";
                    this.requirement = false
                }
                this.formConstraints.minBisLenght = this.formData.von; // set minimum for BIS
                this.setPrositionCrossIndicator(type, this.formData.von);
            } else if (type == "bis") {
                var findIndexBis = this.tableForm.find(s => parseFloat(s.von) < this.formData.bis && this.formData.bis < parseFloat(s.bis)  && s.guid != this.formData.guid);
                if(findIndexBis) {
                    this.requiredBIS = "required";
                    this.requirement = true
                } else {
                    this.requiredBIS = "";
                    this.requirement = false
                }
                this.setPrositionCrossIndicator(type, this.formData.bis);
            }
        },
        setCursor(type) {
            if(this.selectLastFeature) {
                this.cursorType = type;
                if(type == "von") {
                    this.setPrositionCrossIndicator(type, this.formData.von);
                } else if (type == "bis") {
                    this.setPrositionCrossIndicator(type, this.formData.bis);
                }
            }
        },
        setPositionVON(type) {
            let self = this
            if(type == "start") {
                if(self.selectLastFeature) {
                    self.cursorType = "von";
                    self.formData.von = "0";
                    self.setFormDataFromArchive();
                    self.setPrositionCrossIndicator("von", self.formData.von);
                }
            } else if(type == "gps") {
                if(self.selectLastFeature && self.gpsSource) {
                    var features = self.gpsSource.getFeatures();
                    if(features.length) {
                        self.cursorType = "von";
                        self.formData.von = parseFloat(self.getChainageFromFeature(self.selectLastFeature, features[0])).toFixed(2);
                        self.setFormDataFromArchive();
                        self.setPrositionCrossIndicator("von", self.formData.von);
                    }
                }
            } else if(type == "end") {
                if(self.selectLastFeature) {
                    self.cursorType = "von";
                    self.formData.von = self.selectLastFeature.get("length").toFixed(2);
                    self.setFormDataFromArchive();
                    self.setPrositionCrossIndicator("von", self.formData.von);
                }
            }
        },
        setPositionBIS(type) {
            let self = this
            if(type == "start") {
                if(self.selectLastFeature) {
                    self.cursorType = "bis";
                    self.formData.bis = 0;
                    self.setPrositionCrossIndicator("bis", self.formData.bis);
                }
            } else if(type == "gps") {
                if(self.selectLastFeature && self.gpsSource) {
                    var features = self.gpsSource.getFeatures();
                    if(features.length > 0) {
                        self.cursorType = "bis";
                        self.formData.bis = parseFloat(self.getChainageFromFeature(self.selectLastFeature, features[0])).toFixed(2);
                        self.setPrositionCrossIndicator("bis", self.formData.bis);
                    }
                }
            } else if(type == "end") {
                if(self.selectLastFeature) {
                    self.cursorType = "bis";
                    self.formData.bis = self.selectLastFeature.get("length").toFixed(2);
                    self.setPrositionCrossIndicator("bis", self.formData.bis);
                }
            }
        },
        async sendAnyway() {
            let { id, label } = this.data;
            this.formData.posted = false;

            if(this.formConstraints.formEditing) {
                this.formData.requireUpdate = true;
                this.formData.posted = true;
            }

            if(!this.formData.guid) {
                this.formData.guid = uuidv4();
            }

            this.formData.label = label || '';
            await inspectionIdbApi.storeForm(id,  label || '', this.formData)
            this.formData.guid = uuidv4();
            this.formData.von = this.formData.bis;
            this.formData.posted = false;

            // Nastavení dat do formu z předešlého archívu
            if(this.formConstraints.formEditing) {
                this.resetFormData();
            } else {
                this.setFormDataFromArchive();
            }

            await this.updateSectionData(label)
        },
        async sendData (type) {
            let { id, label } = this.data;
            this.setValidationOverlap();
            this.setValidationVonBis();
            this.setValidationSectionId();
            this.setValidationLengthOverlap();

            if (type == "graz") {
                this.setValidationGraz();
            } else {
                this.setValidationTirol();
            }
            
            if(this.requirement || this.requirementOverlap || this.requiredField || this.requirementLowerToVon || this.requirementSection || this.requirementOverMaxLength || this.requirementOverMinLength) {
                this.showValidationDialog = true;
            } else {
                this.formData.posted = false;
                if(type == "update") {
                    this.formData.posted = true;
                    this.formData.requireUpdate = true;
                    this.formConstraints.formEditing = false;
                }

                if(!this.formData.guid) {
                    this.formData.guid = uuidv4();
                }
                this.formData.label = label || '';
                await inspectionIdbApi.storeForm(id, label || '', this.formData);
                this.formData.guid = uuidv4();
                this.formData.von = this.formData.bis;
                this.formData.posted = false;

                if(type == "update") {
                    this.resetFormData();
                } else {
                    this.setFormDataFromArchive();
                }
                await this.updateSectionData(label);
            }
        },
        showAllLayers() {
            let self = this
            self.toolLayers = [];
            if(this.map) {
                this.map.getLayers().forEach(function (layer) {
                    self.toolLayers.push(layer.values_);
                });
            }
        },
        updateTable(data) {
            data.sort((a,b) => {
                return a.von - b.von;
            });
            this.tableForm = data;
        },
        async updateSectionData(sectionLabel) {
            let { id, label } = this.data;
            var data = await inspectionIdbApi.loadInspection(id, sectionLabel, this.formData.sectionId);
            
            if(sectionLabel == label)
                this.updateSummary(data, this.formData.sectionId);

            this.updateTable(data);
        },
        setClass(layout) {
            var self = this;
            var layoutName = layout;
            // if(this.data.form.name === "Graz") 
            //     layoutName = layout + "Graz";

            self.defaultClass = layoutName;
            window.localStorage.setItem("layout", layoutName);
            // #warn odložená aktualizace mapy až po uplatnění změny class
            setTimeout(function () { self.map.updateSize(); }, 200);
        },
        setForm(formType) {
            var self = this;
            self.data.form = formType;
        },
        unselectFeature() {
            var self = this;

            var feature = self.select.getFeatures()
            feature.clear();
            
            self.sectionSeleted([]);
            self.setCursor("von");
        },
        toggleLayer(layerName, visible) {
            var self = this;

            self.map.getLayers().forEach(function (layer) {
                if (layer.get("name") === layerName) {
                    if(layer.get("isFeature") == true) {
                        self.unselectFeature();
                    }
                    layer.setVisible(!visible);
                }
            });
            for (var layer in this.map.getLayers().array_) {
                if(this.map.getLayers().array_[layer].get('archive') == true && this.map.getLayers().array_[layer].get('visible')) {
                    self.showForm = false;
                    break;
                } else {
                    self.showForm = true;
                }
            }
        },
        getChainageFromFeature(line, feature) {
            if(line && feature) {
                var lineGeometry = line.getGeometry();
                var realLength = line.get("length");
                var point = feature.getGeometry()
                var firstPoint = point.getFirstCoordinate();

                var lengthToPoint = lineStringLengthToCoordinates(lineGeometry, realLength, firstPoint[0], firstPoint[1]);
                return lengthToPoint.toFixed(2);
            }
        },
        async setFormDataFromArchive() {
            var self = this;
            if(self.data.copyDataFromArchive != null) {
                let sectionId = self.selectLastFeature.get("id");
                let inspData = await inspectionIdbApi.getInspectionData(self.data.id, self.data.copyDataFromArchive, self.formData.von, sectionId);
                if(inspData) {
                    for(var atrName in self.data.form.attributes) {
                        let attr = self.data.form.attributes[atrName];
                        if(attr.takeOver) {
                            self.formClass[atrName] = true;

                            self.formData[atrName] = inspData[atrName];
                            self.formDataClone[atrName] = inspData[atrName];
                        }
                    }
                } else {
                    this.resetFormClass();
                    this.showNoDataNotification();
                }
            }
        },
        resetFormClass() {
            var self = this;
            for(var i in self.formClass) {
                self.formClass[i] = false;
            }
        },
        dataFromArchive(type) {
            if(this.formClass[type] == true) {
                return 'data-from-archive';
            } else {
                return '';
            }
        },
        sectionSeleted(sectionCollection) {
            var self = this;
            if (sectionCollection && sectionCollection.length > 0) {
                self.selectLastFeature = sectionCollection[0];
                let sectionId = self.selectLastFeature.get("id");
                let sectionName = self.selectLastFeature.get("name");
                let label = self.selectLastFeature.get("label");

                var attrs = self.selectLastFeature.get("objectAttributes");
                if(attrs) {
                    for(var i in attrs) {
                        self.formData[attrs[i].name] = attrs[i].value;
                    }
                }

                self.formData.von = "0";
                self.formData.bis = "0";
                if(self.data.copyDataFromArchive) {
                    this.setFormDataFromArchive();
                } 
                self.formData.sectionId = sectionId;
                self.formData.sectionName = sectionName;
                self.photoData.sectionId = sectionId;
                self.photoData.sectionName = sectionName;

                // set cross visible
                self.editInspectionPointVector.setVisible(true)
                self.setPrositionCrossIndicator("von", self.formData.von);

                // set section limits
                // var selectLastFeatureGeometry = self.selectLastFeature.getGeometry();
                let lineLength = self.selectLastFeature.get("length");

                // var utmLen = self.selectLastFeature.get("utmLength")

                // var len = utmLength(selectLastFeatureGeometry);
                
                self.$set(self.formConstraints, "maxLineLength", lineLength)

                // load section inspected sections
                self.updateSectionData(label || '');
            } else {
                self.selectLastFeature = null;
                
                // clear section in form 
                self.formData.sectionId = "";
                self.formData.sectionName = "";
                self.photoData.sectionId = "";
                self.photoData.sectionName = "";

                // set cross invisible
                self.editInspectionPointVector.setVisible(false)
                self.tableForm = null;
                this.resetFormClass();
            }
        },
        stopEditingFormData() {
            this.formConstraints.formEditing = false;
            this.resetFormData()
        },
        resetFormDataDialog() {
            this.showResetDialog = true;
        },
        resetFormData() {
            let attrs = this.data.form.attributes;
            for(var key in attrs) {
                if(!attrs[key].revokeCopy) {
                    var defVal = attrs[key].defaultValue || "";
                    if(attrs[key].type == "date" && attrs[key].defaultClass == "1") {
                        defVal = moment().format('YYYY-MM-DD');
                    } else if(attrs[key].alias == "team") {
                        defVal = this.teamName;
                    }   
                    this.$set(this.formData, key, defVal)
                }
            }
            this.$set(this.formData, "guid", "");
            this.getResult();
        },
        changeNumberFormat(e) {
            let self = this;
            this.$set(self.formData, "breite", e.target.value.replace(/,/g, '.'))
        },
        defaultFormData() {
            let attrs = this.data.form.attributes;
            for(var key in attrs) {
                var defVal = attrs[key].defaultValue || "";
                if(attrs[key].type == "date" && attrs[key].defaultClass == "1") {
                    defVal = moment().format('YYYY-MM-DD');
                } else if(attrs[key].alias == "team") {
                    defVal = this.teamName;
                } 
                this.$set(this.formData, key, defVal)
            }
        },
        updateSectionForm() {
            let attrs = this.data.form.attributes;
            for(var key in attrs) {
                if(!attrs[key].revokeCopy) {
                    var defVal = attrs[key].defaultValue || "";
                    if(attrs[key].type == "date" && attrs[key].defaultClass == "1") {
                        defVal = moment().format('YYYY-MM-DD');
                    } else if(attrs[key].alias == "team") {
                        defVal = this.teamName;
                    } 
                    this.$set(this.formData, key, defVal)
                }
            }
        },
        async getSummary() {
            let { id } = this.$route.params;
            var data = await projectIdbApi.loadProject("pms", parseInt(id));

            var layout = window.localStorage.getItem('layout');
            if(layout) {
                this.defaultClass = layout;
            }
            
            Object.assign(this.data, data);
            this.getLoginInfo();
            this.generateFormClass();
            this.initMap();
        },
        generateFormClass() {
            for(var i in this.data.form.attributes) {
                this.$set(this.formClass, [i] , false);
            }
        },
        async updateSummary(data, sectionId) {
            let inspectionLen = 0;  
            for (var i in data) {
                if(!data[i].deleted || undefined || null){
                    var record = data[i]
                    inspectionLen += parseFloat(record.bis) - parseFloat(record.von);
                }
            };
            if(inspectionLen > 0)
                this.summaryList[sectionId] = inspectionLen;
        },
        async initMap() {
            var self = this;
            var { id, label } = this.data;
            var layers = [];
            
            // release previous map
            if (this.map) {
                this.map.setTarget(undefined);
            }
            
            // init new map
            proj4.defs('ProjectEpsg' + id, self.data.projection);
            register(proj4);

            // basemaps

            self.data.map.baseMaps.forEach(function(baseMap){
                var layer = createBaseLayer(baseMap, id);
                if(layer) {
                    layers.push(layer);
                }
            })
            var fill = new Fill({
                color: self.data.map.gpsColor || '#00ff51'
            });
            var stroke = new Stroke({
                color: self.data.map.gpsColor || '#00ff51',
                width: 1.25
            });
            let dataFromServer = await inspectionIdbApi.loadFormData(id, label || '');
            let summaryList = {};
            for (var i in dataFromServer) {
                if(!dataFromServer[i].deleted || undefined || null){
                    var section = dataFromServer[i]
                    if(!summaryList[section.sectionId]) summaryList[section.sectionId] = 0;
                    summaryList[section.sectionId] += parseFloat(section.bis) - parseFloat(section.von);
                }
            };

            self.summaryList = summaryList;
            // TODO - Načítání stránky "main", je výrazně pomalejší kvůli načítání všech prvků na mapě.. třeba řešit jinak?
            var sections = await DbApi.loadNet(id, label || '');
            
            var features = getFeaturesFromSections(sections, this.data.projection, this.data.map.sectionAttributeName, this.data.copySectionAttributes);

            if (features.length > 0) {
                var vector = new VectorLayer({ 
                    name: "Sections",
                    isFeature: true,
                    source: new VectorSource({ features: features }),
                    visible: true
                    
                })
                vector.setStyle((feature, resolution) => {
                    var isSelected = false;
                    if(self.selectLastFeature) {
                        isSelected = self.selectLastFeature.get("id") == feature.get("id") && self.selectLastFeature.get("label") == feature.get("label")
                    }
                    if(!isSelected) {
                        var canHighlight = false;
                        if(self.sectionFilter && self.sectionFilter.length > 3 && feature.get('name')) {
                            canHighlight = (feature.get('name').toUpperCase()).indexOf(self.sectionFilter.toUpperCase()) >= 0;
                        }
                        
                        if(resolution < 20) {
                            let len = self.summaryList[feature.get("id")];
                            if(len) {
                                let lineLength = feature.get("length");
                                let finalLength = (len / lineLength)*100;
                                if(finalLength > parseInt(self.data.completedAt)) { 
                                    return defaultLineStyleFunction(feature, resolution, self.data.map.completedLineColor || "#FF0000", canHighlight, self.data.map.highlightedLineColor || "#FF00F7")
                                } else {
                                    return defaultLineStyleFunction(feature, resolution, self.data.map.progressLineColor || "#FF7F50", canHighlight, self.data.map.highlightedLineColor || "#FF00F7")
                                }
                            }
                        }
                        return defaultLineStyleFunction(feature, resolution, self.data.map.lineColor, canHighlight, self.data.map.highlightedLineColor || "#FF00F7");
                    } else {
                        // highlighted featury nepřepisujem
                    }
                })
                layers.push( vector );
            }
            if(self.data.archives) {
                for(var i in self.data.archives) {
                    let inspectionsSummaryList = {};  
                    let archive = self.data.archives[i];
                    var indexDbName = id + '_' + archive.label;

                    await DbApi.init(indexDbName);
                    var sections = await DbApi.loadNet(indexDbName);
                    let inspections = await inspectionIdbApi.loadFormData(indexDbName);

                    var features = getFeaturesFromSections(sections, this.data.projection, this.data.map.sectionAttributeName, this.data.copySectionAttributes);
                    
                    for (var i in inspections) {
                        if(!inspections[i].deleted || undefined || null){
                            var archiveSection = inspections[i];
                            if(!inspectionsSummaryList[archiveSection.sectionId]) inspectionsSummaryList[archiveSection.sectionId] = 0;
                            inspectionsSummaryList[archiveSection.sectionId] += parseFloat(archiveSection.bis) - parseFloat(archiveSection.von);
                        }
                    };
                    if (features.length > 0) {
                        var vector = new VectorLayer({ 
                            name: archive.name,
                            archive: true,
                            isFeature: true,
                            source: new VectorSource({ features: features }),
                            style: (feature, resolution) => {
                                if(resolution < 20) {
                                    let len = inspectionsSummaryList[feature.get("id")];
                                    if(len) {
                                        let lineLength = feature.get("length");
                                        let finalLength = (len / lineLength)*100;
                                        if(finalLength > parseInt(self.data.completedAt)) {
                                            return defaultLineStyleFunction(feature, resolution, self.data.map.completedLineColor || "#FF0000");
                                        } else {
                                            return defaultLineStyleFunction(feature, resolution, self.data.map.progressLineColor || "#FF7F50");
                                        }
                                    }
                                }
                                return defaultLineStyleFunction(feature, resolution, self.data.map.lineColor)
                            },
                            visible: false
                        })
                        layers.push( vector );
                    }
                }
            }

            var view = new View({ center: parseCoords(self.data.map.center), zoom: 16 });
            self.map = new Map({
                target: this.$refs["map"],
                layers: layers,
                view: view,
            });

            self.selectLastFeature = null;
            self.select = new Select({ active: true, style: (feature, resolution) => defaultLineStyleFunction(feature, resolution, self.data.map.selectedLineColor || "#FF0048") });
            self.select.on('select', (evt) => {
                self.sectionSeleted(evt.selected);
                self.setCursor("von");
                //Reset formu při zvolení nové linestring?
                // self.resetFormData()
            });
           
            self.map.addInteraction(self.select);

            var vectorSource = new VectorSource({
                features: features,
                wrapX: false,
            });

            self.gpsPosition = null;
            self.gpsSource = new VectorSource();
            self.gpsLayer = new VectorLayer({
                name: "GPS",
                source: self.gpsSource,
                style: new Style({ image: new Circle({
                    fill: fill,
                    stroke: stroke,
                    radius: 5
                }),
                    fill: fill,
                    stroke: stroke
                })
            });

            navigator.geolocation.watchPosition(function(pos) {
                if(self.watchPosition == true) {
                    self.photoData.gps = pos.coords.latitude.toFixed(7) + " " + pos.coords.longitude.toFixed(7);
                }

                const coords = [pos.coords.longitude, pos.coords.latitude];
                self.gpsSource.clear(true);
                self.gpsSource.addFeatures([ new Feature(new Point(fromLonLat(coords)))]);
            }, function(error) {
                alert(`ERROR: ${error.message}`);
            }, {
                enableHighAccuracy: true
            });

            this.map.addLayer(self.gpsLayer);

            const locate = document.createElement('div');
            locate.className = 'ol-control ol-unselectable locate';
            locate.style = 'top:6em; left:.5em';
            locate.innerHTML = '<button type="button" title="Locate me">◎</button>';

            locate.addEventListener('click', function() {
                if (!self.gpsSource.isEmpty()) {
                    self.map.getView().fit(self.gpsSource.getExtent(), {
                        maxZoom: 20,
                        duration: 100
                    });
                }
            });

            this.map.addControl(new Control({
                element: locate,
            }));

            self.editInspectionPoint = new Feature({
                geometry: new Point([1340237.2318636572, 6024536.137740121]),
                id: "inspection-drag-point",
                name: "Drag point"
            });
            var editInspectionPointSource = new VectorSource();
            editInspectionPointSource.addFeature(self.editInspectionPoint);

            self.editInspectionPointVector = new VectorLayer({
                source: editInspectionPointSource,
                visible: false,
                style: new Style({ image: new Icon({ color: self.cursorColor , src: targetsrc.toString() }) })
            });

            var modify = new Modify({ source: editInspectionPointSource });
            modify.on('modifyend', function (evt) {
                if(self.selectLastFeature && evt.features && evt.features.getLength() > 0) {
                    var feature = evt.features.item(0);
                    var lengthToPoint = self.getChainageFromFeature(self.selectLastFeature, feature);
                    if(lengthToPoint >= 0) {
                        if(self.cursorType == "von") {
                            self.$set(self.formData, "von", lengthToPoint);
                            self.setFormDataFromArchive();
                        } else {
                            self.$set(self.formData, "bis", lengthToPoint)
                        }
                    }
                }
            });
            this.map.addInteraction(modify);

            this.map.addLayer(self.editInspectionPointVector);

            const lockButton = document.createElement('div');
            lockButton.className = 'ol-control';
            lockButton.style = 'display:block; top:.5em; right:.5em;';
            lockButton.innerHTML = ` <button id="buttonDone" style="display: inline-block; width:8.375em;">${this.$t('lockSection')}</button> `;

            lockButton.addEventListener('click', function() {
                if(self.formData) {
                    var ii = self.formData.sectionId;
                    if(ii) {
                        self.select.setActive(false)
                        lockButton.style = "display:none"
                        unlockButton.style = "display: block; top:.5em; right:.5em; pointer-events: auto"
                        closestFeature.style = "display: none"
                        
                        if(self.lockedSection != "") {
                            self.showResetDialog = true;
                        }
                        self.lockedSection = self.formData.sectionId.toString();
                    } else {

                    }
                } else {
                   // TODO: error message - select first 
                }
                
            });

            const unlockButton = document.createElement('div');
            unlockButton.className = 'ol-control';
            unlockButton.style = 'display:none; top:.5em; right:.5em;';
            unlockButton.innerHTML = ` <button id="buttonDone" type="button" style="display: inline-block; width:8.375em;">${this.$t('unlockSection')}</button> `;

            unlockButton.addEventListener('click', function() {
                self.select.setActive(true)
                unlockButton.style = "display:none"
                lockButton.style = "display: block; top:.5em; right:.5em; pointer-events: auto"
                closestFeature.style = "display: block; top:3em; right:.5em; pointer-events: auto"
            });

            const closestFeature = document.createElement('div');
            closestFeature.className = 'ol-control';
            closestFeature.style = 'display:block; top:3em; right:.5em;';
            closestFeature.innerHTML = ` <button id="buttonDone" style="display: inline-block; width:10.375em;">${this.$t('closestFeature')}</button> `;

            closestFeature.addEventListener('click', function() {
                if(self.gpsSource) {
                    var features = self.gpsSource.getFeatures();
                    if(features.length) {
                        var point = features[0].getGeometry()
                        var firstPoint = point.getFirstCoordinate();
                        var closestFeature = vectorSource.getClosestFeatureToCoordinate(firstPoint);
                        
                        var selectedFeatures = self.select.getFeatures();
                        selectedFeatures.clear();
                        selectedFeatures.push(closestFeature);
                        self.select.changed();

                        self.sectionSeleted(selectedFeatures.getArray());
                    }
                } 
            });

            const filterSections = document.createElement('div');
            filterSections.className = 'ol-control';
            filterSections.style = 'display:block; top:5.5em; right:.5em;';
            filterSections.innerHTML = `<div class="input-group">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text">🔍</span>
                                            </div>
                                            <input class="form-control" id="buttonDone" type="text" >
                                        </div>`;
            
            filterSections.addEventListener('input', function(event) {
                var { value } = event.target;
                self.sectionFilter = value || "";
                
            });

            this.map.addControl(new Control({ element: closestFeature }));
            this.map.addControl(new Control({ element: lockButton }));
            this.map.addControl(new Control({ element: unlockButton }));
            this.map.addControl(new Control({ element: filterSections }));

            // var displaySnap = function (coordinate) {
            //     var closestFeature = vectorSource.getClosestFeatureToCoordinate(coordinate);
            //     //TODO Vyplnit /TO
            //     if (closestFeature) {
            //         self.sectionId = closestFeature.get("id")
            //         self.straßenname = closestFeature.get("name")
            //     }

            //     self.map.render();
            // };
            
            // self.map.on('pointermove', function (evt) {
            //     var coordinate = self.map.getEventCoordinate(evt.originalEvent);
            //     displaySnap(coordinate);
            // })


            //TODO - Asi by to chtělo použít await, aby funkce počkala na načtení všech layoutů a až poté se načetla mapa.
            this.showAllLayers();
            this.defaultFormData();
        },
        destroyMap() {
            if (this.map) {
                this.map.setTarget(undefined);
            }
        }
    }, 
    data() {
        return {  
            sectionFilter: "",
            selectLastFeature: null,
            showForm: true,
            disabledBtn: false,
            falsePhoto: "required",
            summaryList: {},
            isMounted: false,
            showDeleteRowDialog: false,
            showUndoRowDialog: false,
            rowToDelete: {},
            requirementOverMinLength: false,
            requirementOverMaxLength: false,
            requirementOverlap: false,
            requirementLowerToVon: false,
            requiredField: false,
            requirement: false,
            requirementSection: false,
            requiredVON: "",
            requiredBIS: "",
            showResetDialog: false,
            showValidationDialog: false,
            teamName: "",
            watchPosition: true, 
            photoData: {
                photoName: "",
                sectionId: "",
                sectionName: "",
                chainage: "",
                gps: "",
                guid: "",
                description: "",
                photo: ""
            },
            isCameraOpen: false,
            isPhotoTaken: false,
            formConstraints: {
                formEditing: false,
                maxLineLength: 0,
                minBisLenght: 0,
            },
            cursorType: "von",
            formData: {},
            formClass: {},
            formDataClone: {},
            lockedSection: "",
            map: null,
            toolLayers: [],
            data: {
                attributes: [],
                showDeletedSections: true,
                form: "default",
                cameraSettings: {
                    width: 1920,
                    height: 1080
                },
                map: {
                    lineColor: "#ffcc33",
                    baseMaps: [],
                },
            },
            defaultClass: "view50",
            tableForm: []
        };
    }
};
</script>

<style scoped>
.archive-border {
    border: 1px solid red;
}
button.cam {
    height: 60px;
    width: 60px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}
button.cam img {
    height: 35px;
    object-fit: cover;
}
.forceSave {
    display: none;
}
.required {
    border: 1px solid rgb(255, 136, 0);
}
.ol-control button {
    font-size: inherit;
}
.col-form-label-sm {
    padding-bottom: 0;
}
.smallForm .form-group {
    margin-bottom: 0;
}
.smallForm .form-group.row.firstDiv {
    padding-top: 10px;
}
.smallForm .lastLabel label {
    padding-left: 5px;
}
.smallForm .form-row .short {
    max-width: 14.8%;
}
.smallForm .form-row .team {
    margin-left: 5px;
}
.buttonSave {
    margin-bottom: calc(0.25rem + 1px);
}
h6 {
    border-bottom: 1px solid black;
}
#notification.show {
    visibility: hidden !important;
}
.map-viewport {
    position: fixed;
    top: 56px;
    right: 0;
    left: 0;
    z-index: 100;
} 
.map-viewport .map {
    height: 100%;
    width: 100%;
}
@media screen { 
    .view100 .fixed-bottom {
        top: 100%;
        overflow: auto;
    }
    .view100 .map-viewport {
        height: calc(100% - 56px);
    }
    .view90 .fixed-bottom {
        top: 90%;
        overflow: auto;
    }
    .view90 .map-viewport {
        height: calc(90% - 56px);
    }
    .view80 .fixed-bottom {
        top: 80%;
        overflow: auto;
    }
    .view80 .map-viewport {
        height: calc(80% - 56px);
    }
    .view75 .fixed-bottom {
        top: 75%;
        overflow: auto;
    }
    .view75 .map-viewport {
       height: calc(75% - 56px);
    }
    .view70 .fixed-bottom {
        top: 70%;
        overflow: auto;
    }
    .view70 .map-viewport {
        height: calc(70% - 56px);
    }
    .view60 .map-viewport {
        height: calc(60% - 56px);
    }
    .view60 .fixed-bottom {
        top: 60%;
        overflow: auto;
    }
    .view50 .map-viewport {
        height: calc(50% - 56px);
    }
    .view50 .fixed-bottom {
        top: 50%;
        overflow: auto;
    }
    .view45 .map-viewport {
        height: calc(45% - 56px);
    }
    .view45 .fixed-bottom {
        top: 45%;
        overflow: auto;
    }
    .view40 .map-viewport {
        height: calc(40% - 56px);
    }
    .view40 .fixed-bottom {
        top: 40%;
        overflow: auto;
    }
    .view30 .map-viewport {
        height: calc(30% - 56px);
    }
    .view30 .fixed-bottom {
        top: 30%;
        overflow: auto;
    }
    .view20 .map-viewport {
        height: calc(20% - 56px);
    }
    .view20 .fixed-bottom {
        top: 20%;
        overflow: auto;
    }
    .view15 .map-viewport {
        height: calc(15% - 56px);
    }
    .view15 .fixed-bottom {
        top: 15%;
        overflow: auto;
    }
    .view10 .map-viewport {
        height: calc(10% - 56px);
    }
    .view10 .fixed-bottom {
        top: 10%;
        overflow: auto;
    }
}
/* @media screen and (orientation:landscape) {
    .map .map-viewport {
        height: calc(88% - 56px);
    }
    .map .fixed-bottom {
        top: 88%;
        overflow: auto;
    }
    .mapForm .map-viewport {
        height: calc(50% - 56px);
    }
    .mapForm .fixed-bottom {
        top: 50%;
        overflow: auto;
    }
    .mapFormTable .map-viewport {
        height: calc(30% - 56px);
    }
    .mapFormTable .fixed-bottom {
        top: 30%;
        overflow: auto;
    }
    .mapGraz .map-viewport {
        height: calc(88% - 56px);
    }
    .mapGraz .fixed-bottom {
        top: 88%;
        overflow: auto;
    }
    .mapFormGraz .map-viewport {
        height: calc(52% - 56px);
    }
    .mapFormGraz .fixed-bottom {
        top: 52%;
        overflow: auto;
    }
    .mapFormTableGraz .map-viewport {
        height: calc(30% - 56px);
    }
    .mapFormTableGraz .fixed-bottom {
        top: 30%;
        overflow: auto;
    }
} */

.mainmap > .map-viewport {
    height: calc(80% - 56px);
}
.mainmap > .fixed-bottom {
    top: 80%;
    overflow: auto;
}
</style>