<script>
    export default {
        props: {
            formData: {},
            formClass: {},
            tableForm: {},
            formOptions: {},
            typeOption: {},
            typeForm: {},
            data: {},
            formConstraints: {},
            template: {}
        },
        render(h) {
            var self = this;
            const render = {
                data: function() {
                    return {
                        showDeleteRowDialog: false,
                        rowToDelete: {},
                        requirementOverlap: false,
                        requirementLowerToVon: false,
                        requiredField: false,
                        requirement: false,
                        requirementSection: false,
                        requiredVON: "",
                        requiredBIS: "",
                        showResetDialog: false,
                        showValidationDialog: false,
                        teamName: "",
                        imageWidth: 1920,
                        imageHeight: 1080,
                        watchPosition: true, 
                        photoData: { 
                            photoName: "",
                            sectionId: "",
                            sectionName: "",
                            chainage: "",
                            gps: "",
                            guid: "",
                            description: "",
                            photo: ""
                        },
                        isCameraOpen: false,
                        isPhotoTaken: false,
                        formConstraints: self.formConstraints,
                        cursorType: "von",  
                        requiredClass: "black",
                        formData: self.formData,
                        formClass: self.formClass,
                        typeForm: null,
                        data: self.data,
                        tool: self.$parent,
                        tableForm: self.tableForm,
                    }
                },
                mounted() {
                    this.getResult()
                },
                template: "<div>" + this.template + "</div>" ,
                methods: {
                    setZustand() {
                        this.tool.setZustand();

                        // Přidal jsem vypočítání 4 hodnot formu po změně Bauweise, je správně ?
                        this.getResult();
                    },
                    checkIfValid(e) {
                        console.log(e.target.value)
                    },
                    dataFromArchive(type) {
                        return this.tool.dataFromArchive(type);
                    },
                    defaultFormData() {
                        this.tool.defaultFormData();
                        this.tool.getResult()
                    },
                    resetFormDataDialog() {
                        this.tool.resetFormDataDialog()
                    },
                    showAttributes() {
                        this.tool.showAttributes()
                    },
                    getLoginInfo()  {
                        this.tool.getLoginInfo();
                    },
                    getAttributes() {
                        return this.tool.getAttributes();
                    },
                    closeCamera() {
                        this.tool.closeCamera();
                    },
                    takePhoto() {
                        this.tool.takePhoto();
                    },
                    savePhoto() {
                        this.tool.savePhoto();
                    },
                    createCameraElement() {
                        this.tool.createCameraElement();
                    },
                    downloadImage() {
                        this.tool.downloadImage();
                    },
                    toggleCamera() {
                        this.tool.toggleCamera();
                    },
                    stopEditingFormData() {
                        this.tool.stopEditingFormData();
                    },
                    resetFormData() {
                        this.tool.resetFormData();
                    },
                    stopCameraStream() {
                        this.tool.stopCameraStream();
                    },
                    returnResult(type) {
                        return this.tool.returnResult(type);
                    },
                    required(type) {
                        return this.tool.required(type);
                    },
                    requiredGraz(type) {
                        return this.tool.requiredGraz(type);
                    },
                    setPrositionCrossIndicator(type, chainage) {
                        this.tool.setPrositionCrossIndicator(type, chainage);
                    },
                    setValidationOverlap() {
                        return this.tool.setValidationOverlap();
                    },
                    setValidationGraz() {
                        return this.tool.setValidationGraz();
                    },
                    setValidationTirol() {
                        return this.tool.setValidationTirol();
                    },
                    setValidationVonBis() {
                        this.tool.setValidationVonBis();
                    },
                    setValidationSectionId() {
                        this.tool.setValidationSectionId();
                    },
                    getClosestPoint(type) {
                        this.tool.getClosestPoint(type);
                    },
                    setNewCoordinates(type) {
                        this.tool.setNewCoordinates(type);
                    },
                    setCursor(type) {
                        this.tool.setCursor(type);
                    },
                    setPositionVON(type) {
                        this.tool.setPositionVON(type);
                    },
                    setPositionBIS(type) {
                        this.tool.setPositionBIS(type);
                    },
                    sendAnyway() {
                        this.tool.sendAnyway();
                    },
                    sendData (type) {
                        this.tool.sendData(type);
                    },
                    showAllLayers() {
                        this.tool.showAllLayers();
                    },
                    requestTable() {
                        this.tool.requestTable();
                    },
                    setClass(layout) {
                        this.tool.setClass(layout);
                    },
                    setForm(formType) {
                        this.tool.setForm(formType);
                    },
                    toggleLayer(layerName, visible) {
                        this.tool.toggleLayer(layerName, visible);
                    },
                    getResult() {
                        return this.tool.getResult() 
                    },
                    getChainageFromFeature(lineGeometry, feature) {
                        return this.tool.getChainageFromFeature(lineGeometry, feature);
                    },
                    sectionSeleted(sectionCollection) {
                        this.tool.sectionSeleted(sectionCollection);
                    }
                }
            }
            return h(render);
        }
    };
</script>

<style>
.data-from-archive {
    background-color:rgb(255, 252, 224);
}
.required {
    border: 1px solid rgb(255, 136, 0);
}
.largeForm .centerLabel {
    text-align: center;
}
.largeForm .columnGraz {
    width: 35%;
    flex: unset;
    max-width: 35%;
}
.largeForm .columnGraz .row .col-md-3 {
    max-width: 23.5%;
}
.largeForm .columnGrazMiddle {
    width: 30%;
    flex: unset;
    max-width: 30%
}
.largeForm .columnGrazMiddle .row .col-md-3,.largeForm .columnGrazMiddle .row .col-md-4{
    max-width: 31.2%;
}
.largeForm .row .form-check-label-sm {
    font-size: 0.875rem;
}
.largeForm .form-row .row {
    margin-left: 5px;
    /* margin-right: 5px */
}
.largeForm .col-md-3,.largeForm .col-md-2,.largeForm .col-md-4 {
    padding-left: 0
}
.largeForm .col-md-6 {
    max-width: 45%;
}

.col-form-label-sm {
    padding-bottom:0
}
.smallForm {
    padding-bottom: 20px;
}
.smallForm .form-check-label {
    margin-bottom: 7px;
}
.smallForm .form-group {
    margin-bottom: 0
}
.smallForm .form-group.row.firstDiv {
    padding-top:10px
}
.smallForm .lastLabel label {
    padding-left: 5px
}
.smallForm .form-row .short {
    max-width: 14.8%;
}
.smallForm .form-row .team {
    margin-left:5px
}

.smallForm .right {
    float: right;
}
.smallForm .form-control {
    float: left;
}
.smallForm .column {
    float: left;
    width: 33.33%;
    padding: 10px;
    border-bottom: 2px solid black;
}

.smallForm .column textarea {
    margin-left: 15px;
    padding-bottom: 0px
}
.smallForm .row:after {
  content: "";
  display: table;
  clear: both;
}
.smallForm .textRight {
    display: inline-block;
    text-align: right;
}
.smallForm .textLeft {
    display: inline-block;
    text-align: left;
}
.smallForm .form-check-inline {
    display: block;
}
</style>