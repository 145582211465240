import { openDB } from 'idb';

const DbProjectApi = {
    storeProjects: async (dbName, data) => {
        var db = await openDB(dbName);
        var tx = db.transaction("projects", "readwrite");
        var storing = data.map(s => tx.store.put(s));

        await Promise.all(storing);

        await tx.done;
    },
    updateProject: async (dbName, data) => {
        var db = await openDB(dbName);
        var tx = db.transaction("projects", "readwrite");

        var storing = tx.store.put(data);

        await storing;

        await tx.done
    },
    loadProjects: async (dataName) => {
        const dbPromise = await openDB(dataName);
        const data = await dbPromise.getAll("projects");
        
        return data;
    },
    loadProject: async (dataName, id) => {
        const dbPromise = await openDB(dataName);
        const data = await dbPromise.get("projects", id);
        
        return data;
    },
    storeGroups: async (dbName, data) => {
        var db = await openDB(dbName);
        var tx = db.transaction("groups", "readwrite");
        var storing = data.map(s => tx.store.put(s));

        await Promise.all(storing);

        await tx.done;
    },
    updateGroup: async (dbName, data) => {
        var db = await openDB(dbName);
        var tx = db.transaction("groups", "readwrite");

        var storing = tx.store.put(data);

        await storing;

        await tx.done
    },
    loadGroups: async (dataName) => {
        const dbPromise = await openDB(dataName);
        const data = await dbPromise.getAll("groups");
        
        return data;
    }
};

export default DbProjectApi