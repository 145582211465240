import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import Vue from 'vue';
import App from './components/App'
import router from './router.js'
import { BFormFile } from 'bootstrap-vue'
import VueNotifications from 'vue-notifications'
import miniToastr from 'mini-toastr'
import FormTemplate from "../src/components/inspection/InspectionForm"
import { localization } from "./localization/en.json";

const toastTypes = {
    success: 'success',
    error: 'error',
    info: 'info',
    warn: 'warn'
}
miniToastr.init({ types: toastTypes })

function toast({ title, message, type, timeout, cb }) {
    return miniToastr[type](message, title, timeout, cb)
}
const options = {
    success: toast,
    error: toast,
    info: toast,
    warn: toast
}
Vue.component('b-form-file', BFormFile)
Vue.component("FormTemplate", FormTemplate)
Vue.use(VueNotifications, options)

Vue.prototype.$t = function (key) {
    var result = localization[key];
    return result || key;
}

new Vue({
    render: h => h(App),
    router, // add the router config to vue instance
}).$mount('#app');
