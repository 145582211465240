<template>
	<div class="container py-5">
		<div>
            <h2>{{ $t('form') }}</h2>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link :to="{ name: 'projects' }">
                            {{ $t('home') }}
                        </router-link>
                    </li>
                    <li class="breadcrumb-item">
                        <router-link :to="'/settings/forms'">
                            {{ $t('forms') }}
                        </router-link>
                    </li>
                    <li class="breadcrumb-item">
                        {{ $t('detail') }}
                    </li>
                </ol>
            </nav>
            <div class="row">
                <div class="form-group col-md-6">
                    <label>{{ $t('formName') }}</label>
                    <input class="form-control form-control-sm" v-model="data.name">
                </div>
                <div class="form-group col-md-6">
                    <label>&nbsp;</label>
                    <button @click="showRowDialog" style="float:right; margin-top:24px"  class="btn btn-primary btn-sm">{{ $t('addAttribute') }}</button>
                </div>
            </div>
            <table class="table">
                <thead>
                    <th class="col-form-label-sm">{{ $t('attributeName') }}</th>
                    <th class="col-form-label col-form-label-sm">{{ $t('title') }}</th>
                    <th class="col-form-label col-form-label-sm">{{ $t('type') }}</th>
                    <th class="col-form-label col-form-label-sm">{{ $t('alias') }} (shp)</th>
                    <th class="col-form-label col-form-label-sm">{{ $t('oneOf') }}</th>
                    <th class="col-form-label col-form-label-sm">{{ $t('default') }}</th>
                    <th class="col-form-label col-form-label-sm">{{ $t('takeOver') }}</th>
                    <th class="col-form-label col-form-label-sm">{{ $t('revokeCopy') }}</th>
                    <th></th>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in data.attributes" :key="i"> 
                        <th class="col-form-label col-form-label-sm">
                            <span  style="display:block; width:110px; word-wrap: break-word;" >{{ i }}</span>
                        </th>
                        <td><input class="form-control form-control-sm" v-model="item.title" /></td>
                        <td>
                            <select class="form-control form-control-sm" v-model="item.type">
                                <option v-for="(type, o) in typeOptions" :key="o"  v-bind:value="type.value">{{ type.name }}</option>
                            </select>
                        </td>
                        <td><input class="form-control form-control-sm" v-model="item.alias" /></td>
                        <td v-if="item.type == 'object'">
                            <ul class="navbar-nav mr-auto">
                                <li class="nav-item dropdown">                    
                                    <button id="btnGroupDrop1" type="button" class="btn btn-info dropdown-toggle btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {{ $t('domainSelect') }}
                                    </button>
                                    <div class="dropdown-menu">
                                        <form style="width:300px">
                                            <div class="container">
                                                <label class="col-form-label col-form-label-sm">{{ $t('title') }}</label>
                                                <i :id="item.type" style="cursor:pointer; float:right;padding-left:25px; margin-top:10px" class="fas fa-sm fa-plus" @click="addObjectType(item)"></i>
                                                <label class="col-form-label col-form-label-sm" style="float:right">{{ $t('const') }}</label>
                                            </div>
                                            <div class="container" v-for="(oneOf, o) in item.oneOf" :key="o">
                                                <div class="input-group input-group-sm">
                                                    <input class="form-control form-control-sm" v-model="oneOf.title"/>
                                                    <input class="form-control form-control-sm" v-model="oneOf.const"/>
                                                    <i style="padding-top:8px; padding-left: 5px" class="fas fa-sm fa-trash" @click="removeObjectType(item, o)"/>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </li>
                            </ul>
                        </td>
                        <td v-else></td>
                        <td v-if="item.type == 'object'">
                            <select class="form-control form-control-sm" v-model="item.defaultValue">
                                <option value="" ></option>
                                <option v-for="(domain, o) in item.oneOf" :key="o" :value="domain.const" > {{ domain.title }} </option>
                            </select>
                        <td v-else-if="item.type == 'date'">
                            <select class="form-control form-control-sm" v-model="item.defaultClass">
                                <option value="0"> {{ $t('empty') }} </option>
                                <option value="1"> {{ $t('currentDate') }} </option>
                            </select>
                        </td>
                        <td v-else-if="i == 'sectionId' || i == 'sectionName'">
                            <input disabled class="form-control form-control-sm"/>
                        </td>
                        <td v-else-if="item.type == 'boolean'">
                            <div class="input-group" style="padding-top:2px">
                                <div class="input-group-text">
                                    <input v-model="item.defaultValue" type="checkbox">
                                </div>
                            </div>
                        </td>
                        <td v-else>
                            <input class="form-control form-control-sm" v-model="item.defaultValue" />
                        </td>
                        <td v-if="i == 'von' || i == 'bis'">
                            <div class="input-group" style="padding-top:2px">
                                <div class="input-group-text">
                                    <input v-model="item.takeOver" disabled type="checkbox">
                                </div>
                            </div>
                        </td>
                        <td v-else>
                            <div class="input-group" style="padding-top:2px">
                                <div class="input-group-text">
                                    <input v-model="item.takeOver" type="checkbox">
                                </div>
                            </div>
                        </td>
                        <td v-if="i == 'sectionId' || i == 'sectionName'">
                            <div class="input-group" style="padding-top:2px">
                                <div class="input-group-text">
                                    <input v-model="item.revokeCopy" disabled type="checkbox">
                                </div>
                            </div>
                        </td>
                        <td v-else>
                            <div class="input-group" style="padding-top:2px">
                                <div class="input-group-text">
                                    <input v-model="item.revokeCopy" type="checkbox">
                                </div>
                            </div>
                        </td>
                        <td v-if="i !== 'von' && i !== 'bis' && i !== 'sectionId' && i !== 'sectionName'">
                            <i style="padding-top:8px; padding-left: 5px" class="fas fa-sm fa-trash" @click="showRemoveAttrDialog(i)"/>
                        </td>
                        <td v-else>
                            &nbsp;
                        </td>
                    </tr>
                </tbody>
            </table>
            <textarea style="width:100%; height:300px" v-model="data.formTemplate"></textarea>
			<button class="btn btn-primary btn-sm" v-on:click="saveData" style="float:right">{{ $t('save') }}</button>
		</div>
        <FormRowDialog v-if="showRowModal" @confirm="addNewRow" @close="close"></FormRowDialog>
        <FormAttrDeleteDialog v-if="showRemoveAttrModal" @confirm="deleteFormRow" ></FormAttrDeleteDialog>
    </div>
</template>

<script>
import FormApi from "../../../api/server/form";
import FormRowDialog from "./FormRowDialog";
import FormAttrDeleteDialog from "./FormAttrDeleteDialog";

export default {
    name: "formDetail",
    components: {
        FormRowDialog,
        FormAttrDeleteDialog
    },
    mounted() {
        this.requestData();
    },
    methods: {
        showRemoveAttrDialog(i) {
            this.attrToDelete = i;
            this.showRemoveAttrModal = true;
        },
        deleteFormRow() {
            let i = this.attrToDelete;
            this.$delete(this.data.attributes, i);
        },
        showRowDialog() {
            this.showRowModal = true;
        },
        close() {
            this.showRowModal = false;
        },
        addNewRow(rowName) {
            this.$set(this.data.attributes, rowName, { value: "string", name: "Text", type: "string" });
        },
        addObjectType(item){
            if(!item.oneOf){
                this.$set(item, 'oneOf', []);
            }
            item.oneOf.push({ title: "", const: "" });
        },
        removeObjectType(item, index){
            item.oneOf.splice(index, 1);
        },
        async requestData() {
            let { id } = this.$route.params;
            this.data = await FormApi.detail(id);
        },
        async saveData() {
            let { id } = this.$route.params;

            //Hack na odebrání aktivních takeOver u VON a BIS
            // if(this.data.attributes.von) this.data.attributes.von.takeOver = false;
            // if(this.data.attributes.bis) this.data.attributes.bis.takeOver = false;
            
            
            await FormApi.update(id, this.data);
            this.showSuccess();
        }   
    },
    data() {
        return {
            showRemoveAttrModal: false,
            attrToDelete: 0,
            typeOptions: [
                {
                    value: "string", name: "Text"
                },
                {
                    value: "object", name: "One Of"
                },
                {
                    value: "number", name: "Number"
                },
                {
                    value: "boolean", name: "Boolean"
                },
                {
                    value: "date", name: "Date"
                }
            ],
            showRowModal: false,
            name: "",
            data: {
                id: 0,
                name: "",
                attributes: {},
                formTemplate: ``
            },
            form: {}
        };
    },
    notifications: {
        showSuccess: { 
            title: 'Getan!',
            message: 'Form succesfully updated!',
            type: 'success'
        }
    }
};
</script>