<template>
    <div class="container py-5">
        <h2>{{ $t('groupCreation') }}</h2>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'projects' }">
                        {{ $t('home') }}
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="'/settings/groups'">
                        {{ $t('groups') }}
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    {{ $t('create') }}
                </li>
            </ol>
        </nav>
        <div class="form-group col-md-4 ">
            <label for="name" required>{{ $t('name') }}</label>
            <input v-model="model.name" type="text" class="form-control" id="name" :placeholder="$t('enterGroupName')"><br/>
            <button type="button" class="btn btn-primary" @click="onSubmit">{{ $t('create') }}</button>
        </div>
    </div>
</template>

<script>
import GroupApi from "../../../api/server/group";

export default {
    methods: {
       async onSubmit() {
            this.model.domain = "";
            let group = await GroupApi.create(this.model);
            this.$router.push({ name: 'groupdetail', params: { id: group.id } })
        }
    },
    data() {
        return {
            model: {
                name: "",
            }
        };
    }
};
</script>
