import request from '../utils'

const PhotoApi = {
    list: async (projectId, label, query) => {
        const req = request.get(`/api/project/${projectId}/photo/${label}${query || ''}`)
        const res = await req;
        return res.body;
    },
    update: async (projectId, inspectionGuid, photo) => {
        const req = request.put(`/api/project/${projectId}/photo/${inspectionGuid}`).send(photo);
        const res = await req;
        return res.body;
    },
    create: async (projectId, photo) => {
        const req = request.post(`/api/project/${projectId}/photo`).send(photo);
        const res = await req;
        return res.body;
    },
    delete: async (projectId, sectionId) => {
        const req = request.delete(`/api/project/${projectId}/photo${sectionId}`);
        await req;
    }
};

export default PhotoApi