import TileLayer from "ol/layer/Tile";
import ImageLayer from "ol/layer/Image";
import Static from 'ol/source/ImageStatic';
import { OSM, TileArcGISRest,  } from "ol/source";
import WMTS, { optionsFromCapabilities } from 'ol/source/WMTS';
import WMTSCapabilities from 'ol/format/WMTSCapabilities';

export function createBaseLayer(layerDef, id) {
    if (layerDef.type == "osm") {
        var tilelayer = new TileLayer({ name: layerDef.name, visible: layerDef.visible || false });
        tilelayer.setSource(new OSM());
        return tilelayer;
    } else if (layerDef.type == "arcgisrest") {
        var tilelayer = new TileLayer({ name: layerDef.name, visible: layerDef.visible || false });
        tilelayer.setSource(new TileArcGISRest({ url: layerDef.url }));
        return tilelayer;
    } else if (layerDef.type == "image") {
        var extent = [];
        if(layerDef.extent) {
            var arrayOfExtents = layerDef.extent.split(",");
            for(var i in arrayOfExtents) {
                extent.push(parseInt(arrayOfExtents[i]));
            }
        }   
        var imageLayer = new ImageLayer({ name: layerDef.name, visible: layerDef.visible || false, type: "image", projection: layerDef.projection, opacity: parseFloat(layerDef.opacity) || 0.5 });
        imageLayer.setSource(new Static({ url: layerDef.url, projection: 'ProjectEpsg' + id , imageExtent: extent }));
        return imageLayer;
    } else if (layerDef.type == "wmts") {
        var tilelayer = new TileLayer({ name: layerDef.name, visible: layerDef.visible || false });
        fetch(layerDef.url)
            .then(function (response) {
                return response.text();
            })
            .then(function (text) {
                var parser = new WMTSCapabilities();
                var result = parser.read(text);
                if(result) {
                    var options = optionsFromCapabilities(result, { layer: layerDef.layer, projection: "urn:ogc:def:crs:EPSG::3857" });
                    tilelayer.setSource(new WMTS(options));
                } else {
                    tilelayer = null;
                }
            });
        return tilelayer;
    }
}