import proj4 from 'proj4';
import { getLength } from 'ol/sphere';
import Feature from "ol/Feature";
import { Point, LineString, MultiLineString } from "ol/geom";
import { Icon, Style, Fill, Stroke, Circle } from "ol/style";

import src from "../images/arrow.png";

export function defaultLineStyleFunction(feature, resolution, color, highlighted, highlightColor) {
  
    var styles = [];
    if(highlighted) {
        styles.push(
            new Style({
                stroke: new Stroke({color: color,width: 3}),
                zIndex: 2
            }),
            new Style({
                stroke: new Stroke({ 
                    color: highlightColor,
                    width: 6,
                    zIndex: 1
                })
            })
        )
    } else {
        styles.push(
            new Style({
                stroke: new Stroke({color: color,width: 6}),
                zIndex: 2
            })
        )
    }
    if(resolution < 10) {
        var geometry = feature.getGeometry();
        let stride = geometry.stride;
        let geo = geometry.flatCoordinates
        var end1 = geo.slice(-2 * stride, -stride) 
        var end2 = geo.slice(-1 * stride)

        var dx = end2[0] - end1[0];
        var dy = end2[1] - end1[1];
        var rotation = Math.atan2(dy, dx);
        styles.push(
            new Style({
                geometry: new Point(end2),
                image: new Icon({
                    src: src.toString(),
                    color: color,
                    anchor: [0.75, 0.5],
                    rotateWithView: true,
                    rotation: -rotation
                }),
            })
        );
    }
    return styles;
}

export function getFeaturesFromSections(sections, projection, sectionAttributeName, attributes) {
    var projFunc = proj4(projection || "EPSG:3857", "EPSG:3857")
    let isWGS = projection.indexOf("4326") > 0;

    var features = sections.map((i) => {
        // transform to mercator 
        var objectId = i.properties.OBJECTID;
        var objectLabel = i.properties.label;
        var objectNames = i.properties[sectionAttributeName];
        var objectAttributes = [];
        
        if(attributes && attributes.length > 0) {
            for (var attr in attributes) {
                var attrProp = attributes[attr];
                objectAttributes.push({ name: attrProp, value: i.properties[attrProp] });
            }
        }

        var olGeometry = null;
        var length = 0;

        if(i.type == "MultiLineString") {
            var coordinates = i.coordinates.map(c => 
                c.map(c1 => projFunc.forward(c1))
            );
            olGeometry = new MultiLineString(coordinates);

            if(!isWGS){

                for(var ii = 0; ii < i.coordinates.length; ii++){
                    var iiCords = i.coordinates[ii];
                    for(var iii = 1; iii < iiCords.length; iii++){

                        var dx = iiCords[iii-1][0] - iiCords[iii][0];
                        var dy = iiCords[iii-1][1] - iiCords[iii][1];
                        length += Math.sqrt(dx*dx + dy*dy);
                    }
                }
            } else {
                length = getLength(olGeometry);
            }
            
        } else {
            var coordinates = i.coordinates.map(c => projFunc.forward(c));
            olGeometry = new LineString(coordinates);

            if(!isWGS){
                for(var iii = 1; iii < i.coordinates.length; iii++){

                    var dx = i.coordinates[iii-1][0] - i.coordinates[iii][0];
                    var dy = i.coordinates[iii-1][1] - i.coordinates[iii][1];
                    length += Math.sqrt(dx*dx + dy*dy);
                }
            } else {
                length = getLength(olGeometry);
            }
        }
        var propertyObject = { 
            geometry: olGeometry,
            id: objectId, 
            label: objectLabel, 
            name: objectNames, 
            length: length,
            objectAttributes: objectAttributes
        }

        return new Feature(propertyObject);
    });

    return features;
}
