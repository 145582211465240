<template>
    <div class="container py-5">
        <h2>{{ $t('tools') }}</h2>
        <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link :to="{ name: 'projects' }">
                            {{ $t('home') }}
                        </router-link>
                    </li>
                    <li v-if="this.$route.name === 'indexeddb'" class="breadcrumb-item">
                        IndexedDB
                    </li>
                    <li v-else-if="this.$route.name === 'cache'" class="breadcrumb-item">
                        {{ $t('caches') }}
                    </li>
                </ol>
            </nav>
        <div class="row">   
            <div class="col-3">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <router-link to="/tools/indexeddb" v-bind:class="['nav-link', activeIndexeddb]" id="indexeddb" 
                        data-toggle="pill" href="#indexeddb" role="tab" aria-controls="indexeddb" aria-selected="false">
                        IndexedDB
                    </router-link>
                    <router-link to="/tools/cache" id="cache" data-toggle="pill" href="#cache" 
                        role="tab" aria-selected="true" v-bind:class="['nav-link', activeCache]" >
                        {{ $t('caches') }}
                    </router-link>
                    
                </div>
            </div>
            <div class="col-9">
                <div class="tab-content" id="v-pills-tabContent">
                <router-view></router-view>
            </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    mounted() {
        this.checkIfActive();
    },
    methods: {
        checkIfActive() {
            var self = this;
            if(self.$route) {
                if(self.$route.name === "indexeddb") {
                    self.activeIndexeddb = "active"
                } else if (self.$route.name === "cache") {
                    self.activeCache = "active"
                }
            }
        }
    },
    data() {
        return {
            activeIndexeddb: "",
            activeCache: "",
            list: [],
        };
    },
};
</script>

<style scoped>
ul li {
    margin: 0;
}

.col-md-3 {
    border-right: 1px solid black;
}

.col-md-1 {
    padding-right: 0;
    padding-left: 0;
}

ul {
    text-align: left;
    list-style: inside;
    padding-inline-start: 0;
}

h2 {
    display: inline-block;
}

button {
    display: inline-block;
}
</style>