import { lerp, squaredDistance as squaredDx } from 'ol/math';
import { getLength } from 'ol/sphere';
import LineString from 'ol/geom/LineString';
import MultiLineString from 'ol/geom/MultiLineString'

function assignClosest(flatCoordinates, offset1, offset2, stride, x, y, closestPoint) {
    var x1 = flatCoordinates[offset1];
    var y1 = flatCoordinates[offset1 + 1];
    var dx = flatCoordinates[offset2] - x1;
    var dy = flatCoordinates[offset2 + 1] - y1;
    var offset;
    if (dx === 0 && dy === 0) {
        offset = offset1;
    }
    else {
        var t = ((x - x1) * dx + (y - y1) * dy) / (dx * dx + dy * dy);
        if (t > 1) {
            offset = offset2;
        }
        else if (t > 0) {
            for (var i = 0; i < stride; ++i) {
                closestPoint[i] = lerp(flatCoordinates[offset1 + i], flatCoordinates[offset2 + i], t);
            }
            closestPoint.length = stride;
            return;
        }
        else {
            offset = offset1;
        }
    }
    for (var i = 0; i < stride; ++i) {
        closestPoint[i] = flatCoordinates[offset + i];
    }
    closestPoint.length = stride;
}

export function lineStringLengthToCoordinatesLine(flatCoordinates, offset, end, stride, x, y) {
    var x1 = flatCoordinates[offset];
    var y1 = flatCoordinates[offset + 1];
    var length = 0;
    
    var minSquaredDistance = Infinity;
    var lengthToThePoint = 0;
    var tmpPoint = [NaN, NaN];

    for (var i = offset + stride; i < end; i += stride) {

        assignClosest(flatCoordinates, i - stride, i, stride, x, y, tmpPoint);
        var squaredDistance = squaredDx(x, y, tmpPoint[0], tmpPoint[1]);
        if(squaredDistance < minSquaredDistance) {
            var x2 = tmpPoint[0];
            var y2 = tmpPoint[1];
            var l = Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));

            lengthToThePoint = length + l;
            minSquaredDistance = squaredDistance;
        }
        
        var x2 = flatCoordinates[i];
        var y2 = flatCoordinates[i + 1];
        length += Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
        x1 = x2;
        y1 = y2;
    }

    return lengthToThePoint;
}

export function lineStringLengthToCoordinatesMultiLine(flatCoordinates, offset, ends, stride, x, y) {

    var minSquaredDistance = Infinity;
    var lengthToThePoint = 0;
    var tmpPoint = [NaN, NaN];
    
    for (var i = 0, ii = ends.length; i < ii; ++i) {
        var end = ends[i];
        
        var x1 = flatCoordinates[offset];
        var y1 = flatCoordinates[offset + 1];
        var length = 0;
        
        for (var i = offset + stride; i < end; i += stride) {
            
            assignClosest(flatCoordinates, i - stride, i, stride, x, y, tmpPoint);
            var squaredDistance = squaredDx(x, y, tmpPoint[0], tmpPoint[1]);
            if(squaredDistance < minSquaredDistance) {
                var x2 = tmpPoint[0];
                var y2 = tmpPoint[1];
                var l = Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));

                lengthToThePoint = length + l;
                minSquaredDistance = squaredDistance;
            }
            
            var x2 = flatCoordinates[i];
            var y2 = flatCoordinates[i + 1];
            length += Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
            x1 = x2;
            y1 = y2;

        }
        
        offset = end;
    }
    return minSquaredDistance;
}


LineString.prototype.getLengthToCoordinates = function (x, y) {
    var tmpPoint = [NaN,NaN];
    this.closestPointXY(x, y, tmpPoint, Infinity);
    return lineStringLengthToCoordinatesLine(this.flatCoordinates, 0, this.flatCoordinates.length, this.stride, tmpPoint[0], tmpPoint[1]);
};

MultiLineString.prototype.getLengthToCoordinates = function (x, y) {
    var tmpPoint = [NaN,NaN];
    this.closestPointXY(x, y, tmpPoint, Infinity);
    return lineStringLengthToCoordinatesMultiLine(this.flatCoordinates, 0, this.ends_, this.stride, x, y);
};


export function lineStringLengthToCoordinates(geometry, realLength, x, y) {
    if(geometry.getLengthToCoordinates) {
        var len =  geometry.getLengthToCoordinates(x, y);
        var geometryLength = geometry.getLength();
        return (len/geometryLength)*realLength;
    }
    return 0;
}


