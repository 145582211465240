<template>
    <div>
        <table class="table table-bordered table-responsive-md">
            <thead>
                <tr>
                    <th class="text-center">{{ $t('groups') }}
                        <router-link style="float: right" :to="{ name: 'groupadd'}"><i class="fas fa-lg fa-plus"></i></router-link>
                    </th>
                </tr>
            </thead>
            <tbody v-if="list.length > 0">
                <tr v-for="(item, i) in list" :key="i">
                    <td>
                        <router-link :to="{ name: 'groupdetail', params: { id: item.id }}"> {{ item.name }} </router-link>
                        <i style="float:right; padding-top:5px; cursor: pointer" @click="showDeleteGroupModal(item)" class="fas fa-sm fa-trash"></i>
                    </td>
                </tr>
            </tbody>
        </table>
        <GroupModal v-if="isModalVisible" @reload="requestData"/>
    </div>
</template>

<script>
import GroupApi from "../../../api/server/group";
import GroupModal from "./GroupDeleteModal"

export default {
    name: "groups",
    components: {
        GroupModal,
    },
    mounted() {
        this.requestData()
    },
    methods: {
        async requestData() {
            this.list = await GroupApi.list();
        },
        showDeleteGroupModal(item) {
            this.groupToDeleteId = item.id;
            this.groupToDeleteName = item.name;
            this.isModalVisible = true;
        }
    },
    data() {
        return {
            groupToDeleteId: "",
            groupToDeleteName: "",
            isModalVisible: false,
            list: [],
        };
    },
};
</script>