<template>
    <div class="container">
        <div v-if="pending">{{ $t('loading') + '...' }}</div>
        <div v-else-if="error">{{ $t('error') }}</div>
        <div v-else>
            <div class="py-5">
                <h2>{{ $t('inspectionDetail') }}</h2>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: 'projects'}">
                                {{ $t('home') }}
                            </router-link>
                        </li>
                        <li class="breadcrumb-item">{{ data.name }}</li>
                    </ol>
                </nav>
            </div>
            <table class="table table-bordered">
                <thead>
                    <tr>
                    <th scope="col"></th>
                    <th scope="col">{{ $t('server') }}</th>
                    <th scope="col">{{ $t('myDevice') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">{{ $t('network') }}</th>
                        <td>
                            <template v-if="serverNetworkRecords >= 0">
                                {{ serverNetworkRecords }} {{ $t('records') }}
                            </template>
                        </td>
                        <td colspan="3">
                            <template v-if="deviceNetworkRecords >= 0">
                                {{ deviceNetworkRecords }} {{ $t('records') }}
                            </template>
                        </td>
                         
                    </tr>
                    <tr>
                        <th scope="row">{{ $t('inspections') }}</th>
                        <td>
                             <template v-if="serverInspectionRecords >= 0">
                                {{ serverInspectionRecords }} {{ $t('records') }}
                                <div v-if="hasRights" class="btn-group" role="group" style="float:right">
                                    <button id="syncLog" type="button" class="btn btn-sm btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Export
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="syncLog">
                                        <a v-bind:href="serverInspectionDownloadSkipDeletedUrl" target="_blank" type="button" class="dropdown-item">
                                            SHP 
                                        </a>
                                        <a v-bind:href="serverInspectionDownloadUrl" target="_blank" type="button" class="dropdown-item">
                                            SHP with deleted records 
                                        </a>
                                    </div>
                                </div>
                             </template>
                        </td>
                        <td colspan="3">
                              <template v-if="deviceInspectionRecords >= 0">
                                {{ deviceInspectionRecords }} {{ $t('records') }}
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{{ $t('photos') }}</th>
                        <td>
                            <template v-if="serverPhotoRecords >= 0">
                                {{ serverPhotoRecords }} {{ $t('records') }}
                                <a v-if="hasRights" v-bind:href="serverPhotoDownloadUrl" target="_blank" type="button" class="btn btn-secondary btn-sm float-right">
                                    {{ $t('export') }}
                                </a>
                            </template>
                        </td>
                        <td colspan="3">
                            <template v-if="devicePhotoRecords >= 0">
                                {{ devicePhotoRecords }} {{ $t('records') }}
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <router-link :to="{ name: 'inspectionview', params: { id: this.$route.params.id } }">
                    <button :disabled="syncLoading == ''" type="button" class="btn btn-primary">
                            {{ $t('viewOnline') }}
                    </button>
                </router-link>
                &nbsp;
                <div v-if="hasRights" class="btn-group" role="group"  style="float:right; " >
                    <button :disabled="syncLoading == ''" type="button" class="btn btn-info btn-sm" @click="serverSync">
                        <span v-if="serverSyncing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        {{ $t('syncWithServer') }}
                    </button>
                    <div class="btn-group" role="group">
                        <button id="syncLog" type="button" class="btn btn-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        </button>
                        <div class="dropdown-menu" aria-labelledby="syncLog">
                        <a @click="exportCSV('lockCSV')" class="dropdown-item" href="#">{{ $t('downloadSyncLog') }}</a>
                        </div>
                    </div>
                </div>
              
            </div><br>
            <div v-if="hasRights">
                <router-link :to="{ name: 'inspectiontool', params: { id: this.$route.params.id } }">
                    <button :disabled="syncLoading == ''" type="button" class="btn btn-success">
                            {{ $t('startInspection') }}
                    </button> 
                </router-link>
                <button :disabled="syncLoading == ''" type="button" style="float:right; " class="btn btn-primary" @click="sendPhotos">
                    {{ $t('postPhotos') }}
                </button>
            </div><br/>
            <div v-if="archives.length > 0">
                <h2>{{ $t('archives') }}</h2>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col"></th>
                            <th colspan="2" style="text-align:center">{{ $t('server') }}</th>
                            <th colspan="2" style="text-align:center">{{ $t('myDevice') }}</th>
                            <th>&nbsp;</th>
                        </tr>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">{{ $t('network') }}</th>
                            <th scope="col">{{ $t('inspections') }}</th>
                            <th scope="col">{{ $t('network') }}</th>
                            <th scope="col">{{ $t('inspections') }}</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(archive, i) in archives" :key="i" >
                            <td>{{ archive.name }}</td>
                            <td>{{ archive.networkCount }}</td>
                            <td>{{ archive.inspectionCount }}</td>
                            <td>{{ archive.deviceNetworkCount }}</td>
                            <td>{{ archive.deviceInspectionCount }}</td>
                            <td>
                                <button :disabled="syncLoading == ''" type="button" style="float: right" class="btn btn-primary btn-sm" @click="loadArchive(archive.label)">
                                    <span class="spinner-border spinner-border-sm" v-if="archiveSyncing && archiveLabel == archive.label" role="status" aria-hidden="true"></span>
                                    {{ $t('loadArchive') }}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div> 
</template>

<script>
import netApi from "../../api/server/net";
import netIdbApi from "../../api/idb/net";

import projectServerApi from "../../api/server/project";
import projectIdbApi from "../../api/idb/project";

import inspectionApi from "../../api/server/inspection";
import inspectionIdbApi from "../../api/idb/inspection";

import photoApi from "../../api/server/photo";
import photoIdbApi from "../../api/idb/photo";
import loginIdbApi from "../../api/idb/login";
import ProjectApi from '../../api/server/project';
import UserApi from '../../api/server/user';

export default {
    name: "projectdetail",
    notifications: {
        showSuccess: { 
            title: 'Getan!',
            message: 'Archive succesfully loaded!',
            type: 'success'
        },
        showServerSyncErrorNotification: { 
            title: 'Error!',
            message: 'There was an error while synchronizing with server!',
            type: 'error'
        },
        showArchiveSyncErrorNotification: { 
            title: 'Error!',
            message: 'There was an error while loading archive!',
            type: 'error'
        }
    },
    data() {
        return {
            archiveLabel: "",
            hasRights: false,
            inspectionData: {},
            serverSyncing: false,
            archiveSyncing: false,
            syncLoading: "hidden",
            pending: true,
            error: false,
            data: null,
            deviceNetworkRecords: null,
            devicePhotoRecords: null,
            deviceInspectionRecords: null,
            serverNetworkRecords: null,
            serverPhotoRecords: null,
            serverPhotoDownloadUrl: "",
            serverInspectionRecords: null,
            serverInspectionDownloadSkipDeletedUrl: "",
            serverInspectionDownloadUrl: "",
            archives: []
        };
    },
    mounted() {
        this.checkUserRights();
        this.requestData();
    },
    methods: {
        async loadArchive(label) {
            let { id } = this.data;
            this.syncLoading = "";
            this.archiveSyncing = true;
            this.archiveLabel = label;

            try {
                var inspections = await inspectionApi.list(id, label);
                var net = await netApi.loadRoutes(id, label);
                
                // uložení inspekcí
                await netIdbApi.init(id, label);
                for(var i in inspections) {
                    var s = inspections[i];
                    await inspectionIdbApi.storeForm(id, label, s);
                }

                // uložení sítě
                for(var i in net) {
                    var n = net[i];
                    await netIdbApi.storeNet(id, label, n);
                }
                this.requestInspectionData();
                this.showSuccess();

                this.syncLoading = "hidden";
                this.archiveSyncing = false;
                this.archiveLabel = "";
            } catch (err) {
                this.showArchiveSyncErrorNotification();
                this.syncLoading = "hidden";
                this.archiveSyncing = false;
                this.archiveLabel = "";
            }

        },
        async checkUserRights(){
            let user = await loginIdbApi.getLogin("pms");
            if(user.userrole == "Admin" || user.userrole == "Inspector") {
                this.hasRights = true;
            } else {
                this.hasRights = false;
            }
        },
        async exportCSV(type) {
            var { id, label } = this.data;
            let insp = [];
            if(type === "lockCSV"){
                insp = await inspectionIdbApi.loadFormLockedData(id, label || '');
            } else {
                insp = await inspectionIdbApi.loadFormData(id, label || '');
            }
            
            let rows = insp.map(e => {
                var row = e.guid;
                for(var key in this.data.form.attributes) {
                    row += ";" + e[key];
                }
                return row;
            })
            let csvContent = "data:text/csv;charset=utf-8," + rows.join("\r\n");
            this.download(csvContent, this.data.name +`.csv`);
        },
        download(data, strFileName, strMimeType) {
            var self = window, // this script is only for browsers anyway...
                defaultMime = "application/octet-stream", // this default mime also triggers iframe downloads
                mimeType = strMimeType || defaultMime,
                payload = data,
                url = !strFileName && !strMimeType && payload,
                anchor = document.createElement("a"),
                toString = function(a){return String(a);},
                myBlob = (self.Blob || self.MozBlob || self.WebKitBlob || toString),
                fileName = strFileName || "download",
                blob,
                reader;
                myBlob= myBlob.call ? myBlob.bind(self) : Blob ;
        
            if(String(this)==="true"){ //reverse arguments, allowing download.bind(true, "text/xml", "export.xml") to act as a callback
                payload=[payload, mimeType];
                mimeType=payload[0];
                payload=payload[1];
            }

            //go ahead and download dataURLs right away
            if(/^data\:[\w+\-]+\/[\w+\-]+[,;]/.test(payload)){
            
                if(payload.length > (1024*1024*1.999) && myBlob !== toString ){
                    payload=dataUrlToBlob(payload);
                    mimeType=payload.type || defaultMime;
                }else{			
                    return navigator.msSaveBlob ?  // IE10 can't do a[download], only Blobs:
                        navigator.msSaveBlob(dataUrlToBlob(payload), fileName) :
                        saver(payload) ; // everyone else can save dataURLs un-processed
                }
            }//end if dataURL passed?

            blob = payload instanceof myBlob ? payload : new myBlob([payload], {type: mimeType}) ;


            function dataUrlToBlob(strUrl) {
                var parts= strUrl.split(/[:;,]/),
                type= parts[1],
                decoder= parts[2] == "base64" ? atob : decodeURIComponent,
                binData= decoder( parts.pop() ),
                mx= binData.length,
                i= 0,
                uiArr= new Uint8Array(mx);

                for(i;i<mx;++i) uiArr[i]= binData.charCodeAt(i);

                return new myBlob([uiArr], {type: type});
            }

            function saver(url, winMode){

                if ('download' in anchor) { //html5 A[download]
                    anchor.href = url;
                    anchor.setAttribute("download", fileName);
                    anchor.className = "download-js-link";
                    anchor.innerHTML = "downloading...";
                    anchor.style.display = "none";
                    document.body.appendChild(anchor);
                    setTimeout(function() {
                        anchor.click();
                        document.body.removeChild(anchor);
                        if(winMode===true){setTimeout(function(){ self.URL.revokeObjectURL(anchor.href);}, 250 );}
                    }, 66);
                    return true;
                }

                // handle non-a[download] safari as best we can:
                if(/(Version)\/(\d+)\.(\d+)(?:\.(\d+))?.*Safari\//.test(navigator.userAgent)) {
                    url=url.replace(/^data:([\w\/\-\+]+)/, defaultMime);
                    if(!window.open(url)){ // popup blocked, offer direct download:
                        if(confirm("Displaying New Document\n\nUse Save As... to download, then click back to return to this page.")){ location.href=url; }
                    }
                    return true;
                }

                //do iframe dataURL download (old ch+FF):
                var f = document.createElement("iframe");
                document.body.appendChild(f);

                if(!winMode){ // force a mime that will download:
                    url="data:"+url.replace(/^data:([\w\/\-\+]+)/, defaultMime);
                }
                f.src=url;
                setTimeout(function(){ document.body.removeChild(f); }, 333);

            }//end saver




            if (navigator.msSaveBlob) { // IE10+ : (has Blob, but not a[download] or URL)
                return navigator.msSaveBlob(blob, fileName);
            }

            if(self.URL){ // simple fast and modern way using Blob and URL:
                saver(self.URL.createObjectURL(blob), true);
            }else{
                // handle non-Blob()+non-URL browsers:
                if(typeof blob === "string" || blob.constructor===toString ){
                    try{
                        return saver( "data:" +  mimeType   + ";base64,"  +  self.btoa(blob)  );
                    }catch(y){
                        return saver( "data:" +  mimeType   + "," + encodeURIComponent(blob)  );
                    }
                }

                // Blob but not URL support:
                reader=new FileReader();
                reader.onload=function(e){
                    saver(this.result);
                };
                reader.readAsDataURL(blob);
            }
            return true;
        },  /* end download() */

        async requestInspectionData() {
            var { id, label } = this.data;
            await netIdbApi.init(id, label || ''); 
            this.deviceInspectionRecords = await netIdbApi.countElements(id, label || '', "inspection_v2");
            this.deviceNetworkRecords = await netIdbApi.countElements(id, label || '', "net");
            this.devicePhotoRecords = await netIdbApi.countElements(id, label || '', "photo");
            
            var archives = [];
            if(this.data.archives && this.data.archives.length > 0) {
                for(var i in this.data.archives) {
                    var archive = this.data.archives[i];
                    archives.push({
                        name: archive.name, 
                        label: archive.label,  
                        networkCount: null, 
                        inspectionCount: null,
                        deviceNetworkCount: null, 
                        deviceInspectionCount: null
                    })
                }   
            }
            this.archives = archives;

            for(var i in archives) {
                var archive = archives[i];
                await netIdbApi.init(id, archive.label);
                var deviceNet = await netIdbApi.countElements(id, archive.label || '', "net");
                var deviceInsp = await netIdbApi.countElements(id, archive.label || '', "inspection_v2"); 

                archive.deviceNetworkCount = deviceNet;
                archive.deviceInspectionCount = deviceInsp;
            }
            
            var projectInfo = await ProjectApi.getInfo(id);
            
            this.serverInspectionRecords = projectInfo.inspections.count;
            this.serverNetworkRecords = projectInfo.net.count;
            this.serverPhotoRecords = projectInfo.photos.count;

            if(projectInfo.archives) {
                for(var i in archives) {
                    var archive = archives[i];
                    var label = archive.label;
                    var proj = projectInfo.archives.find(p => (p.label || '') == label);
                    if(proj) {
                        archive.inspectionCount = proj.inspectionCount;
                        archive.networkCount = proj.networkCount;
                    }
                }
            }
        },
        async sendPhotos() {
            let { id, label } = this.data;
            
            var photos = await photoIdbApi.getPhotos(id, label);

            for (var i in photos) {
                await photoApi.create(id, photos[i]);
            }
            await photoIdbApi.deletePhotos(id, label);
            this.requestInspectionData();
        },
        async serverSync() {
            let { id, label } = this.data;
            this.syncLoading = "";
            this.serverSyncing = true;

            try {      
                // odeslání záznamů na server
                var inspectionData = await inspectionIdbApi.loadFormData(id, label || '');
               
                for (var i in inspectionData) {
                    var inspectionRecord = inspectionData[i];
                    if(inspectionRecord.requireUpdate || inspectionRecord.posted === false) {
                        await inspectionIdbApi.storeLockedFormData(id, label || '', inspectionRecord)
                        inspectionRecord.posted = true;
                        await inspectionApi.updateByGuid(id, inspectionRecord);
                    }
                }

                // promazání databáze
                await inspectionIdbApi.deleteInspections(id, label || '');
                await netIdbApi.deleteNet(id, label || '');

                await netIdbApi.init(id, label || '');

                // stažení prohlídek k projektu
                let inspections = await inspectionApi.list(id, label || '');
               
                // stažení sítě projektu
                // TODO: sít není třeba tahat znovu a znovu!!!! prohlídky taká, třeba optimalizovat
                var net = await netApi.loadRoutes(id, label || '');
                
                // uložení prohlídek
                for(var i in inspections) {
                    var s = inspections[i];
                    await inspectionIdbApi.storeForm(id, label, s);
                }

                // uložení sítě
                for(var i in net) {
                    var n = net[i];
                    await netIdbApi.storeNet(id, label, n);
                }
                this.syncLoading = "hidden";
                this.serverSyncing = false;
                this.requestInspectionData();
            } catch (err) {
                this.syncLoading = "hidden";
                this.serverSyncing = false;
                this.showServerSyncErrorNotification({ message: err.message });
                await UserApi.createLog({ message: err.message, stack: err.stack });
            } 
        },
        async saveProject() {
            await projectServerApi.update(this.data);
        },
        async requestData() {
            this.pending = true;
            this.data = await projectIdbApi.loadProject("pms", parseInt(this.$route.params.id));
            this.serverInspectionDownloadSkipDeletedUrl = "/api/project/" + this.data.id + "/inspection/download/shp-zip/" + (this.data.label || '') + "?skipDeleted=true";
            this.serverInspectionDownloadUrl = "/api/project/" + this.data.id + "/inspection/download/shp-zip/" + (this.data.label || '');
            this.serverPhotoDownloadUrl = "/api/project/" + this.data.id + "/photo/download/zip/" + (this.data.label || '');

            this.error = false;
            this.pending = false;
            this.requestInspectionData();
        }
    }
};
</script>