<template>
    <transition name="modal-fade">
        <div class="modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <b-form-file v-model="file" multiple :state="Boolean(file)" :file-name-formatter="formatNames" :placeholder="$t('chooseFile')" drop-placeholder="Drop file"></b-form-file>
                    <div v-bind:class="[hiddenAlert, 'modal-body']">
                        <div class="alert alert-danger" role="alert">
                            {{ $t('wrongFileType') }}<br>
                            {{ $t('requiredFileType') }}
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button v-bind:class="[buttonLoading, syncLoading]" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {{ $t('loading') }}
                        </button>
                        <button type="button" class="btn btn-secondary" @click="close" data-dismiss="modal" >
                            {{ $t('close') }}
                        </button>
                        <button type="button" class="btn btn-primary" @click="uploadFiles" title="Upload" >
                            {{ $t('upload') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import NetApi from "../../../api/server/net";

export default {
    name: "modal",
    notifications: {
        showSuccess: { 
            title: 'Getan!',
            message: 'Files were successfully uploaded.',
            type: 'success'
        }
    },
    methods: {
        close() {
            this.file = undefined,
            this.hiddenAlert = "hidden";
            this.$parent.isModalVisible = false;
        },
        formatNames(files) {
            return files.length === 1 ? files[0].name : `${files.length} files selected`;
        },
        async uploadFiles() {
            this.syncLoading = "";
            try {
                await NetApi.upload(this.$route.params.id, this.file, this.$parent.data.label || '');
                this.close();
                this.showSuccess();
                this.$emit("refresh");
            } catch {
                this.hiddenAlert = "";
            } finally {
                this.file = undefined,
                this.buttonLoading = "hidden"
            }
        },
    },
    data() {
        return {
            hiddenAlert: "hidden",
            buttonLoading: 'btn btn-primary',
            syncLoading: "hidden",
            file: undefined
        };
    }
};
</script>