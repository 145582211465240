import * as superagent from 'superagent';

let serverBase = "";

const request = {
    get: (url) => {
        return superagent.get(serverBase + url)
            .withCredentials()
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json');
    },
    post: (url) => {
        return superagent.post(serverBase + url)
            .withCredentials()
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json');
    },
    postMultipart: (url) => {
        return superagent.post(serverBase + url)
            .withCredentials()
            .set('Accept', 'application/json');
    },
    put: (url) => {
        return superagent.put(serverBase + url)
            .withCredentials()
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json');
    },
    patch: (url) => {
        return superagent.patch(serverBase + url)
            .withCredentials()
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json');
    },
    delete: (url) => {
        return superagent.delete(serverBase + url)
            .withCredentials()
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json');
    }
};

export default request;