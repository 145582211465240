<template>
    <div class="container py-5">
        <h2>{{ $t('formCreation') }}</h2>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'projects' }">
                        {{ $t('home') }}
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="'/settings/forms'">
                        {{ $t('forms') }}
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    {{ $t('create') }}
                </li>
            </ol>
        </nav>
        <div class="form-group col-md-4 ">
            <label for="name" required>{{ $t('name') }}</label>
            <input v-model="data.name" type="text" class="form-control" id="name" :placeholder="$t('enterFormName')"><br/>
            <button type="button" class="btn btn-primary" @click="onSubmit">{{ $t('create') }}</button>
        </div>
    </div>
</template>

<script>
import FormApi from "../../../api/server/form";

export default {
    methods: {
       async onSubmit() {
            let form = await FormApi.create(this.data);
            this.$router.push({ name: 'formdetail', params: { id: form.id } })
        }
    },
    data() {
        return {
            data: {
                attributes: {
                    sectionId: {type: 'string', alias: 'OBJEKT_NR', title: 'OBJEKT_NR', revokeCopy: true, defaultValue: ''},
                    sectionName: {type: 'string', alias: 'BEZEICHNUNG', title: 'Straßenname', revokeCopy: true},
                    von: {type: 'number', alias: 'VON_M', title: 'Von', revokeCopy: true, takeOver: false},
                    bis: {type: 'number', alias: 'BIS_M', title: 'Bis', revokeCopy: true, takeOver: false}
                }
            }
        };
    }
};
</script>