<template>
    <div class="container py-5">
        <h2>{{ $t('projectCreation') }}</h2>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link :to="{ name: 'projects' }">
                        {{ $t('home') }}
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link :to="'/settings/projects'">
                        {{ $t('projects') }}
                    </router-link>
                </li>
                <li class="breadcrumb-item">
                    {{ $t('create') }}
                </li>
            </ol>
        </nav>
         <div class="form-row">
            <div class="form-group col-md-3">
                <label for="name">{{ $t('projectName') }}</label>
                <input v-model="model.name" type="text" v-bind:class="['form-control', requiredCenter]" id="name"  placeholder="Enter project name">
            </div>
            <div class="form-group col-md-3">
                <label for="center">{{ $t('center') }}</label>
                <input v-model="model.map.center" type="text" v-bind:class="['form-control', requiredCenter]" id="center" placeholder="Enter coordinates">
            </div>
            <div class="form-group col-md-3">
                <label for="form">{{ $t('form') }}</label>
                <div class="input-group">
                    <select class="form-control" v-model="model.form">
                        <option> </option>
                        <option v-for="(form, i) in this.forms" :key="i" v-bind:value="form">{{ form.name }}</option>
                    </select>
                </div>
            </div>
            <div class="form-group col-md-3">
                <label for="form">{{ $t('deletedSections') }}</label>
                <div class="input-group">
                    <select class="form-control" v-model="model.showDeletedSections">
                        <option disabled selected ></option>
                        <option v-bind:value="true">Yes</option>
                        <option v-bind:value="false">No</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-4">
                <label for="attribute">{{ $t('sectionAttributeName') }}</label>
                <input v-model="model.map.sectionAttributeName" type="text" class="form-control" id="attribute">
            </div>  
            <div class="form-group col-md-4">
                <label>{{ $t('camerasettings') }}</label>
                <div class="input-group">
                    <input :value="getCameraSettings(model.cameraSettings)" type="text" v-bind:class="['form-control']" id="projection" placeholder="Choose camera resolution">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="#" @click="setCameraResolution($event, 'FullHD')">Full HD</a>
                            <a class="dropdown-item" href="#" @click="setCameraResolution($event, '4K')">4K</a>
                        </div>
                    </div>
                </div>
            </div>
             <div class="form-group col-md-4">
                <label>{{ $t('projection') }}</label>
                <div class="input-group">
                    <input v-model="model.projection" type="text" v-bind:class="['form-control', requiredProjection]"  id="projection" placeholder="Choose projection">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="#" @click="projectionType($event,'4326')">WGS 84</a>
                            <a class="dropdown-item" href="#" @click="projectionType($event,'31254')">Austria GK West</a>
                            <a class="dropdown-item" href="#" @click="projectionType($event,'31255')">Austria GK Central</a>
                            <a class="dropdown-item" href="#" @click="projectionType($event,'31256')">Austria GK East</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
             <div class="form-group col-md-4">
                <label for="completedAt">{{ $t('completedAt') }} {{ model.completedAt }} % </label>
                <input v-model="model.completedAt" type="range" class="form-control" id="completedAt">
            </div>
            <div class="form-group col-md-4">
                <label for="center">{{ $t('line') }}</label>
                <div class="input-group">
                    <input v-model="model.map.lineColor" type="text" class="form-control" id="line">
                    <div class="input-group-append">
                        <input class="input-group-text" style="height: auto;  padding:5px" type="color" id="favcolor" name="favcolor" v-model="model.map.lineColor">
                    </div>
                </div>
            </div>
            <div class="form-group col-md-4">
                <label for="center">{{ $t('gpsColor') }}</label>
                <div class="input-group">
                    <input v-model="model.map.gpsColor" type="text" class="form-control" id="gpsColor">
                    <div class="input-group-append">
                        <input class="input-group-text" style="height: auto;  padding:5px" type="color" id="favcolor" name="favcolor" v-model="model.map.gpsColor">
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-3">
                <label for="center">{{ $t('selectedLine') }}</label>
                <div class="input-group">
                    <input v-model="model.map.selectedLineColor" type="text" class="form-control"  id="selectedLine">
                    <div class="input-group-append">
                        <input class="input-group-text" style="height: auto;  padding:5px" type="color" id="favcolor" name="favcolor" v-model="model.map.selectedLineColor">
                    </div>
                </div>
            </div>
            <div class="form-group col-md-3">
                <label for="center">{{ $t('progressLine') }}</label>
                <div class="input-group">
                    <input v-model="model.map.progressLineColor" type="text" class="form-control"  id="progressLineColor">
                    <div class="input-group-append">
                        <input class="input-group-text" style="height: auto;  padding:5px" type="color" id="favcolor" name="favcolor" v-model="model.map.progressLineColor">
                    </div>
                </div>
            </div>
            <div class="form-group col-md-3">
                <label for="center">{{ $t('completedLine') }}</label>
                <div class="input-group">
                    <input v-model="model.map.completedLineColor" type="text" class="form-control"  id="completedLineColor">
                    <div class="input-group-append">
                        <input class="input-group-text" style="height: auto;  padding:5px" type="color" id="favcolor" name="favcolor" v-model="model.map.completedLineColor">
                    </div>
                </div>
            </div>
            <div class="form-group col-md-3">
                <label for="center">{{ $t('highlightedLine') }}</label>
                <div class="input-group">
                    <input v-model="model.map.highlightedLineColor" type="text" class="form-control"  id="highlightedLineColor">
                    <div class="input-group-append">
                        <input class="input-group-text" style="height: auto;  padding:5px" type="color" id="favcolor" name="favcolor" v-model="model.map.highlightedLineColor">
                    </div>
                </div>
            </div>
        </div>
       
        <div v-if="groups.length > 0" class="form-group row">
            <label class="col-sm-12 col-form-label"><b>{{ $t('groups') }}</b></label>
            <div v-for="(item, i) in groups" :key="i" class="input-group col-md-4" style="padding-bottom:10px">
                <label readonly :for="item.id" type="text" class="form-control" :title="item.name" aria-label="Text input with checkbox">{{item.name.length > 12 ? item.name.substring(0, 12) + "..." : item.name}}</label>
                <div class="input-group-append">
                    <div class="input-group-text">
                        <input type="checkbox" :id="item.id" @change="checkedGroup(item, $event)" :checked="groupAtProject(item)" aria-label="Checkbox for following text input">
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-12" style="display:block; text-align:right">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item dropdown">                    
                        <!-- <input class="form-control" placeholder="Click to choose basemap" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"/> -->
                        <button id="btnGroupDrop1" type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{ $t('baseMaps') }}
                        </button>
                        <div class="dropdown-menu" v-if="this.typeOfMaps.length > 0">
                            <form>
                                <a class="dropdown-item" v-for="(item, i) in this.typeOfMaps" :key="i" @click="addToArray($event,item)">
                                    <i :id="item.type"  class="fas fa-sm fa-plus"></i>
                                    <label style="margin-bottom:0"  :for="item.type">{{ item.name }}</label>
                                </a>
                            </form>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="form-row">
            <table class="table table-hover" v-if="this.model.map.baseMaps.length > 0">
                <thead>
                    <th style="width: 10%">{{ $t('type') }}</th>
                    <th style="width: 25%">{{ $t('name') }}</th>
                    <th style="width: 25%">{{ $t('url') }}</th>
                    <th style="width: 30%">{{ $t('layer') }}</th>
                    <th style="width: 5%">{{ $t('visible') }}</th>
                    <th style="width: 5%"></th>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in this.model.map.baseMaps" :key="i">
                        <th scope="row">{{ item.type }}</th>
                        <td><input v-model="model.map.baseMaps[i].name" type="text" class="form-control form-control-sm"></td>
                        <td><input v-model="model.map.baseMaps[i].url" type="text" class="form-control"></td>
                        <td v-if="item.type == 'wmts'">
                            <input v-model="model.map.baseMaps[i].layer" type="text" class="form-control">
                        </td>
                        <td v-else>
                        </td>
                        <td>
                            <div class="input-group" style="padding-top:5px">
                                <div class="input-group-text">
                                    <input v-model="model.map.baseMaps[i].visible" type="checkbox" >
                                </div>
                            </div>
                        </td>
                        <td>
                            <i style="display:block; float:right; padding-top:15px" class="fas fa-lg fa-trash" @click="removeFromArray($event,i)"/>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="form-row">
            <div class="col-md-4">
                <button type="button" class="btn btn-primary" @click="onSubmit">{{ $t('save') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import projectApi from "../../../api/server/project";
import GroupApi from "../../../api/server/group";
import FormApi from "../../../api/server/form";

export default {
    mounted() {
        this.requestData();
    },
    methods: {
        useForm(form) {
            this.model.form = form;
        },
        async requestData() {
            this.forms = await FormApi.list();
            this.groups = await GroupApi.list();
        },
        addToArray(event, item) {
            event.preventDefault();
            var clone = Object.assign({}, item);
            this.model.map.baseMaps.push(clone)
        },
        removeFromArray(event, rowIndex) {
            event.preventDefault();
            if (rowIndex > -1) {
                this.model.map.baseMaps.splice(rowIndex, 1);
            }
        },
        async onSubmit() {
            if(this.model.name.length < 1){
                this.requiredName = "required"
            } else {
                this.requiredName = ""
            }
            if(this.model.map.center.length < 1){
                this.requiredCenter = "required"
            } else {
                this.requiredCenter = ""
            }
            if(this.model.projection.length < 1){
                this.requiredProjection = "required"
            } else {
                this.requiredProjection = ""
            }
            if(this.model && this.model.name.length > 1 && this.model.map.center.length > 1 && this.model.projection.length > 1){
                let project = await projectApi.create(this.model);
                this.$router.push({ name: 'admininspectiondetail', params: { id: project.id } })
            }
               
        },
        getCameraSettings(res) {
            if(res) {
                return `${res.width}x${res.height} (${res.width > 3000 ? '4K' : 'FullHD'})`; 
            }

            return "";
        },
        setCameraResolution(event,type) {
            event.preventDefault();
            if(type === "FullHD") {
                this.model.cameraSettings.width = 1920;
                this.model.cameraSettings.height = 1080;
            } else if (type === "4K") {
                this.model.cameraSettings.width = 3840;
                this.model.cameraSettings.height = 2160;
            }
        },
        projectionType(event, type) {
            event.preventDefault();
            if(type === "4326") {
                this.model.projection = 'GEOGCS["WGS 84", DATUM["World Geodetic System 1984", SPHEROID["WGS 84", 6378137.0, 298.257223563, AUTHORITY["EPSG","7030"]], AUTHORITY["EPSG","6326"]], PRIMEM["Greenwich", 0.0, AUTHORITY["EPSG","8901"]], UNIT["degree", 0.017453292519943295], AXIS["Geodetic longitude", EAST], AXIS["Geodetic latitude", NORTH], AUTHORITY["EPSG","4326"]]'
            } else if (type === "31254") {
                this.model.projection = 'PROJCS["MGI / Austria GK West",GEOGCS["MGI",DATUM["Militar_Geographische_Institute",SPHEROID["Bessel 1841",6377397.155,299.1528128,AUTHORITY["EPSG","7004"]],TOWGS84[577.326,90.129,463.919,5.137,1.474,5.297,2.4232],AUTHORITY["EPSG","6312"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4312"]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",10.33333333333333],PARAMETER["scale_factor",1],PARAMETER["false_easting",0],PARAMETER["false_northing",-5000000],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AUTHORITY["EPSG","31254"]]';
            } else if (type === "31255") {
                this.model.projection = 'PROJCS["MGI / Austria GK Central",GEOGCS["MGI",DATUM["Militar_Geographische_Institute",SPHEROID["Bessel 1841",6377397.155,299.1528128,AUTHORITY["EPSG","7004"]],TOWGS84[577.326,90.129,463.919,5.137,1.474,5.297,2.4232],AUTHORITY["EPSG","6312"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4312"]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",13.33333333333333],PARAMETER["scale_factor",1],PARAMETER["false_easting",0],PARAMETER["false_northing",-5000000],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AUTHORITY["EPSG","31255"]]';
            } else if (type === "31256") {
                this.model.projection = 'PROJCS["MGI / Austria GK East",GEOGCS["MGI",DATUM["Militar_Geographische_Institute",SPHEROID["Bessel 1841",6377397.155,299.1528128,AUTHORITY["EPSG","7004"]],TOWGS84[577.326,90.129,463.919,5.137,1.474,5.297,2.4232],AUTHORITY["EPSG","6312"]],PRIMEM["Greenwich",0,AUTHORITY["EPSG","8901"]],UNIT["degree",0.0174532925199433,AUTHORITY["EPSG","9122"]],AUTHORITY["EPSG","4312"]],PROJECTION["Transverse_Mercator"],PARAMETER["latitude_of_origin",0],PARAMETER["central_meridian",16.33333333333333],PARAMETER["scale_factor",1],PARAMETER["false_easting",0],PARAMETER["false_northing",-5000000],UNIT["metre",1,AUTHORITY["EPSG","9001"]],AUTHORITY["EPSG","31256"]]';
            } 
        },
        checkedGroup(group, event) {
            if(event.target.checked) {
                var index = this.model.groups.findIndex(f=>f.groupId == group.id);
                if(index < 0) {
                    this.model.groups.push({ "groupId": group.id })
                }
            } else {
                var index = this.model.groups.findIndex(f=>f.groupId == group.id);
                if(index >= 0) {
                    this.model.groups.splice(index, 1);
                }
            }
        },
        groupAtProject(group) {
            if(this.model.groups.length > 0) {
                for (var i in this.model.groups){
                    if(this.model.groups[i].groupId == group.id) {
                        return true
                    }
                }
            } 
            return false;
        },
        formType(type) {
            if(type === "default") {
                this.model.form = 'default'
            } else if (type === "graz") {
                this.model.form = 'graz'
            } else if (type === "custom") {
                this.model.form = 'custom'
            }
        }
    },
    data() {
        return {
            forms: [],
            groups: [],
            typeOfMaps: [
                { type: "osm", name: "OSM" ,url: "", visible: true },
                { type: "wmts", name: "Ortophoto (Tirol)" ,url: "/tirolwms.xml" , layer: "gdi_ortho", visible: true },
                { type: "arcgisrest", name: "Ortophoto (Graz)", url: "https://geodaten.graz.at/arcgis/rest/services/OGD/Orthofoto_WMS/MapServer", visible: true },
                { type: "wmts", name: "Custom WMTS", url: "" , layer: "", visible: true },
                { type: "arcgisrest", name: "Custom ArcGIS REST", url: "", visible: true  }
            ],
            id: "Id",
            projects: "Projects",
            requiredName: "",
            requiredCenter: "",
            requiredProjection: "",
            model: {
                completedAt: 90,
                name: "",
                projection: "",
                showDeletedSections: true,
                form: { name: "Please select form"},
                groups: [],
                 cameraSettings: {
                    width: 1920,
                    height: 1080
                },
				map: {
                    center: "",
                    lineColor: '#FFCC33',
                    selectedLineColor: "#FF0000",
                    progressLineColor: "#FF7F50",
                    highlightedLineColor: "#FF00F7",
                    completedLineColor: "#00FF08",
                    gpsColor: "#0BB5AF",
                    sectionAttributeName: "",
					baseMaps: []
				}
            }
        }
    }
};

</script>

<style scoped>
.center {
    display: block;
    text-align: center;
}
.centerLabel {
    text-align: center;
}
.required {
    border: 1px solid red;
}
</style>