<template>
    <div class="container py-5">
        <div v-if="pending">{{ $t('loading') }}</div>
        <div v-else-if="error">{{ $t('error') }}</div>
        <div v-else>
            <h2>{{ $t('inspectionDetail') }}</h2>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link :to="{ name: 'projects' }">
                            {{ $t('home') }}
                        </router-link>
                    </li>
                    <li class="breadcrumb-item">
                        <router-link :to="'/settings/projects'">
                            {{ $t('projects') }}
                        </router-link>
                    </li>
                    <li class="breadcrumb-item">
                        {{ data.name }}
                    </li>
                </ol>
            </nav>
            <table class="table table-bordered">
                <thead>
                    <tr>
                    <th scope="col"></th>
                    <th scope="col">{{ $t('server') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">{{ $t('network') }}</th>
                        <td>
                            {{ serverNetworkRecords }} {{ $t('records') }}
                            <div class="btn-group btn-group-sm" style="float:right" role="group" aria-label="Basic example">
                                <button v-if="data.archives && data.archives.length > 0" type="button" @click="showImportFromOldModal" class="btn btn-secondary">{{ $t('importFromOld') }}</button>
                                <button type="button" @click="showModal" class="btn btn-primary btn-sm float-right" >
                                    {{ $t('upload') }}
                                </button>
                            </div>  
                            
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">{{ $t('inspections') }}</th>
                        <td>
                            {{ serverInspectionRecords }} {{ $t('records') }}
                            <div class="float-right">
                                <div class="btn-group btn-group-sm" role="group" aria-label="Button group with nested dropdown">
                                    <button @click="showClearInspDialog" type="button" class="btn btn-danger">{{ $t('clear') }}</button>
                                    <div class="btn-group btn-group-sm" role="group">
                                        <button id="btnGroupDrop1" type="button" class="btn btn-secondary dropdown-toggle btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {{ $t('export') }}
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                        <a v-bind:href="exportToXlsxSkipDeleted" target="_blank" class="dropdown-item btn-sm">XLSX</a>
                                        <a v-bind:href="exportToXlsx" target="_blank" class="dropdown-item btn-sm">XLSX with deleted records</a>
                                        <a v-bind:href="serverInspectionDownloadSkipDeletedUrl" target="_blank" class="dropdown-item btn-sm">SHP</a>
                                        <a v-bind:href="serverInspectionDownloadUrl" target="_blank" class="dropdown-item btn-sm">SHP with deleted records</a>

                                        </div>
                                    </div>

                                    <div class="btn-group btn-group-sm" role="group">
                                        <button id="btnGroupDrop1" type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {{ $t('upload') }}
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                            <a @click="showImportXlsx" type="button " class="dropdown-item btn-sm">XLSX</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                     <tr>
                        <th scope="row">{{ $t('photos') }}</th>
                        <td>
                            {{ serverPhotoRecords }} {{ $t('records') }}
                            <a v-bind:href="serverPhotoDownloadUrl" target="_blank" type="button" class="btn btn-secondary btn-sm float-right">
                                {{ $t('export') }}
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <router-link class="btn btn-primary" :to="{ name: 'projectview', params: { id: this.$route.params.id } }">
                    {{ $t('viewOnline') }}
                </router-link>
                <div class="btn-group" role="group" aria-label="Basic example" style="float:right">
                <button type="button" @click="showStoreArchiveData" class="btn btn-secondary">Archive</button>
                <router-link class="btn btn-info" type="button" :to="{ name: 'projectdetail', params: { id: this.$route.params.id } }">
                    {{ $t('projectDetail') }}
                </router-link>
                </div>
            </div>
        <Modal v-show="isModalVisible" @refresh="requestInspectionData" @close="closeModal" ></Modal>
        <ClearInspectionsDialog v-show="showClearInspectionsDialog" @refresh="requestInspectionData" @close="closeClearInspectionsDialog"></ClearInspectionsDialog>
        <UploadXlsxDialog v-show="showImportXlsxModal" @refresh="requestInspectionData" @close="closeImportXlsx" ></UploadXlsxDialog>
        <ArchiveDataDialog v-show="showStoreArchiveDataDialog" @confirm="storeArchiveData" @close="closeStoreArchiveData"></ArchiveDataDialog>
        <ImportFromArchive v-show="showImportFromOldDialog" @confirm="importFromOld" @close="closeImportFromOldModal"></ImportFromArchive>
    </div>
</template>

<script>
import Modal from "./FileUploadDialog";
import UploadXlsxDialog from "./UploadXlsxDialog";
import ClearInspectionsDialog from "./ClearInspectionsDialog";
import projectServerApi from "../../../api/server/project";
import inspectionIdbApi from "../../../api/idb/inspection";
import ProjectApi from "../../../api/server/project";
import ArchiveDataDialog from "./ArchiveDataDialog";
import ImportFromArchive from "./ImportFromArchive";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import NetApi from '../../../api/server/net';

export default {
    name: "projectdetail",
    components: {
        Modal,
        UploadXlsxDialog,
        ClearInspectionsDialog,
        ImportFromArchive,
        ArchiveDataDialog
    },
    data() {
        return {
            archiveName: "",
            loadArchiveLabel: "",
            showStoreArchiveDataDialog: false,
            pending: true,
            isModalVisible: false,
            showImportXlsxModal: false,
            showClearInspectionsDialog: false,
            showImportFromOldDialog: false,
            error: false,
            data: null,
            serverNetworkRecords: 0,
            serverPhotoRecords: 0,
            serverPhotoDownloadUrl: "",
            serverInspectionRecords: 0,
            serverInspectionDownloadUrl: "",
            serverInspectionDownloadSkipDeletedUrl: "",
            exportToXlsx: "",
            exportToXlsxSkipDeleted: ""
        };
    },
    watch: {
        $route: "requestData",
    },
    notifications: {
        showSuccess: {
            title: 'Getan!',
            message: 'Archive was successfully created.',
            type: 'success'
        },
        showCopySuccess: {
            title: 'Getan!',
            message: 'Data successfully loaded.',
            type: 'success'
        }
    },
    mounted() {
        this.requestData();
        this.requestInspectionData();
    },
    methods: {
        showClearInspDialog() {
            this.showClearInspectionsDialog = true;
        },
        closeClearInspectionsDialog() {
            this.showClearInspectionsDialog = false;
        },
        showImportFromOldModal() {
            this.showImportFromOldDialog = true;
        },
        closeImportFromOldModal() {
            this.showImportFromOldDialog = false;
        },
        async importFromOld() {
            await NetApi.copy(this.$route.params.id, this.loadArchiveLabel);
            this.showImportFromOldDialog = false;
            this.showCopySuccess();
            this.requestInspectionData();
        },
        showStoreArchiveData() {
            this.showStoreArchiveDataDialog = true;
        },
        closeStoreArchiveData() {
            this.showStoreArchiveDataDialog = false;
        },
        async storeArchiveData() {
            var projectData = await ProjectApi.detail(this.$route.params.id);
            let oldCode = projectData.label;

            projectData.label = moment().format("YYYYMMDD") + uuidv4().substring(0,2);
            if(!projectData.archives) projectData.archives = [];
                projectData.archives.push({ label: oldCode, name: this.archiveName })
            await ProjectApi.update(projectData);

            this.showStoreArchiveDataDialog = false;
            this.showSuccess();
            this.requestInspectionData();
        },
        async exportCSV() {
            var { id, label } = this.data;
            let insp = await inspectionIdbApi.loadFormData(id, label || '');

            let rows = insp.map(e => {
                var row = e.guid;
                for(var key in this.data.form.attributes) {
                    row += ";" + e[key];
                }
                return row;
            })
            let csvContent = "data:text/csv;charset=utf-8," + rows.join("\r\n");
            this.download(csvContent, `test.csv`);
        },
        download(data, strFileName, strMimeType) {
            var self = window, // this script is only for browsers anyway...
                defaultMime = "application/octet-stream", // this default mime also triggers iframe downloads
                mimeType = strMimeType || defaultMime,
                payload = data,
                url = !strFileName && !strMimeType && payload,
                anchor = document.createElement("a"),
                toString = function(a){return String(a);},
                myBlob = (self.Blob || self.MozBlob || self.WebKitBlob || toString),
                fileName = strFileName || "download",
                blob,
                reader;
                myBlob= myBlob.call ? myBlob.bind(self) : Blob ;

            if(String(this)==="true"){ //reverse arguments, allowing download.bind(true, "text/xml", "export.xml") to act as a callback
                payload=[payload, mimeType];
                mimeType=payload[0];
                payload=payload[1];
            }

            //go ahead and download dataURLs right away
            if(/^data\:[\w+\-]+\/[\w+\-]+[,;]/.test(payload)){

                if(payload.length > (1024*1024*1.999) && myBlob !== toString ){
                    payload=dataUrlToBlob(payload);
                    mimeType=payload.type || defaultMime;
                }else{
                    return navigator.msSaveBlob ?  // IE10 can't do a[download], only Blobs:
                        navigator.msSaveBlob(dataUrlToBlob(payload), fileName) :
                        saver(payload) ; // everyone else can save dataURLs un-processed
                }
            }//end if dataURL passed?

            blob = payload instanceof myBlob ? payload : new myBlob([payload], {type: mimeType}) ;


            function dataUrlToBlob(strUrl) {
                var parts= strUrl.split(/[:;,]/),
                type= parts[1],
                decoder= parts[2] == "base64" ? atob : decodeURIComponent,
                binData= decoder( parts.pop() ),
                mx= binData.length,
                i= 0,
                uiArr= new Uint8Array(mx);

                for(i;i<mx;++i) uiArr[i]= binData.charCodeAt(i);

                return new myBlob([uiArr], {type: type});
            }

            function saver(url, winMode){

                if ('download' in anchor) { //html5 A[download]
                    anchor.href = url;
                    anchor.setAttribute("download", fileName);
                    anchor.className = "download-js-link";
                    anchor.innerHTML = "downloading...";
                    anchor.style.display = "none";
                    document.body.appendChild(anchor);
                    setTimeout(function() {
                        anchor.click();
                        document.body.removeChild(anchor);
                        if(winMode===true){setTimeout(function(){ self.URL.revokeObjectURL(anchor.href);}, 250 );}
                    }, 66);
                    return true;
                }

                // handle non-a[download] safari as best we can:
                if(/(Version)\/(\d+)\.(\d+)(?:\.(\d+))?.*Safari\//.test(navigator.userAgent)) {
                    url=url.replace(/^data:([\w\/\-\+]+)/, defaultMime);
                    if(!window.open(url)){ // popup blocked, offer direct download:
                        if(confirm("Displaying New Document\n\nUse Save As... to download, then click back to return to this page.")){ location.href=url; }
                    }
                    return true;
                }

                //do iframe dataURL download (old ch+FF):
                var f = document.createElement("iframe");
                document.body.appendChild(f);

                if(!winMode){ // force a mime that will download:
                    url="data:"+url.replace(/^data:([\w\/\-\+]+)/, defaultMime);
                }
                f.src=url;
                setTimeout(function(){ document.body.removeChild(f); }, 333);

            }//end saver

            if (navigator.msSaveBlob) { // IE10+ : (has Blob, but not a[download] or URL)
                return navigator.msSaveBlob(blob, fileName);
            }

            if(self.URL){ // simple fast and modern way using Blob and URL:
                saver(self.URL.createObjectURL(blob), true);
            }else{
                // handle non-Blob()+non-URL browsers:
                if(typeof blob === "string" || blob.constructor===toString ){
                    try{
                        return saver( "data:" +  mimeType   + ";base64,"  +  self.btoa(blob)  );
                    }catch(y){
                        return saver( "data:" +  mimeType   + "," + encodeURIComponent(blob)  );
                    }
                }

                // Blob but not URL support:
                reader=new FileReader();
                reader.onload=function(e){
                    saver(this.result);
                };
                reader.readAsDataURL(blob);
            }
            return true;
        },  /* end download() */
        async requestInspectionData() {
            let { id } = this.$route.params;

            let inspectionServer = await ProjectApi.getInfo(id);

            this.serverInspectionRecords = inspectionServer.inspections.count;
            this.serverNetworkRecords = inspectionServer.net.count;
            this.serverPhotoRecords = inspectionServer.photos.count;
        },
        showImportXlsx() {
            this.showImportXlsxModal = true;
        },
        closeImportXlsx() {
            this.showImportXlsxModal = false;
        },
        showModal() {
            this.isModalVisible = true;  
        },
        closeModal() {
            this.isModalVisible = false;
        },
        async saveProject() {
            await projectServerApi.update(this.data);
        },
        async requestData() {
            this.pending = true;
            try {
                this.data = await projectServerApi.detail(this.$route.params.id);
                
                this.serverInspectionDownloadUrl = "/api/project/" + this.data.id + "/inspection/download/shp-zip/" + (this.data.label || '');
                this.serverInspectionDownloadSkipDeletedUrl = "/api/project/" + this.data.id + "/inspection/download/shp-zip/" + (this.data.label || '') + "?skipDeleted=true";
                this.exportToXlsx = "/api/project/" + this.data.id + "/inspection/download/xlsx/" + (this.data.label || '');
                this.exportToXlsxSkipDeleted = "/api/project/" + this.data.id + "/inspection/download/xlsx/" + (this.data.label || '') + "?skipDeleted=true";
                this.serverPhotoDownloadUrl = "/api/project/" + this.data.id + "/photo/download/zip/" + (this.data.label || '');

                this.error = false;
            } catch (e) {
                this.data = null;
                this.error = e;
            }
            this.pending = false;
            this.requestInspectionData();
        }
    },
    render() {
        return this.$scopedSlots.default({
            pending: this.pending,
            error: this.error,
            data: this.data,
        });
    },
};
</script>