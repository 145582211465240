<template>
    <transition name="modal-fade">
        <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('sectionDialog') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" @click="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p style="margin-bottom:0">{{ $t('resetDataOrContinue') }}</p>
                </div>
                <div class="modal-footer" style="display:block">
                    <button type="button" class="btn btn-secondary" @click="reset" >
                        {{ $t('resetData') }}
                    </button>
                    <button type="button" style="display:block; float:right" class="btn btn-primary" @click="confirm">
                        {{ $t('continue') }}
                    </button>
                </div>
                </div>
            </div>
        </div>      
    </transition>
</template>

<script>
export default {
    name: "resetModal",
    methods: {
        close() {
            this.$emit("close");
        },
        reset() {
            this.$emit("reset");
            this.$emit("close");
        },
        confirm() {
            this.$emit("confirm");
            this.$emit("close");
        }
    }
};
</script>