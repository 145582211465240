import request from '../utils'

const NetApi = {
    loadRoutes: async (projectId, label) => {
        const req = request.get(`/api/net/get/${projectId}/${label}`)
        const res = await req;
        return res.body;
    },
    upload: async (projectId, files, label) => {
        const req = request.postMultipart(`/api/net/upload/${projectId}/${label}`);
        files.forEach(file => {
            req.attach("files", file);
        });
        const res = await req;
        return res.body;
    },
    copy: async (projectId, label) => {
        const req = request.get(`/api/net/copy/${projectId}/${label}`)
        const res = await req;
        return res.body;
    },
    detail: async (projectId) => {
        const req = request.get(`/api/net/get/${projectId}`)
        const res = await req;
        return res.body;
    },
};

export default NetApi