<template>
    <div>
        <table class="table table-bordered table-responsive-md">
            <thead>
                <tr>
                    <th class="text-center">{{ $t('forms') }}
                        <router-link style="float: right" :to="{ name: 'formadd'}"><i class="fas fa-lg fa-plus"></i></router-link>
                    </th>
                </tr>
            </thead>
            <tbody v-if="list.length > 0">
                <tr v-for="(item, i) in list" :key="i">
                    <td >
                        <router-link :to="{ name: 'formdetail', params: { id: item.id }}">
                            {{ item.name }}
                        </router-link>
                        <i style="float:right; padding-top:5px; cursor: pointer" @click="showDeleteForm(item)" class="fas fa-sm fa-trash"></i>
                    </td>
                </tr>
            </tbody>
        </table>
        <FormModal v-show="isModalVisible" @reload="requestData"></FormModal>
    </div>
</template>

<script>
import FormApi from "../../../api/server/form";
import FormModal from "./FormDeleteDialog";

export default {
    name: "forms",
    components: {
        FormModal
    },
    mounted() {
        this.requestData();
    },
    methods: {
        async requestData() {
            this.list = await FormApi.list();
        },
        showDeleteForm(item) {
            this.formToDeleteId = item.id
            this.formToDeleteName = item.name
            this.isModalVisible = true
        }
    },
    data() {
        return {
            formToDeleteId: "",
            formToDeleteName: "",
            isModalVisible: false,
            list: []
        };
    },
};
</script>