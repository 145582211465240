<template>
    <transition name="modal-fade">
        <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('validationRequired') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" @click="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p style="margin-bottom:0">{{ $t('validationFailed') }}</p>
                    <ul>
                        <li v-if="this.$parent.requirementSection">Straße wurde nicht ausgewählt.</li>
                        <li v-if="this.$parent.requiredField">erforderliches Eingabefeld /fehlt.</li>
                        <li v-if="this.$parent.requirementOverMaxLength">Bis kann nicht größer sein als Straßenende.</li>
                        <li v-if="this.$parent.requirementOverMinLength">Von kann nicht kleiner sein als Straßenanfang.</li>
                        <li v-if="this.$parent.requirementOverlap">Überschneidung von Abschnitten.</li>
                        <li v-if="this.$parent.requirementLowerToVon">Bis muss größer sein als Von.</li>
                    </ul>
                </div>
                <div class="modal-footer" style="display:block">
                    <button type="button" class="btn btn-secondary" @click="close" >
                        {{ $t('cancel') }}
                    </button>
                    <button type="button" style="display:block; float:right" class="btn btn-primary" :disabled="this.$parent.requirementLowerToVon || this.$parent.requirementSection" @click="confirm" >
                        {{ $t('forceSave') }}
                    </button>
                </div>
                </div>
            </div>
        </div>      
    </transition>
</template>

<script>
export default {
    name: "validationModal",
    methods: {
        close() {
            this.$emit("close");
        },
        async confirm() {
            this.$emit("confirm");
            this.$emit("close");
        }
    }
};
</script>