import request from '../utils'

const InspectionApi = {
    list: async (projectId, label, query) => {
        const req = request.get(`/api/project/${projectId}/inspection/${label}${query || ''}`)
        const res = await req;
        return res.body;
    },
    summary: async (projectId, label) => {
        const req = request.get(`/api/project/${projectId}/inspection/section-summary/${label}`)
        const res = await req;
        return res.body;
    },
    updateByGuid: async (projectId, inspection) => {
        const req = request.put(`/api/project/${projectId}/inspection/updatebyguid`).send(inspection);
        const res = await req;
        return res.body;
    },
    delete: async (projectId) => {
        const req = request.delete(`/api/project/${projectId}/inspection`);
        await req;
    }
};

export default InspectionApi