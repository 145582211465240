import { openDB } from 'idb';

const INSPECTION_TABLE_NAME = "inspection_v2";
const INSPECTION_LOG_TABLE_NAME = "inspection_log";

const DbInspectionApi = { 
    deleteInspections: async (dbName, label) => {
        if(label) dbName += '_' + label;
        var db = await openDB(dbName);
        var tx = db.transaction(INSPECTION_TABLE_NAME, "readwrite");
        
        await tx.store.clear();
    },
    storeForm: async (dbName, label, data) => {
        if(label) dbName += '_' + label;
        var db = await openDB(dbName);
        var tx = db.transaction(INSPECTION_TABLE_NAME, "readwrite");
        var storing = tx.store.put(data);

        await storing;

        await tx.done
    },
    deleteFormRow: async (dbName, guid) => {
        var db = await openDB(dbName);
        var tx = db.transaction(INSPECTION_TABLE_NAME, "readwrite");
        await tx.store.delete(guid)
    },
    loadFormData: async (dataName, label) => {
        if(label) dataName += '_' + label;
        const dbPromise = await openDB(dataName);
        const data = await dbPromise.getAll(INSPECTION_TABLE_NAME);
        
        return data;
    },
    storeLockedFormData: async (dbName, label, data ) => {
        if(label) dbName += '_' + label;
        var db = await openDB(dbName);
        var tx = db.transaction(INSPECTION_LOG_TABLE_NAME, "readwrite");
        var storing = tx.store.put(data);

        await storing;

        await tx.done
    },
    loadFormLockedData: async (dataName, label) => {
        if(label) dataName += '_' + label;
        const dbPromise = await openDB(dataName);
        const data = await dbPromise.getAll(INSPECTION_LOG_TABLE_NAME);

        return data;
    },
    loadInspection: async (dataName, label, sectionId) => {
        if(label) dataName += '_' + label;
        let data = [];
        const dbPromise = await openDB(dataName);
        //TODO Indexy, nebrat všechny sections
        const inspections = await dbPromise.getAll(INSPECTION_TABLE_NAME);
        inspections.forEach((insp) => {
            if(insp.sectionId == sectionId) {
                data.push(insp)
            }
        })
        return data;
    },
    findInspections: async (dataName, label, sectionId) => {
        if(label) dataName += '_' + label;
        const dbPromise = await openDB(dataName);
        //TODO Indexy, nebrat všechny sections
        const inspections = await dbPromise.getAll(INSPECTION_TABLE_NAME);
        var data = inspections.find(insp => insp.sectionId == sectionId);

        return data;
    },
    getInspectionData: async (dataName, label, von, sectionId) => {
        if(label) dataName += '_' + label;
        const dbPromise = await openDB(dataName);
        //TODO Indexy, nebrat všechny sections

        const inspections = await dbPromise.getAll(INSPECTION_TABLE_NAME);
        var data = inspections.find(insp => insp.sectionId == sectionId && insp.von <= parseFloat(von) && insp.bis > parseFloat(von));

        return data;
    },
};

export default DbInspectionApi