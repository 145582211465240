<template>
    <transition name="modal-fade">
        <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('enterAttributeName') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" @click="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <input v-model="rowName" class="form-control"/>
                </div>
                <div class="modal-footer" style="display:block">
                    <button type="button" class="btn btn-secondary" @click="close" >
                        {{ $t('cancel') }}
                    </button>
                    <button type="button" style="display:block; float:right" class="btn btn-primary" @click="confirm(rowName)" title="Confirm" >
                        {{ $t('confirm') }}
                    </button>
                </div>
                </div>
            </div>
        </div>      
    </transition>
</template>

<script>
export default {
    name: "validationModal",
    data() {
        return {
            rowName: ""
        }
    },
    methods: {
        close() {
            this.$parent.showRowModal = false;
        },
        async confirm(rowName) {
            if(this.$parent.data.attributes) {
                let keys = Object.keys(this.$parent.data.attributes)
                let findIndex = keys.findIndex(k => k === rowName)
                if(findIndex === -1) {
                    this.$emit("confirm", rowName);
                    this.close();
                    this.rowName = "";
                } else {
                    this.showError();
                }
            }
        }
    },
    notifications: {
        showError: { 
            title: 'Error!',
            message: 'Row with that name already exists!',
            type: 'error'
        }
    }
};
</script>